export const APP_LOAD = "APP_LOAD"
export const REGISTER = "REGISTER"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"
export const LOGIN_FAIL="LOGIN_FAIL"
export const LOGIN_START="LOGIN_START"
export const REGISTER_START="REGISTER_START"
export const REGISTER_SUCCESS="REGISTER_SUCCESS"
export const REGISTER_FAIL="REGISTER_FAIL"
export const REGISTER_VERIFICATION_SUCCESS="REGISTER_VERIFICATION_SUCCESS"
export const ACTIVATE_ACCOUNT_SUCCESS = "ACTIVATE_ACCOUNT_SUCCESS"
export const ACTIVATE_ACCOUNT_FAIL = "ACTIVATE_ACCOUNT_FAIL"
export const FORGET_PASS_SEND_PHONE_SUCCESS="FORGET_PASS_SEND_PHONE_SUCCESS"
export const CHANGE_PASSWORD_SUCCESS= "CHANGE_PASSWORD_SUCCESS"
export const CHANGE_PASSWORD_FAIL = "CHANGE_PASSWORD_FAIL"
export const LOGOUT = "LOGOUT"
export const ERROR = "ERROR"
export const SUBSCRIBE_LANDING_PAGE = "SUBSCRIBE_LANDING_PAGE"
export const UNSUBSCRIBE_LANDING_PAGE = "UNSUBSCRIBE_LANDING_PAGE"
export const CHANGE_PASSWORD_SEND_CODE_AGAIN = "CHANGE_PASSWORD_SEND_CODE_AGAIN"

export const CALL_REQUEST_SUCCESS = "CALL_REQUEST_SUCCESS"
export const QUEST_SEND_OTP_SUCCESS = "QUEST_SEND_OTP_SUCCESS"
export const QUEST_SEND_OTP_START = "QUEST_SEND_OTP_START"
export const USER_CALL_REQUEST_START = "USER_CALL_REQUEST_START"
export const CALL_REQUEST_RESET = "CALL_REQUEST_RESET"

export const LOAD_SERIVCES_SUCCESS = "LOAD_SERIVCES_SUCCESS"

export const GET_SERVICES_SUCCESS = "GET_SERVICES_SUCCESS"
export const GET_SERVICE_SPECS ="GET_SERVICE_SPECS"
export const GET_SERVICE_SPECS_ITEM_SUCCESS = "GET_SERVICE_SPECS_ITEM_SUCCESS"
export const SUBMIT_REQUEST_SUCCESS = "SUBMIT_REQUEST_SUCCESS"
export const SUBMIT_REQUEST_START = "SUBMIT_REQUEST_START"
export const RESET_REQUEST_CYCLE = "RESET_REQUEST_CYCLE"

export const GET_PROFILE_SUCCESS = "GET_PROFILE_SUCCESS"

export const UPDATE_PROFILE_START = "UPDATE_PROFILE_START"
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS"
export const REMOVE_UPDATE_PROFILE_MESSAGE="REMOVE_UPDATE_PROFILE_MESSAGE"

export const GET_BLOOD_TYPES_SUCCESS="GET_BLOOD_TYPES_SUCCESS"

export const GET_NOTIFICATION_LIST_SUCCESS = "GET_NOTIFICATION_LIST_SUCCESS"
export const RATE_SERVICE_START = "RATE_SERVICE_START"
export const RATE_SERVICE_SUCCESS = "RATE_SERVICE_SUCCESS"
export const REMOVE_MESSAGE = "REMOVE_MESSAGE"

export const GET_CUSTOMER_MEDICAL_RECORDS_SUCCESS = "GET_CUSTOMER_MEDICAL_RECORDS_SUCCESS"
export const GET_CUSTOMER_MIDICATIONS_SUCCESS = "GET_CUSTOMER_MIDICATIONS_SUCCESS"
export const GET_CUSTOMER_SYMPTOMS_SUCCESS="GET_CUSTOMER_SYMPTOMS_SUCCESS"
export const FETCH_HEALTHY_DATA_START="FETCH_HEALTHY_DATA_START"
export const FETCH_HEALTHY_DATA_SUCCESS ="FETCH_HEALTHY_DATA_SUCCESS"
export const CLEAR_MEMORY = "CLEAR_MEMORY"
