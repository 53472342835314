import React from "react";
import * as classes from "./BookingServicesButton.module.scss";
import cx from "classnames";
import questionImg from '../../../../assets/img/bookingService/question-circle.png'
export default function BookingServiceButton(props) {
  return (
      <div className={classes.BookingServiceButton}>
    <button disabled={props.disable} className={cx("btn btn-default d-flex justify-content-around align-items-center")}>
      <span>{props.text}</span>
      {props.removeFont?null:<img src={questionImg} />}
    </button>
    </div>
  );
}
