
import axios from "../../axios";
import * as actiontypes from "./actionTypes";

export const loginStart = (phone, password) => {
  const loginData = {
    Username: phone,
    Password: password,
    FCM: "dfkdff",
  };
  return (dispatch) => {
    dispatch({type:actiontypes.LOGIN_START});
    axios
      .post("/accounts/Login", loginData)
      .then((res) => {
          dispatch(
            loginSuccess(
              res.data.responseobject.access_token,
              res.data.responseobject.fullname,
              res.data.responseobject.profileimg,
              res.data.responseobject.username,
              res.data.responseobject.client_id
            )
          );
      })
      .catch((err) => {
        dispatch(loginFail(err.title||err.response.data.statusmessage || err.response.data.title));
      });
  };
};
export const loginSuccess = (token, currentUser ,profileImg,username,clientID) => {
  return {
    type: actiontypes.LOGIN_SUCCESS,
    token: token,
    currentUser: currentUser,
    profileImg : profileImg,
    username : username,
    clientID: clientID
  };
};
export const loginFail = (errorMessage) => {
  return {
    type: actiontypes.LOGIN_FAIL,
    error: errorMessage,
  };
};
export const registerFail = (errorMessage) => {
  return {
    type: actiontypes.REGISTER_FAIL,
    error: errorMessage,
  };
};
export const registerStart = (registerData) => {
  const requestData = {
    ...registerData,
    UserType: "1",
    UserRole: "D74FE1F9-EF11-49F9-9DBA-96B3D71C8DFF",
  };
  return dispatch => {
    dispatch({type:actiontypes.REGISTER_START});
    axios
      .post("/accounts/create", requestData)
      .then((res) => {
        if (res.data.statuscode == 200) {
          dispatch(registerSuccess(res.data.statusMessage))
        }else{
          dispatch(registerFail(res.data.statusmessage));
        }
      })
      .catch();
  }
};

export const registerSuccess = (successMessage) => {
  return {
    type: actiontypes.REGISTER_SUCCESS,
    message: successMessage,
  };
};

export const registerVericationStart = (phone, code) => {
  let requestData = {
    PhoneNumber: phone,
    ConfirmationCode: code
  }
  return dispatch => {
    axios.post('/accounts/ConfirmPhone', requestData).then(res => {
      dispatch(registerVericationSuccess())
    })
  }

}
export const registerVericationSuccess = () => {
  return {
    type: actiontypes.REGISTER_VERIFICATION_SUCCESS
  }
}
export const activateAccountSuccess = () => {
  return {
    type: actiontypes.ACTIVATE_ACCOUNT_SUCCESS,
  }
}
export const activateAccountFail=()=>{
  return{
    type : actiontypes.ACTIVATE_ACCOUNT_FAIL
  }
}
export const activateAccountStart = (reqData) => {
  return dispatch => {
    axios.post('/accounts/ConfirmPhone', reqData).then(res => {
      if (res.data.statuscode == 200) {
        dispatch(activateAccountSuccess())
      } else {
        dispatch(activateAccountFail())
      }


    })
  }
}
export const forgetPassSendPhoneSuccess = () => {
  return {
    type: actiontypes.FORGET_PASS_SEND_PHONE_SUCCESS
  }
}
export const forgetPassSendPhone = (phone) => {
  const reqData = {
    PhoneNumber: phone
  }
  return dispatch => {
    axios.post('accounts/resetpasswordrequest', reqData).then(res => {
      dispatch(forgetPassSendPhoneSuccess())
    })
  }

}
export const changePasswordSuccess = () => {
  return {
    type: actiontypes.CHANGE_PASSWORD_SUCCESS
  }

}
export const changePasswordFail = () => {
  return {
    type: actiontypes.CHANGE_PASSWORD_FAIL
  }

}
export const sendForgetPasswordCodeAgain = () => {
  return {
    type: actiontypes.CHANGE_PASSWORD_SEND_CODE_AGAIN
  }

}
export const changePasswordStart = (otp, password, cofirmPassword) => {
  const reqData = {
    OTP: otp,
    NewPassword: password,
    ConfirmPassword: cofirmPassword
  }
  return dispatch => {
    axios.post('/accounts/resetcustpassword', reqData).then(res => {
      dispatch(changePasswordSuccess())
    },(err)=>{
      dispatch(changePasswordFail());
    });
  }
}
export const logout = () => {
  return {
    type: actiontypes.LOGOUT,
  };
};
