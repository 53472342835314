import React, { Component } from 'react'
import * as classes from './CallRequest.module.scss'
import cx from 'classnames'
import CallRequestHeader from './CallRequestHeader/CallRequestHeader'
import CallRequestForm from './CallRequestForm/CallRequestForm'
import CallRequestSubmit from './CallRequestSubmit/CallRequestSubmit'
import CallRequestSuccess from '../../../shared/RequestSuccess/RequestSuccess'
import CallRequestPhoneForm from './CallRequestPhoneForm/CallRequestPhoneForm'
import RegisterVerificationForm from '../../../auth/pages/Register/RegisterVerificationForm/RegisterVerificationForm'
import * as actions from '../../../../store/actions/index'
import { connect } from "react-redux";

const mapStateToProps = (state) => {
    return {
        username: state.common.username,
        callRequestSuccess: state.callRequest.callRequestSuccess,
        switchToVerifyOTP: state.callRequest.switchToVerifyOTP,
        dir: state.common.currentLanguage,
        error: state.common.error,
        loading : state.callRequest.loading
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        userCallRequest: (username) => { dispatch(actions.userCallRequest(username)) },
        guestSendOTP: (phoneNumber) => { dispatch(actions.sendGuestOTP(phoneNumber)) },
        guestCallRequest: (name, phone, code) => { dispatch(actions.guestCallRequest(name, phone, code)) },
        callRequestReset : ()=>{dispatch(actions.callRequestReset())}
    }
}
export class CallRequest extends Component {
    state = {
        hideSmall: false,
        switchToPhoneForm: false,
        guestName: '',
        guestPhone: ''
    }
    // If user it call API if success it should be redirect to success if guest switch hem to phone and name form
    callRequest = () => {
        if (this.props.username) {
            this.props.userCallRequest(this.props.username);
            this.setState({
                ...this.state,
                hideSmall: true,
            })
        }
        else {
            this.setState({
                ...this.state,
                switchToPhoneForm: true,
                hideSmall: true
            })
        }
    }
    // taking guest data and send him OTP code
    submitPhoneForm = (name, phone) => {
        this.setState({
            ...this.state,
            guestName: name,
            guestPhone: phone
        })
        this.props.guestSendOTP(phone)
    }
    // Verify code guest entered and send his data
    verifyCode = (code) => {
        this.props.guestCallRequest(this.state.guestName, this.state.guestPhone, code);
    }
    moveToLandingPage = () => {
        this.props.history.replace('/notifications');
        this.props.callRequestReset();

        
    }
    render() {
        let subContent = <CallRequestSubmit loading={this.props.loading} superClicked={() => { this.callRequest() }} />
        if (!this.props.callRequestSuccess && this.state.switchToPhoneForm && !this.props.switchToVerifyOTP) {
            subContent = <CallRequestPhoneForm loading={this.props.loading} submitted={(name, phone) => this.submitPhoneForm(name, phone)} />
        }
        if (!this.props.callRequestSuccess && this.props.switchToVerifyOTP) {
            subContent = <div className={classes.Verify}><RegisterVerificationForm loading={this.props.loading} backLink="/" dir={this.props.dir} submitted={(code) => { this.verifyCode(code) }} /></div>
        }
        let content =
            <div className={cx("row", classes.SecondSection)}>
                <div className={cx("col-md-7", classes.first, this.state.hideSmall ? "d-none d-md-block" : '')}>
                    <div className={cx("col-12", classes.Gray)} >
                        <CallRequestForm />
                    </div>
                </div>
                <div className={cx("col-md-5 mt-3 mt-md-0", classes.second)} >
                    <div className={cx("col-12", classes.Gray)} >
                        {subContent}
                    </div>
                </div>
            </div>
        if (this.props.callRequestSuccess) {
            content = <CallRequestSuccess superClicked={() => { this.moveToLandingPage() }} />
        }

        return (
            <div className={classes.CallRequest}>
                <div className="container-fluid">
                    <div className={this.state.hideSmall ? "d-none d-md-block" : ''}>
                        <CallRequestHeader />
                    </div>
                    {content}

                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CallRequest)
