import React from "react";
import * as classes from "./WorkingHoursWidget.module.scss";
import cx from "classnames";
import clockImg from './../../../../assets/img/bookingService/clock.svg';
import { useTranslation } from "react-i18next";

export default function WorkingHoursWidget() {
  const { t, i18n } = useTranslation();
  return (
    <div className={cx(classes.NotAvailableWidget)}>
        <img src={clockImg} />
        <span>{t('not-available-message')}</span>
    </div>
  );
}
