import axios from "../../axios";
import * as actiontypes from "./actionTypes";

// current user then we will make request to get it
export const appLoad = (
  token,
  currentUser,
  currentLanguage,
  otherLanguage,
  profileImg,
  username,
  clientID
) => {
  return {
    type: actiontypes.APP_LOAD,
    token: token,
    currentUser: currentUser,
    currentLanguage,
    otherLanguage,
    profileImg,
    username,
    clientID
  };
};
export const loadServices = () => {
  return (dispatch) => {
    axios.get("/Service/st").then((res) => {
      let services = res.data.responseobject;
      dispatch(loadServicesSucces(services));
    });
  };
};
export const loadServicesSucces = (services) => {
  return {
    type: actiontypes.LOAD_SERIVCES_SUCCESS,
    services: services
  };
};
export const error = (error) => {
  return {
    type: actiontypes.ERROR,
    error: error,
  };
};
export const subscribeLandingPage=()=>{
  return{
    type:actiontypes.SUBSCRIBE_LANDING_PAGE
  }
}
export const unsubscribeLandingPage=()=>{
  return{
    type:actiontypes.UNSUBSCRIBE_LANDING_PAGE
  }
}