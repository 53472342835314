import React, { Component, createRef } from "react";
import * as classes from "./LandingPage.module.scss";
import cx from "classnames";
import { withTranslation } from "react-i18next";
import LandingPageCard from "./LandingPageCard/LandingPageCard";
import phoneImg from "../../assets/img/landingPage/doc home.png";
import docWriteImg from "../../assets/img/landingPage/docwrite1.png";
import customerService from "../../assets/img/landingPage/customer-service.svg";
import { Link } from "react-router-dom";
import * as actions from "../../store/actions/index";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/scrollbar/scrollbar.scss";
import LoadingPage from "../shared/LoadingPage/LoadingPage";
import * as THREE from "three";
import FOG from "vanta/dist/vanta.fog.min";

import {
  faChevronCircleRight,
  faChevronCircleLeft,
} from "@fortawesome/free-solid-svg-icons";
const mapStateToProps = (state) => {
  return {
    services: state.common.services,
    lang: state.common.currentLanguage,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onLoadServices: () => {
      dispatch(actions.loadServices());
    },
    subscribeLandingPage: () => {
      dispatch(actions.subscribeLandingPage());
    },
    unsubscribeLandingPage: () => {
      dispatch(actions.unsubscribeLandingPage());
    },
  };
};
export class LandingPage extends Component {
  constructor() {
    super();
    this.vantaRef = React.createRef();
  }
  componentDidMount() {
    if (this.vantaRef.current) {
      this.renderFOG();
    }
    this.props.subscribeLandingPage();
    this.props.onLoadServices();
  }
  componentWillUnmount() {
    if (this.vantaEffect) this.vantaEffect.destroy();
    this.props.unsubscribeLandingPage();
  }
  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.services !== prevProps.services) {
      this.renderFOG();
    }
  }

  renderFOG() {
    this.vantaEffect = FOG({
      el: this.vantaRef.current,
      THREE: THREE,
      mouseControls: true,
      touchControls: true,
      gyroControls: false,
      minHeight: 200.0,
      minWidth: 200.0,
      highlightColor: 0xff005a,
      midtoneColor: 0x8d00ff,
      lowlightColor: 0xffffff,
      blurFactor: 0.77,
      speed: 2.6,
      zoom: 0.8,
    });
  }

  render() {
    let servicesMd = null;
    let servicesSm = null;
    if (this.props.services) {
      let servicesCopy = [...this.props.services];
      let servicesSmCopy = [...this.props.services];
      servicesMd = servicesCopy.map((service, index) => {
        const link =
          "/services/" +
          service.ID +
          "/" +
          service.ServiceType.replace(" ", "-");
        return (
          <LandingPageCard
            classCustomName={cx(
              "mb-0 mb-md-3 mb-lg-4 d-none d-md-block" /*,index!==servicesCopy.length-1?classes.CardMargin:null*/
            )}
            key={service.ID}
            link={link}
            img={service.img}
            text={service.ServiceType}
            clicked={(event) => {
              event.preventDefault();
              this.props.history.push(link);
            }}
          />
        );
      });
      servicesSm = servicesSmCopy.map((service) => {
        const link =
          "/services/" +
          service.ID +
          "/" +
          service.ServiceType.replace(" ", "-");
        return (
          <div
            className={cx("col-12 d-block d-md-none", classes.SmallCard)}
            key={service.ID}
          >
            <Link to={link}>
              <div className="row d-flex justify-content-between px-4">
                <div className="d-flex">
                  <img src={service.img} />
                  <p className="ml-3">{service.ServiceType}</p>
                </div>

                <div className="mt-2">
                  <FontAwesomeIcon
                    icon={
                      this.props.lang == "ar"
                        ? faChevronCircleLeft
                        : faChevronCircleRight
                    }
                    size="2x"
                    color="#C9C9C9"
                  />
                </div>
              </div>
            </Link>
          </div>
        );
      });
    }
    if (this.props.services) {
      return (
        <div className={cx(classes.LandingPage)}>
          <div
            ref={this.vantaRef}
            className={cx(classes.FogBackgroundContainer)}
          >
            <div className={cx(classes.pageHeaders)}>
              <h3 className={classes.header}>
                {this.props.t("home-hospital")}
              </h3>
              <h3 className={classes.header2}>
                {this.props.t("home-hospitalia")}
              </h3>
            </div>

            <div
              ref={this.vantaRef}
              className={cx(
                "row justify-content-center",
                classes.firstSection,
                classes.CardMargin
              )}
            >
              {servicesMd}
            </div>
            <div
              ref={this.vantaRef}
              className={cx(
                "row justify-content-center d-block d-md-none",
                classes.firstSection
              )}
            >
              {servicesSm}
            </div>
            <div className={classes.whiteOverlay}></div>
            <div className={classes.whiteWaves}>
              <div>
                <svg
                  className="waves"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  viewBox="0 24 150 28"
                  preserveAspectRatio="none"
                  shapeRendering="auto"
                >
                  <defs>
                    <path
                      id="gentle-wave"
                      d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
                    ></path>
                  </defs>
                  <g className={classes.parallax}>
                    <use
                      xlinkHref="#gentle-wave"
                      x="48"
                      y="0"
                      fill="rgba(255,255,255,0.7)"
                    ></use>
                    <use
                      xlinkHref="#gentle-wave"
                      x="48"
                      y="3"
                      fill="rgba(255,255,255,0.5)"
                    ></use>
                    <use
                      xlinkHref="#gentle-wave"
                      x="48"
                      y="5"
                      fill="rgba(255,255,255,0.3)"
                    ></use>
                    <use
                      xlinkHref="#gentle-wave"
                      x="48"
                      y="7"
                      fill="#fff"
                    ></use>
                  </g>
                </svg>
              </div>
            </div>
          </div>
          <div className={cx("row", classes.secondSection)}>
            <div className="col-md-3 d-flex justify-content-center justify-content-md-end">
              <img src={customerService} className={classes.phoneImg} />
            </div>
            <div className="col-md-4 mt-2 pt-5 text-center text-md-left">
              <p className={cx("mb-3", classes.order)}>
                {this.props.t("order-call")}
              </p>
              <p className="mb-4 mb-md-4">{this.props.t("call-req-land")}</p>
              <div className="text-center text-md-left">
                <Link
                  className={cx(classes.callRequestButton, "btn btn-default")}
                  to="/order-call"
                >
                  <span>{this.props.t("order-call")}</span>
                </Link>
              </div>
            </div>
            <div className="col-md-4 offset-md-1 d-flex justify-content-center mt-0 mt-md-4">
              <img src={docWriteImg} className="mb-0" />
            </div>
          </div>
        </div>
      );
    } else {
      return <LoadingPage />;
    }
  }
}
const Extended = withTranslation()(LandingPage);
Extended.static = LandingPage.static;
export default connect(mapStateToProps, mapDispatchToProps)(Extended);
