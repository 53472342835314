import React from 'react'
import * as classes from "./HealthyAttachment.module.scss"
import cx from 'classnames'
import { Link } from 'react-router-dom'
export default function HealthyAttachment(props) {
    return (
        <div className={cx("row",classes.HealthyAttachment,props.classCustomName)}>
            <div className="col-12 text-center">
                <img src={props.mainImg} />
                <p className="mb-2">{props.para}</p>
                <Link to={props.link}>
                    <img src={props.plusImg} className={classes.plusImg}/>
                </Link>
                
            </div>
        </div>
    )
}
