import React from "react";
import * as classes from "./BookingServiceHeader.module.scss";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import handImg from "../../../../assets/img/bookingService/hand-holding-heart.png";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function BookingServiceHeader(props) {
  const { t, i18n } = useTranslation();
  return (
    <div className={cx(classes.BookingServiceHeader)}>
      <div className="row">
        <div className="col-12 col-md-2 col-lg-2 d-flex justify-content-center justify-content-lg-end">
          <div
            className={cx(
              classes.GrayCircle,
              "d-flex justify-content-center align-items-center"
            )}
          >
            <img src={props.img} />
          </div>
        </div>
        <div className="col-12 col-md-5 col-lg-5 col-xl-6 text-center text-md-left">
          <h3 className={classes.HeaderHead}>{props.speciality}</h3>
          <p>{props.description}</p>
        </div>
        <div className="col-12 col-md-5 col-lg-5 col-xl-4 px-0">
          <div
            className={cx(classes.GreenBox, "d-flex justify-content-between")}
          >
            <FontAwesomeIcon
              className={classes.faCustom}
              icon={faInfoCircle}
              size="2x"
            />
            <p>{t("service-fee")}</p>
            <h3 className={classes.FeeHeader}>{props.fee + props.currency}</h3>
            <img className={classes.hand} src={handImg} />
          </div>
          <p className={cx("text-center", classes.FeePara)}>
            {t("collect-fee")}
          </p>
        </div>
      </div>
    </div>
  );
}
