import axios from "axios";
import { toast } from 'react-toastify';
import { store } from "./store/store";
import * as actions from "./store/actions/index";
import Cookies from "js-cookie";
const { dispatch } = store;
const instance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});
instance.defaults.params = {}
instance.defaults.params['LangID'] = Cookies.get("language") === 'ar' ? '2':'1'
instance.interceptors.request.use(configs=>{
  const token = Cookies.get("token");
  if(token){
    configs.headers['Authorization'] = 'Bearer ' + token;
  }else{
    configs.headers['Authorization'] = 'FAKE_TOKEN';
  }
  return configs;
})
instance.interceptors.response.use((res) => {
  return res;
}, error=>{
  const response = error.response.data;
  if (
    response.statuscode == 401 ||
    response.statuscode == 400 ||
    response.statuscode == 500 || 
    response.status == 401 || 
    response.status == 400 ||
    response.status == 500 
  ) {
    toast.error(response.responseobject || response.statusmessage || response.title ,{autoClose:5000});
    dispatch(actions.error(response.statusmessage));
  }
  if(response.statuscode ==0){
    dispatch(actions.error("error happend"));
  }
  return Promise.reject(error.response.data);
});
export default instance;
