import React from "react";
import mainLogo from "../../../../assets/img/auth/hospitalia-logo-2.png";
import * as classes from "./AuthLayout.module.scss";
import cx from "classnames";
const authLayout = (props) => {
  return (
    <div className={cx(classes.AuthLayout)}>
      <div className="row d-flex align-items-center">
        <div className="col-md-6 order-last order-md-first">
          <div className="row justify-content-center text-center ">
            <div>
              <div className="row mx-auto">
              <img className={cx("img-responsive mx-auto",classes.img)} src={mainLogo} />
              </div>
              <img
                className={cx("img-responsive col-sm-12 text-center", props.registerImg ? "col-md-12" : "col-md-12", props.registerImg ? classes.imgRegister : classes.imgLogin)}
                src={props.mainImg}
              />
            </div>
          </div>


        </div>
        <div className="col-md-6 order-first order-md-last">
          <div className="row d-flex justify-content-md-end">
            <div
              className={cx(
                "col-md-10 px-3 px-xl-5 px-md-4 ",
                classes.formLayout
              )}>

              {props.children}

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default authLayout;
