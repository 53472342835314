import React from 'react'
import cx from 'classnames'
import * as classes from "./HealthyRegularInput.module.scss"
export default function HealthyRegularInput(props) {
    return (
        <div className={cx("input-group",classes.HealthyRegularInput)}>
            <input onChange={props.changed} value={props.value} type="text" className="form-control" placeholder={props.placeholder}  aria-describedby="basic-addon2" />
            <div className={cx("input-group-append")}  >
                <span className={cx("input-group-text",classes.Appended)} id="basic-addon2">{props.addOn}</span>
            </div>
        </div>
    )
}
