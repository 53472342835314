import React, { useState } from "react";
import * as classes from "./AccountHeader.module.scss";
import cx from "classnames";
import userImg from "../../../../../assets/img/account/user.png";
import cameraImg from "../../../../../assets/img/account/bicon_dark.png";
import weightImg from "../../../../../assets/img/account/weight-scale.png";
import heightImg from "../../../../../assets/img/account/height (1).png";
import bloodImg from "../../../../../assets/img/account/Group 62405.png";
import BloodType from '../../../shared/BloodType/BloodType'
import { useTranslation } from "react-i18next";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
export default function AccountHeader(props) {
  const [profilePic, setProfilePic] = useState("")
  const [profilePicViewer, setProfilePicViewer] = useState(props.img ? props.img : "")
  const { t, i18n } = useTranslation();
  const changeProfileImg = (event) => {
    setProfilePic(event.target.value);
    setProfilePicViewer(URL.createObjectURL(event.target.files[0]))
    props.changed(event.target.files[0])
  }
  return (
    <div className={cx(classes.AccountHeader, "row ")}>
      <div
        className={cx(
          "col-12 col-md-3 col-lg-2 d-flex justify-content-center justify-content-md-2"
        )}
      >
        <div
          className={cx(
            classes.HeaderImgContainer,
            "d-flex justify-content-center align-items-center"
          )}
        >
          {profilePicViewer ? (
            <img src={profilePicViewer} className={classes.ImgResize} />
          ) : (
            <img src={userImg} />
          )}
          <label>
            <input type="file" id="file" onChange={(event) => { changeProfileImg(event) }} />
            <div>
              <img src={cameraImg} className={classes.CameraImg} />
            </div>
          </label>
        </div>
      </div>
      <div className="col-12 col-md-3 col-lg-4 col-xl-5  text-center text-md-left mb-3 mb-md-0 ">
        <h3>{t('my-account')}</h3>
        <p>{props.name}</p>
      </div>
      <div className={cx("col-12 col-md-6 col-lg-6 col-xl-5  d-flex flex-column", classes.MyHealthy)}>
        <div className="order-last order-md-first">
          <p className="text-right">
            <Link to="/healthy" className={classes.HealthyLink}>
            {t("my-healthy")}
            {props.lang == "ar" ? (
              <FontAwesomeIcon className="ml-2" icon={faChevronLeft} />
            ) : (
              <FontAwesomeIcon className="ml-2" icon={faChevronRight} />
            )}
            </Link>
          </p>
        </div>
        <div className="d-flex justify-content-between px-4 px-md-4 px-lg-5 mt-0 mt-md-2 order-first order-md-last">
          <div className="text-center">
            <img src={weightImg} />
            <p className={props.weight ? classes.Data : classes.NoData}>
              {props.weight ? props.weight : t("unknown")}
            </p>
          </div>
          <div className="text-center">
            <img src={heightImg} />
            <p className={props.height ? classes.Data : classes.NoData}>
              {props.height ? props.height : t("unknown")}
            </p>
          </div>
          <div className="text-center">
            <div className={classes.bloodImg}>
              {props.blood ?<BloodType bloodType={props.bloodType} />:null}
            </div>
            <p className={props.blood ? classes.Data : classes.NoData}>
              {props.blood ? props.blood : t("unknown")}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
