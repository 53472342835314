import React from 'react'
import * as classes from './CkeckBox.module.scss'
import cx from "classnames"
import Checkbox from "react-custom-checkbox";
import * as Icon from "react-icons/fi";
import CheckBox from './../../../../../shared/CheckBox/CheckBox';


export default function CkeckBox(props) {
    return (
        <div className={cx(classes.CheckBox, "rounded")}>
            {/* <Checkbox
                borderColor="#68046B"
                size={24}
                icon={
                    <div
                        style={{
                            display: "flex",
                            flex: 1,
                            backgroundColor: "#FC0095",
                            alignSelf: "stretch",
                        }}
                    >
                        <Icon.FiCheck color="white" size={20} />
                    </div>
                }
            /> */}
            <CheckBox />
            <p className="mt-1 pb-2">{props.title}</p>
        </div>
    )
}
