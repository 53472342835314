import i18n from "i18next";
import en from "./en";
import ar from "./ar";
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Cookies from "js-cookie";


const resources = {
  en: {
    translation: en
  },
  ar: {
    translation: ar
  }
};
i18n.use(initReactI18next).init({
  // we init with resources
  resources: resources,
  lng:Cookies.get('language') || document.documentElement.lang,
  fallbackLng: "ar",
  debug: false,
  keySeparator: false, // we use content as keys

  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ","
  },
  react: {
    useSuspense: true
  }
});

export default i18n;
