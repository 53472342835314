import React from 'react'
import * as classes from './CallRequestHeader.module.scss'
import phonePicture from '../../../../../assets/img/order-call/doc home.png'
import customerService from "../../../../../assets/img/landingPage/customer-service.svg";
import handPicture from '../../../../../assets/img/order-call/hand-holding-heart.png'
import infoPic from '../../../../../assets/img/order-call/info-circle.png'
import cx from 'classnames';
import { useTranslation } from "react-i18next";
import {
    faInfoCircle
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
export default function CallRequestHeader(props) {
    const { t, i18n } = useTranslation();
    return (
        <div className={cx("row", classes.CallRequestHeader)}>
            <div className="col-12 col-md-3 col-xl-2 d-flex justify-content-center justify-content-md-end">
                <img className={classes.phoneImg} src={customerService} />
            </div>
            <div className={cx("col-12 col-xl-5 col-md-4",classes.Info)} >
                <h4 className="d-block d-sm-none">{t("order-call")}</h4>
                <p className={cx(classes.customP, "d-flex justify-content-center justify-content-md-start")}>{t("call-order-first-section-paragraph")}</p>
            </div>
            <div className="col-12  col-md-5 col-lg-5 col-xl-4 offset-xl-1">
                <div className={classes.greenBox}>
                    <img src={infoPic} className={classes.faCustom} />
                    <div className="d-flex justify-content-between">
                        <p>{t("free-service")}</p>
                        <img className={cx(classes.hand, " my-auto")} src={handPicture} />
                    </div>
                </div>
            </div>
        </div>
    )
}
