import React, { useState } from "react";
import * as classes from "./JoinUsForm.module.scss";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import Input from "../../../../shared/Input/Input";
import JoinUsSelect from "../../../shared/JoinUsSelect/JoinUsSelect";
import Button from "../../../../shared/Button/Button";
import MajorCard from "../../../shared/MajorCard/MajorCard";
import xRayImg from "../../../../../assets/img/contactUs/x-ray.png";
import testsImg from "../../../../../assets/img/contactUs/028-wheelchair.png";
import physImg from "../../../../../assets/img/contactUs/phys.png";
import nurImg from "../../../../../assets/img/contactUs/nur.png";
import docImage from "../../../../../assets/img/contactUs/doctor.png";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

export default function JoinUsForm() {
  const { t, i18n } = useTranslation();
  const [service, setServices] = useState("")
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [region, setRegion] = useState("");
  const [major, setMajor] = useState("");
  const [experience, setExperience] = useState("");
  const [cv, setCv] = useState("");
  const [practiceCard, setPracticeCard] = useState("");
  const [id, setId] = useState("");

  const schema = yup.object().shape({
    name: yup.string().required(t("no-name-error-message")),
    phone: yup
      .string()
      .required(t("no-phone-error-message"))
      .matches(/^\d{11}$/, t("phone-not-valid-error-message")),
    email: yup
      .string()
      .notRequired()
      .test(
        "email-regex",
        t("email-not-valid-error-message"),
        function (value) {
          if (value) {
            return value?.match(/^\w+([\.-]?\w+)@\w+([\.-]?\w+)(\.\w{2,3})+$/);
          }
          return true;
        }
      ),
    region: yup.string().required(t("no-region-error-message")),
    experience: yup.string().required(t("no-experience-error-message")),
    major: yup.string().required(t("no-major-error-message")),
    cv: yup.mixed().required(t("no-cv-error-message")),
    practiceCard: yup.mixed().required(t("no-practice-card-error-message")),
    id: yup.mixed().nullable().required(t("no-id-error-message"))
  });
  const { register, handleSubmit, errors, formState: { isDirty, isValid } } = useForm({
    mode: "all",
    resolver: yupResolver(schema),
    defaultValues: {
      phone: phone,
      name: name,
      region: region,
      email: email,
      major: major,
      experience: experience,
      cv: cv,
      practiceCard: practiceCard,
      id: id,
    },
  });
  const onSubmit = (data) => {
    console.log(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={cx("row", classes.JoinUsForm)}>
        <div className={cx("col-12 ", classes.FormContainer)}>
          <p className={classes.JoinUsServicesPara}>{t("join-us-services")}</p>
          <div className={cx(classes.Majors, "row px-3")}>
            <MajorCard
              selected={service}
              img={xRayImg}
              major={t("join-us-xray")}
              classCustomName="mb-2 mb-xl-0"
              id="5"
              clicked={() => { setServices(5) }}
            />
            <MajorCard
              img={testsImg}
              major={t("join-us-tests")}
              classCustomName="mb-2 mb-md-0"
              id="4"
              selected={service}
              clicked={() => { setServices(4) }}
            />
            <MajorCard
              img={physImg}
              major={t("join-us-phys")}
              classCustomName="mb-2 mb-md-0"
              id="3"
              selected={service}
              clicked={() => { setServices(3) }}
            />
            <MajorCard
              img={nurImg}
              major={t("join-us-nur")}
              classCustomName="mb-2 mb-md-0"
              id="2"
              selected={service}
              clicked={() => { setServices(2) }}
            />
            <MajorCard img={docImage} major={t("join-us-docs")} id="1" selected={service} clicked={() => { setServices(1) }} />
          </div>
          <p className={cx("mt-3 mt-md-4", classes.JoinUsInfoPara)}>
            {t("join-us-info")}
          </p>
          <div className={classes.ContactUsInput}>
            <Input
              
              customStyle={{ backgroundColor: "#fff" }}
              type="text"
              placeholder={t("join-us-fullname")}
              name="name"
              value={name}
              register={register} s
              changed={(e) => {
                setName(e.target.value);
              }}
            />
            {errors.name?.message && (
              <p className={classes.errorMessage}>{errors.name?.message}</p>
            )}
            <Input
              register={register}
              classCustomName="mt-3"
              customStyle={{ backgroundColor: "#fff" }}
              type="phone"
              placeholder={t("phoneNumber")}
              name="phone"
              //value={phone}
              changed={(e) => {
                setPhone(e.target.value);
              }}
            />
            {errors.phone?.message && (
              <p className={classes.errorMessage}>{errors.phone?.message}</p>
            )}
            <Input
              register={register}
              classCustomName="mt-3"
              customStyle={{ backgroundColor: "#fff" }}
              type="email"
              placeholder={t("join-us-email")}
              name="email"
              value={email}
              changed={(e) => {
                setEmail(e.target.value);
              }}
            />
            {errors.email?.message && (
              <p className={classes.errorMessage}>{errors.email?.message}</p>
            )}
            <JoinUsSelect
              placeholder={t("join-us-region")}
              optionsElements={[
                { label: t("join-us-city1"), value: "Cairo" },
                { label: t("join-us-city2"), value: "Alexandria" },
              ]}
              name="region"
              register={register}
              changed={(event) => {
                setRegion(event.value)
              }}
              classCustomName="mt-3"
            />
            {errors.region?.message && (
              <p className={classes.errorMessage}>{errors.region?.message}</p>
            )}
            <Input
              placeholder={t("join-us-major")}
              name="major"
              changed={(event) => setMajor(event.target.value)}
              register={register}
              customStyle={{ backgroundColor: "#fff" }}
              classCustomName="mt-3"
            />
            {errors.major?.message && (
              <p className={classes.errorMessage}>{errors.major?.message}</p>
            )}
          </div>
          <p className="mt-3">{t("join-us-experience")}</p>
          <div className={classes.ContactUsInput}>
            <textarea
              ref={register}
              value={experience}
              name="experience"
              onChange={(e) => {
                setExperience(e.target.value);
              }}
              className={cx(classes.TextArea, "form-control")}
              placeholder={t("join-us-tell-experience")}
            ></textarea>
            {errors.experience?.message && (
              <p className={classes.errorMessage}>
                {errors.experience?.message}
              </p>
            )}
          </div>
          <p className={classes.FilePara} style={{marginTop:'20px'}}>{t("cv")}</p>
          <div className={classes.ContactUsInput}>
            <Input
              register={register({ required: true })}
              classCustomName={classes.FileInput}
              customStyle={{ backgroundColor: "#fff" }}
              type="file"
              placeholder={t("join-us-upload-cv")}
              name="cv"
              //value={cv}
              changed={(file) => {
                setCv(file);
              }}
            />
            {errors.cv?.message && (
              <p className={classes.errorMessage}>{errors.cv?.message}</p>
            )}
          </div>
          <p className={classes.FilePara}>{t("join-us-practice")}</p>
          <div className={classes.ContactUsInput}>
            <Input
              register={register}
              classCustomName={classes.FileInput}
              customStyle={{ backgroundColor: "#fff" }}
              type="file"
              placeholder={t("join-us-upload-practice")}
              name="practiceCard"
              value={practiceCard}
              changed={(file) => {
                setPracticeCard(file);
              }}
            />
            {errors.practiceCard?.message && (
              <p className={classes.errorMessage}>
                {errors.practiceCard?.message}
              </p>
            )}
          </div>
          <p className={classes.FilePara}>{t("join-us-id")}</p>
          <div className={classes.ContactUsInput}>
            <Input
              register={register}
              classCustomName={classes.FileInput}
              customStyle={{ backgroundColor: "#fff" }}
              type="file"
              placeholder={t("join-us-upload-id")}
              name="id"

              changed={(file) => {
                setId(file);
              }}
            />
            {errors.id?.message && (
              <p className={classes.errorMessage}>{errors.id?.message}</p>
            )}
          </div>
        </div>
        <div className={cx("col-12 px-0 d-flex justify-content-end")}>
          <div className={classes.SubmitButton}>
            <Button text={t("join-us-button")} type="submit" />
          </div>
        </div>
      </div>
    </form>
  );
}
