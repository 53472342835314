import React, { useState } from "react";
import AuthButton from "../../../../shared/Button/Button";
import AuthInput from "../../../../shared/Input/Input";
import * as classes from "./PasswordForm.module.scss";
import cx from "classnames";
import { useTranslation } from "react-i18next";
export default function PasswordForm(props) {
  const { t, i18n } = useTranslation();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const validate = () => {
    let passwordErrorVal = "";
    const pass = /^(?=.*[0-9])(?=.*[a-z])([a-zA-Z0-9]{8,})$/;
    if (!password || !confirmPassword || password !== confirmPassword) {
      passwordErrorVal = t("password-no-match-error-message")
    }
    if ((!password.match(pass) || !confirmPassword.match(pass)) && (password || confirmPassword)) {
      passwordErrorVal = t("signup-password-example")
    }
    if (passwordErrorVal) {
      setPasswordError(passwordErrorVal);
      return false;
    }
    return true;
  };
  const submitForm = (event) => {
    event.preventDefault();
    const isValid = validate();
    if (isValid) {
      setPasswordError("");
      props.submitted(password, confirmPassword);
    }
  };
  return (
    <div className={classes.PasswordForm}>
      <h3 className="mb-3 mb-md-4">{t("reset-password")}</h3>
      <form
        onSubmit={(event) => {
          submitForm(event);
        }}
      >
        <AuthInput
          classCustomName="mb-2"
          name="password"
          type="password"
          placeholder={t("new-password")}
          changed={(event) => {
            setPassword(event.target.value);
          }}
          value={password}
        />
        <AuthInput
          classCustomName="mt-1"
          name="password"
          type="password"
          placeholder={t("new-password-confirm")}
          changed={(event) => {
            setConfirmPassword(event.target.value);
          }}
          value={confirmPassword}
        />
        {passwordError ? <p className={classes.errorMessage}>{passwordError}</p> : null}
        <div className={cx("mt-1", classes.AuthButton)}>
          <AuthButton text={t("change-password")} />
        </div>
      </form>
    </div>
  );
}
