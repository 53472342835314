import React, { Component } from "react";
import * as classes from "./HealthyForm.module.scss";
import cx from "classnames";
import HealthyRegularInput from "../../../shared/HealthyRegularInput/HealthyRegularInput";
import HealthyDateSelect from "../../../shared/HealthyDateSelect/HealthyDateSelect";
import { getBloodTypes } from "../../../../../store/actions/index";
import { connect } from "react-redux";
import BloodType from "../../../shared/BloodType/BloodType";
import { withTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import Button from '../../../../shared/Button/Button'
import weightImg from '../../../../../assets/img/account/weight.png'
import heightImg from '../../../../../assets/img/healthy/height.png'
import correct from '../../../../../assets/img/healthy/correct.png'
import correctSelected from '../../../../../assets/img/healthy/correct-selected.png'
import close from '../../../../../assets/img/healthy/close.png'
import closeSelected from '../../../../../assets/img/healthy/close-selected.png'
import {toast } from 'react-toastify';

const mapStateToProps = (state) => {
  return {
    bloodTypes: state.profile.bloodTypes,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getBloodTypes: () => {
      dispatch(getBloodTypes());
    },
  };
};
export class HealthyForm extends Component {
  state = {
    userProfileData: null,
    days: null,
  };
  componentDidMount() {
    this.props.getBloodTypes();
    this.setState({
      ...this.state,
      userProfileData: this.props.userProfileData,
      days: this.daysInMonth(
        new Date(this.props.userProfileData.Birthdate).getMonth(),
        new Date(this.props.userProfileData.Birthdate).getFullYear()
      ),
    });
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.state.userProfileData != prevState.userProfileData) {
      this.setState({
        ...this.state,
        days: this.daysInMonth(
          new Date(this.state.userProfileData.Birthdate).getMonth(),
          new Date(this.state.userProfileData.Birthdate).getFullYear()
        ),
      });
    }
    if(this.props.message !=prevProps.message){
      toast(this.props.message)
    }
  }
  submitForm = ()=>{
    this.props.submitted(this.state.userProfileData)
  }
  daysInMonth = (month, year) => {
    return new Date(year, month + 1, 0).getDate();
  };
  updateDate = (event, type) => {
    if (type === "days") {
      this.setState({
        ...this.state,
        userProfileData: {
          ...this.state.userProfileData,
          Birthdate: new Date(
            new Date(this.state.userProfileData.Birthdate).setDate(
              event.value
            )
          ),
        },
      });
    } else if (type === "months") {
      this.setState({
        ...this.state,
        userProfileData: {
          ...this.state.userProfileData,
          Birthdate: new Date(
            new Date(this.state.userProfileData.Birthdate).setMonth(
              event.value - 1
            )
          ),
        },
      });
    } else {
      this.setState({
        ...this.state,
        userProfileData: {
          ...this.state.userProfileData,
          Birthdate: new Date(
            new Date(this.state.userProfileData.Birthdate).setFullYear(
              event.value
            )
          ),
        },
      });
    }
  };
  setBloodType = (id, blood) => {
    this.setState({
      ...this.state,
      userProfileData: {
        ...this.state.userProfileData,
        BloodTypeId: id,
        BloodType: blood,
      },
    });
  };
  render() {
    if (this.state.userProfileData && this.props.bloodTypes) {
      let bloodCarts = null;
      const bloodTypesCopy = [...this.props.bloodTypes];
      bloodCarts = bloodTypesCopy.map((bloodtype) => {
        return (
          <div
            className={cx(classes.BloodCard,"mb-2 mb-md-2 mb-lg-0")}
            key={bloodtype.ID}
            style={
              this.state.userProfileData.BloodTypeId == bloodtype.ID
                ? { backgroundColor: "#F4F4FC" }
                : null
            }
            onClick={() => {
              this.setBloodType(bloodtype.ID, bloodtype.BloodType);
            }}
          >
            <div className={classes.bloodImg}>
              <BloodType bloodType={bloodtype.ID} />
            </div>
          </div>
        );
      });
      return (
        <div className={cx("row", classes.HealthyForm)}>
          <div className="col-12">
            <p className={cx(classes.Label, "mb-3")}>
              {this.props.t("choose-blood-type")}
            </p>
            <div className="mt-1 mt-md-0">
            {bloodCarts}
            </div>
          </div>
          <div className={cx("col-12 col-xl-7 col-lg-9 col-md-10")}>
            <div className={cx("row d-md-flex justify-content-md-between", classes.WeightSection)}>
              <div className="col-12 col-md-3 d-flex">
                <img src={weightImg} className={classes.WeightImg} />
                <p className={classes.Label}>{this.props.t("weight")}</p>
              </div>

              <div className="col-12 col-md-9">
                <HealthyRegularInput
                  addOn={this.props.t("kg")}
                  className=""
                  placeholder={this.props.t("enter-weight")}
                  changed={(event)=>{this.setState({
                    ...this.state,
                    userProfileData: {
                      ...this.state.userProfileData,
                      Weight: event.target.value
                    }

                  })}}
                />
              </div>
            </div>
            <div className={cx("row d-md-flex justify-content-md-between",classes.HeightSection)}>
              <div className="col-12 col-md-3 d-flex">
                <img src={heightImg} className={classes.HeightImg} />
                <p className={classes.Label}>{this.props.t("height")}</p>
              </div>
              <div className="col-12 col-md-9">
                <HealthyRegularInput
                  addOn={this.props.t("cm")}
                  placeholder={this.props.t("enter-height")}
                  changed={(event)=>{this.setState({
                    ...this.state,
                    userProfileData: {
                      ...this.state.userProfileData,
                      Height: event.target.value
                    }

                  })}}
                />
              </div>
            </div>
            <div className="row d-md-flex justify-content-md-between">
              <p className={cx("col-12 col-md-3",classes.Label)}>{this.props.t("birthday")}</p>
              <div className="col-12 col-md-9 d-flex justify-content-between">
                <HealthyDateSelect
                  changed={(event) => {
                    this.updateDate(event, "days");
                  }}
                  placeholder="dd"
                  range={[1, this.state.days]}
                  value={new Date(
                    this.state.userProfileData.Birthdate
                  ).getDate()}
                /> <p className="my-auto">/</p>
                <HealthyDateSelect
                placeholder="mm"
                  value={
                    new Date(this.state.userProfileData.Birthdate).getMonth() +
                    1
                  }
                  
                  range={[1, 12]}
                  changed={(event) => {
                    this.updateDate(event, "months");
                  }}
                /> <p className="my-auto">/</p>
                <HealthyDateSelect
                placeholder="yyyy"
                  changed={(event) => {
                    this.updateDate(event, "years");
                  }}
                  range={[1930, 2021]}
                  value={new Date(
                    this.state.userProfileData.Birthdate
                  ).getFullYear()}
                />
              </div>
            </div>
              <div className={cx("row d-md-flex justify-content-md-between",classes.GenderSection)}>
                <div className="col-12 col-md-4 ">
                  <p className={classes.Label}>{this.props.t("gender")}</p>
                </div>
                <div className="col-12 col-md-8 d-flex justify-content-md-end justify-content-start  ">
                  <div
                    className={cx(
                      classes.SelectBox,
                      "d-flex justify-content-around align-items-center"
                    )}
                    style={
                      this.state.userProfileData.isMale
                        ? { color: "#4B0064", backgroundColor: "#F4F4FC" }
                        : null
                    }
                    onClick={() => {
                      this.setState({
                        ...this.state,
                        userProfileData: {
                          ...this.state.userProfileData,
                          isMale: true
                        }
                      })
                    }}
                  >
                    <p>{this.props.t("signup-gender-male")}</p>
                  </div>
                  <div
                    className={cx(
                      classes.SelectBox,
                      "d-flex justify-content-around align-items-center ml-3"
                    )}
                    style={
                      !this.state.userProfileData.isMale
                        ? { color: "#4B0064", backgroundColor: "#F4F4FC" }
                        : null
                    }
                    onClick={() => {
                      this.setState({
                        ...this.state,
                        userProfileData: {
                          ...this.state.userProfileData,
                          isMale: false
                        }
                      })
                    }}
                  >
                    <p>{this.props.t("signup-gender-female")}</p>
                  </div>
                </div>
              </div>
              <div className={cx("row d-md-flex justify-content-md-between ",classes.SmokerSection)}>
                <div className="col-12 col-md-4">
                  <p className={classes.Label}>{this.props.t("smoker")}</p>
                </div>
                <div className="col-12 col-md-8 d-flex justify-content-md-end justify-content-start  ">
                  <div
                    className={cx(
                      classes.SelectBox,
                      "d-flex justify-content-around align-items-center"
                    )}
                    style={
                      this.state.userProfileData.isSmoker
                        ? { color: "#4B0064", backgroundColor: "#F4F4FC" }
                        : null
                    }
                    onClick={() => {
                      this.setState({
                        ...this.state,
                        userProfileData: {
                          ...this.state.userProfileData,
                          isSmoker: true
                        }
                      })
                    }}
                  >
                    <img src={this.state.userProfileData.isSmoker?correctSelected:correct}/>
                    <p>{this.props.t("yes")}</p>
                  </div>
                  <div
                    className={cx(
                      classes.SelectBox,
                      "d-flex justify-content-around align-items-center ml-3"
                    )}
                    style={
                      !this.state.userProfileData.isSmoker
                        ? { color: "#4B0064", backgroundColor: "#F4F4FC" }
                        : null
                    }
                    onClick={() => {
                      this.setState({
                        ...this.state,
                        userProfileData: {
                          ...this.state.userProfileData,
                          isSmoker: false
                        }
                      })
                    }}
                  >
                    <img src={!this.state.userProfileData.isSmoker?closeSelected:close}/>
                    <p>{this.props.t("no")}</p>
                  </div>
                </div>
              </div>
            
          
              <div className="row d-md-flex justify-content-md-between ">
                <div className="col-12 col-md-6">
                  <p className={classes.Label}>{this.props.t("alcoholic")}</p>
                </div>
                <div className="col-12 col-md-6 d-flex justify-content-md-end justify-content-start  ">
                  <div
                    className={cx(
                      classes.SelectBox,
                      "d-flex justify-content-around align-items-center"
                    )}
                    style={
                      this.state.userProfileData.isAlcoholic
                        ? { color: "#4B0064", backgroundColor: "#F4F4FC" }
                        : null
                    }
                    onClick={() => {
                      this.setState({
                        ...this.state,
                        userProfileData: {
                          ...this.state.userProfileData,
                          isAlcoholic: true
                        }
                      })
                    }}
                  >
                    <img src={this.state.userProfileData.isAlcoholic?correctSelected:correct}/>
                    <p>{this.props.t("yes")}</p>
                  </div>
                  <div
                    className={cx(
                      classes.SelectBox,
                      "d-flex justify-content-around align-items-center ml-3"
                    )}
                    style={
                      !this.state.userProfileData.isAlcoholic
                        ? { color: "#4B0064", backgroundColor: "#F4F4FC" }
                        : null
                    }
                    onClick={() => {
                      this.setState({
                        ...this.state,
                        userProfileData: {
                          ...this.state.userProfileData,
                          isAlcoholic: false
                        }
                      })
                    }}
                  >
                    <img src={!this.state.userProfileData.isAlcoholic?closeSelected:close}/>
                    <p>{this.props.t("no")}</p>
                  </div>
                </div>
              </div>
            
          </div>
          <div className="col-12 col-xl-5 col-lg-3 d-flex justify-content-end align-items-end">
            <div className={classes.SaveButton}>
              <Button loading={this.props.loading} disabled={!this.state.userProfileData.Height||!this.state.userProfileData.Weight} text={this.props.t("save")} clicked={()=>{this.submitForm()}} />
            </div>

          </div>

        </div>
      );
    } else {
      return <div>loading</div>;
    }
  }
}
const Extended = withTranslation()(HealthyForm);
Extended.static = HealthyForm.static;
export default connect(mapStateToProps, mapDispatchToProps)(Extended);
