import React, { useState } from "react";
import mainLogo from "../../../../../assets/img/logos/hoslogo.png";
import AuthButton from "../../../../shared/Button/Button";
import AuthInput from "../../../../shared/Input/Input";
import { Link } from "react-router-dom";
import * as classes from "../Register.module.scss";
import AuthSelect from "../../../shared/AuthSelect/AuthSelect";
import cx from 'classnames'
import { useTranslation } from 'react-i18next';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
const RegisterForm = (props) => {
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [gender, setGender] = useState("");
  const { t, i18n } = useTranslation();
  const schema = yup.object().shape({
    name:yup.string().required(t("no-name-error-message")).matches(/^[A-Za-z\u0620-\u064A]+$/,t("name-not-valid-message")),
    phone: yup.string().required(t("no-phone-error-message")).matches(/^\d{11}$/,t("phone-not-valid-error-message")),
    password: yup.string().required(t("no-password-error-message")).matches(/^(?=.*[0-9])(?=.*[a-z])([a-zA-Z0-9]{8,})$/,t('password-contains-error-message')),
    email:yup.string().notRequired().test('email-regex',t("email-not-valid-error-message"),function(value){
      if(value){
        return value?.match(/^\w+([\.-]?\w+)@\w+([\.-]?\w+)(\.\w{2,5})+$/);
      }
      return true;
    })
    });
  const {register,errors,handleSubmit,formState:{isDirty,isValid}} = useForm({
    mode:'all',
    resolver:yupResolver(schema),
    defaultValues:{
      phone,
      name,
      password,
      email,
      date:birthDate
    }
  });
  const setIntialState = () => {
    setNameError("");
    setPhoneError("");
    setPasswordError("");
    setEmailError("")
  };
  const validate = () => {
    let nameErrorVal = "";
    let phoneErrorVal = "";
    let passwordErrorVal = "";
    let emailErrorVal = "";
    const num = /^[0-9\b]+$/;
    const pass = /^(?=.*[0-9])(?=.*[a-z])([a-zA-Z0-9]{8,})$/;
    let emailRegex = email?.match(/^\w+([\.-]?\w+)@\w+([\.-]?\w+)(\.\w{2,3})+$/)
    //name validation
    if (name === "") {
      nameErrorVal = t("no-name-error-message")
    }
    if (nameErrorVal) {
      setNameError(nameErrorVal);
      return false;
    }

    //phone validation
    if (phone === "" || !phone.match(num)) {
      phoneErrorVal = t("no-phone-error-message")
    }
    if (phone.length != 11 || !phone.match(num)) {
      phoneErrorVal = t("phone-not-valid-error-message");
    }
    if (phoneErrorVal) {
      setPhoneError(phoneErrorVal);
      return false;
    }
    //email validation
    
    if(email !== '' && !emailRegex){
      emailErrorVal=t("email-not-valid-error-message")
    }
    if(emailErrorVal){
      setEmailError(emailErrorVal);
      return false;
    }
    //password validation
    if (password === "") {
      passwordErrorVal = t("no-password-error-message");
    }
    if (!password.match(pass)) {
      passwordErrorVal = t("password-contains-error-message");
    }

    if (passwordErrorVal) {
      setPasswordError(passwordErrorVal);
      return false;
    }
    return true;
  };

  const handelSubmit = (event) => {
    event.preventDefault();
    const isValid = validate();
    if (isValid) {
      setIntialState();
      const regData = {
        Email: email,
        FirstName: name,
        Gender: gender,
        BirthDate: birthDate,
        PhoneNumber: phone,
        Password: password,
        ConfirmPassword: password,
      };
      props.submitted(regData);
    }
  };

  const onSubmit = (data) => {
    const regData = {
      Email: email,
      FirstName: name,
      Gender: gender,
      BirthDate: birthDate,
      PhoneNumber: phone,
      Password: password,
      ConfirmPassword: password,
    };
    props.submitted(regData); 
  }

  return (
    <div className={classes.Register}>
      <div className="">
        <div className="text-center text-md-left">
        <img src={mainLogo} className={cx(classes.logo)} />
        </div>
        <h3 className={cx("text-center text-md-left",classes.HeaderRegister)}>{t("create-new-account")}</h3>
        <p className="mb-3">{t("create-new-account-para")}</p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <AuthInput
          classCustomName="mt-0 mt-md-1 mb-2"
            type="text"
            name="name"
            placeholder={t("signup-name")}
            // value={name}
            register={register}
            changed={(event) => setName(event.target.value)}
          />
          {errors.name?.message && <p className={classes.errorMessage}>{errors.name?.message}</p>}
          <AuthInput
          classCustomName="mt-1 mb-2"
            type="phone"
            name="phone"
            placeholder={t("signup-phone")}
            // value={phone}
            register={register}
            changed={(event) => setPhone(event.target.value)}
          />
          {errors.phone?.message && <p className={classes.errorMessage}>{errors.phone?.message}</p>}
          <AuthInput
            type="email"
            name="email"
            placeholder={t("signup-email")}
            // value={email}
            register={register}
            changed={(event) => setEmail(event.target.value)}
            classCustomName="mt-1 mb-2"
          />
          {errors.email?.message &&<p className={classes.errorMessage}>{errors.email?.message}</p>}
          <AuthInput
            type="password"
            name="password"
            placeholder={t("signup-password")}
            // value={password}
            register={register}
            changed={(event) => setPassword(event.target.value)}
            classCustomName="mt-1 mb-2"
          />
          {errors.password?.message&&<p className={classes.errorMessage}>{errors.password?.message}</p>}
          <AuthSelect
            optionsElements={[{label:t("signup-gender-male"), value:"male"},{label:t("signup-gender-female"),value:"female"}]}
            placeholder={t("signup-gender")}
            classCustomName="mt-1 mb-2"
            value={gender}
            name="gender"
            changed={(event) => setGender(event.value)}
          />
          <AuthInput
            type="date"
            name="date"
            register={register}
            placeholder={t("signup-birthdate")}
            classCustomName="mt-1"
            // value={birthDate}
            changed={(event) => setBirthDate(event.target.value)}
          />
          <div className={cx(classes.RegisterButton)}>
          <AuthButton disabled={!isValid} loading={props.loading} text={t("create-new-account")}/>
          </div>
          <div className={cx(classes.registerLinkDiv, "d-flex justify-content-center")}>
            <p>{t("have-account")}</p>
            <Link className={classes.registerLink} to="/login">
              {t("loginTitle")}
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};
export default RegisterForm;
