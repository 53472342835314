import * as actionTypes from '../actions/actionTypes'
const defaultState = {
    targetService :null,
    targetSpecsItem : null,
    serviceSpecs : null,
    requestSuccess : false,
    loading: false
}
export default (state = defaultState, action) => {
    switch (action.type) {
        case actionTypes.GET_SERVICES_SUCCESS:
            return{
                ...state,
                targetService : action.targetService
            }
        case actionTypes.GET_SERVICE_SPECS:
            return{
                ...state,
                serviceSpecs: action.serviceSpecs

            }
        case actionTypes.GET_SERVICE_SPECS_ITEM_SUCCESS:
            return{
                ...state,
                targetSpecsItem : action.targetSpecsItem
            }
        case actionTypes.SUBMIT_REQUEST_SUCCESS:
            return{
                ...state,
                requestSuccess : true,
                loading : false
            }
        case actionTypes.SUBMIT_REQUEST_START:
            return{
                ...state,
                loading :true
            }
        case actionTypes.RESET_REQUEST_CYCLE:
            return {
                ...state,
                requestSuccess : false
            }
        default:
            return state;
    }
    
}