import React from 'react'
import successImg from '../../../../../assets/img/auth/034-security-1.png'
import cx from 'classnames'
import * as classes from './ForgetPasswordSuccess.module.scss'
import { faChevronCircleRight, faChevronCircleLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom'
export default function ForgetPasswordSuccess(props) {
    const { t, i18n } = useTranslation();
    return (
        <div className={classes.ForPassSuccess}>
            <div className={cx("text-center", classes.MainContent)}>
                <img src={successImg} className="" />
                <div className="px-5 px-md-0">
                <h3 className="mx-auto">{t("password-changed-successfully")}</h3>
                </div>
                <div className={cx(classes.ServicesPath, " mx-auto py-3 d-flex justify-content-center")}>

                    {props.dir === 'ar' ?
                        <FontAwesomeIcon className="mt-1 mr-3" icon={faChevronCircleRight} /> : <FontAwesomeIcon className="mt-1 mr-3" icon={faChevronCircleLeft} />
                    }
                    <Link to="/">{t("otp-success-para")}</Link>
                </div>
            </div>
        </div>
    )
}
