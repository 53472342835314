import axios from "../../axios";
import * as actiontypes from "./actionTypes";

export const getServiceSuccess = (targetService) => {
    return {
        type: actiontypes.GET_SERVICES_SUCCESS,
        targetService: targetService
    }

}

export const resetRequestCycle = () => {
    return {
        type: actiontypes.RESET_REQUEST_CYCLE
    }

}

export const getService = (id) => {
    return dispatch => {
        axios.get("/Service/st").then(res => {
            const resObject = res.data.responseobject;
            const targetService = resObject.filter(el => {
                return el.ID == id
            })
            dispatch(getServiceSuccess(targetService[0]))
        })
    }
}
export const getServiceSpecsSuccess = (serviceSpecs) => {
    return {
        type : actiontypes.GET_SERVICE_SPECS,
        serviceSpecs : serviceSpecs
    }
}
export const getServiceSpecs = (id, lang) => {
    const reqData = {
        ServiceTypeID: id,
        LangID: lang == 'ar' ? '2' : '1'
    }
    return dispatch => {
        axios.post('/Service/spec', reqData).then(res => {
            dispatch(getServiceSpecsSuccess(res.data.responseobject))
        })
    }

}






export const getServiceSpecsItemSuccess = (targetSpecsItem)=>{
    return {
        type : actiontypes.GET_SERVICE_SPECS_ITEM_SUCCESS,
        targetSpecsItem : targetSpecsItem
    }
}


export const getServiceSpecsItem = (serviceID,specID,lang)=>{
    const reqData =  {
        ServiceTypeID: serviceID,
        LangID: lang == 'ar' ? '2' : '1'
    }
    console.log(reqData)
    return dispatch=>{
        axios.post('/Service/spec', reqData).then(res => {
            const resObject = res.data.responseobject;
            const targetSpecsItem = resObject.filter(el => {
                return el.ID == specID
            })
            dispatch(getServiceSpecsItemSuccess(targetSpecsItem[0]))
        })

    }
}
export const submitRequest =(reqdata)=>{
    return dispatch=>{
        dispatch(submitRequestStart())
        axios.post('/ServiceRequest/submitrequest',reqdata).then(res=>{
            if (res.data.statuscode == 200) {
                dispatch(submitRequestSuccess());
            }
        })
    }
}
export const submitRequestStart = ()=>{
    return {
        type : actiontypes.SUBMIT_REQUEST_START
    }
}
export const submitRequestSuccess = ()=>{
    return {
        type : actiontypes.SUBMIT_REQUEST_SUCCESS
    }
}
