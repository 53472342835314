const en = {
  "loginTitle" : 'Sign In',
  "loginParagraph" : 'Enter your account data',
  "phoneNumber" : 'Phone Number',
  "password" : 'Password',
  "loginRegister" : 'Create new account',
  "forgetPassword": "Forget Password ?",
  "no-phone-error-message": "Your Mobile Number",
  "phone-not-valid-error-message":"Your Mobile Number Should Be 11 Digit",
  "email-not-valid-error-message": "Email ID is not valid",
  "password-contains-error-message": "Password should contain letters & numbers . ",
  "no-password-error-message": "Your Password",
  "create-new-account": "Create new account",
  "create-new-account-para" : "Enter personal data for your account",
  "signup-name": "Your Full Name",
  "signup-email": "Your Email (optional )",
  "signup-phone": "Your Mobile Number",
  "signup-password": "Your Password",
  "signup-gender" : "Gender (optional)",
  "signup-gender-male" : "Male",
  "signup-gender-female" : "Female",
  "signup-birthdate": "Birthdate (optional)",
  "signup-password-example": "Password should contain letters & numbers for example: password1",
  "have-account": "Do you have account ?",
  "no-name-error-message": "Your Name",
  "name-not-valid-message":"Name should contain letters",
  "back": "Back",
  "confirm-code": "Confirm Code (OTP)",
  "enter-confirm-code": "Enter (OTP) Code",
  "verify" : "Verify",
  "send-code-again": "Send OTP Code Again",
  "send-code-validation" : "Enter OTP correctly",
  "otp-success": "Account Activated ",
  "otp-success-para" :"Services search",
  "forget-password":"Forget Password ?",
  "forget-password-paragraph" : "Confirm phone Number and you will receive OTP to reset password",
  "send-code" : "Send OTP",
  "reset-password" : "Reset new password",
  "new-password" : "New Password",
  "new-password-confirm" : "Confirm New Password",
  "change-password" : "Change Password",
  "password-no-match-error-message": "Password confirmation field mismatch your password",
  "password-changed-successfully": "Password Changed Successfully",
  "my-account" : "My account",
  "my-healthy" : "My Healthy",
  "notifications" : "Notifications",
  "settings" : "Settings",
  "sign-out" : "Sign out",
  "sign-in" :  "Sign In",
  "services" : "Services",
  "services-providers" : "Services Providers",
  "call-request" : "Contact customer service",
  "download-app" : "Download App Now",
  "quick-links" : "Quick links",
  "join-us" : "Join us",
  "medical-articles" : "Medical articles",
  "contact-us" : "Contact us",
  "quick-info" : "Contact information",
  "subscribe" : "Subscribe to the newsletter",
  "email" : "Email",
  "subscribe-button" : "Subscribe",
  "address" : "4 C Ahmed Kamel Street, branched from Laselky, New Maadi, Cairo, Egypt",
  "terms" : "Terms And Conditions",
  
  "call-order-first-section-paragraph" : "Describe what you feel",
  "free-service" : "Free Service",
  "order-call" : "Contact customer service",
  "order-call-para" : "You can also choose one of the symptoms to book your service, and we will contact you immediately",
  "other-symptoms" : "Do you have other symptoms?",
  "describe-symptoms" : "Describe symptoms",
  "increased-perspiration":"Increased perspiration",
  "preasure" : "High/low pressure",
  "cough" : "Cough",
  "dizziness" : "Dizziness",
  "scaly-skin" : "Scaly skin",
  "breathing-difficulties" : "Breathing difficulties",
  "call-request-contact-us" : "Contact with Hospitalia's registered doctors",
  "confirm-call-request" : "Confirm Call Request",
  "enter-name-phone" : "Enter your name and phone",
  "accept-conditions" : "I agree to the terms and conditions",
  "request-confirmed": "Request Confirmed",
  "request-confirmed-para" : "We will call you soon",
  "request-confirmed-second-para" : "You can track alerts and the status of your requests through the notifications page",
  "ok":"OK",
  "otp-error" : "OTP is not correct",


  "home-hospital" : "Hospital at home",
  "home-hospitalia" : "Medical services at home in Cairo and Alexandria",
  "home-doctor": "Home Doctor",
  "home-nursing": "Home Nursing",
  "physical-therapy": "Physiotherapy",
  "rays":"Radiology",
  "tests" : "Labs",
  "book-now" : "Book Now",
  "call-req-land" :"Select what you feel and we will call you soon",
  

  "hospitalia-services": "Hospitalia services",
  "available-specialities": "Home visits medical specialities",
  "available-nursing" : "Home visits nursing specialities",
  "available-pys" : "Available specialties in physical therapy",
  "choose-service-house": "choose your home healthcare service",
  "doctor-nurses-registerd" : "Hospitalia network team",
  "labs-registerd" : "Hospitalia accredited radiology laboratories and centers",
  "available-tests":"A wide range of laboratory tests",
  "available-xrays": "Home X-ray services available",
  "home-medicine" : "More about home healthcare",

  "describe-feeling" : "Describe how you are feeling",
  "describe-status" :"Describe the condition",
  "upload-tests" : "Please attach any medical photos / reports, x-rays or tests",
  "enter-address" :"Please enter the address in detail",
  "select-location" : "Select location",
  "service-fee" : "Service cost starts from",
  "collect-fee" : "We collect it after receiving service",
  "address-detailed": "Enter the address in detail",
  "pick-date-time" : "Choose a date and time",

  "please-sign-in": "Please sign in",
  "please-sign-in-para":"To be able to reserve this service",
  "booking-success" : "Success",
  "booking-button" : "Book",
  "am" : "Morning",
  "pm" :"Evening",

  "yes": "Yes",
  "no" : "No",
  "smoker" : "Do you smoke?",
  "gender" : "Gender",
  "birthday":"Birthdate",
  "alcoholic" : "Do you drink alcoholic beverages?",
  "unknown" : "Unknown",
  "medical-file" : "Your Hospitalia medical file",
  "medical-history":"Add a medical history",
  "describe-medical-history": "Describe your medical history",
  "write-current-medicines": "Mention your current medications",
  "write-family-deseases": "Mention your familial diseases",
  "current-medications" : "Add current medications",
  "family-diseases" : "Add family diseases",
  "search-box-placeholder":"Enter Address",
  "account-full-name" : "Fullname :",
  "account-phone" : "Phone number :",
  "account-email" : "Email :",
  "add-address" : "Add home address",
  "enter-address": "Please enter the address in detail",
  "enter-address-place" : "Enter the address in detail",
  "save" : "Save",
  "notifications": "Notifications",
  "request-status-message": "Follow up appointments and service status",
  "order-status" : "Order status",
  "number": "Number",
  "service-evaluation":"Service evaluation",
  "service-evaluation-para": "How satisfied are you with the services",
  "comment" : "Comment",
  "rate-hosbitalia-modal":"Describe your opinion or any comments about the service",
  "finish":"Finish",
  "rate-tip":"Click here to rate the service",
  "detailed-address": "Detailed address:",
  "map-location" : "Location",
  "edit-profile" : "Edit Account information",
  "no-notification" : "There are no new notifications",
  "choose-blood-type":"Choose your blood type",
  "weight":"Weight",
  "height" : "Height",
  "kg" : "kg",
  "cm" : "cm",
  "enter-weight" : "Enter the weight",
  "enter-height" : "Enter the height",
  "file-error": "File format must be .jpg, .png or .pdf and not exceed 1.5 MB",
  "describe-medical-history": "Describe your medical history",
  "attach-reports": "Attach images , medical reports , X-rays or Lab tests",
  "join-us-services" : "Start by choosing the service you want to join",
  "join-us-fullname" : "Fullname",
  "join-us-email" : "Email",
  "join-us-region" : "Choose a region",
  "join-us-major" : "Choose a major",
  "join-us-experience" : "Experience",
  "join-us-tell-experience" : "Tell us about your professional experiences",
  "join-us-info" : "Enter the following data",
  "cv": "CV",
  "join-us-upload-cv":"Upload a copy of your CV",
  "join-us-practice" : "Practicing profession card",
  "join-us-upload-practice":"Upload a copy of your Practicing profession card",
  "join-us-id" : "ID",
  "join-us-upload-id" : "Upload a copy of your ID",
  "join-us-button" : "Confirm your request",
  "join-us-header" : "To provide the highest level of integrated home health care",
  "join-us-city1" : "Cairo",
  "join-us-city2" : "Alexandria",
  "join-us-xray" : "Radiology center",
  "join-us-tests": "Laboratories",
  "join-us-phys" : "Physical therapy",
  "join-us-nur" : "Nursing",
  "join-us-docs" : "Doctors",
  "no-region-error-message":"Your region",
  "no-major-error-message":"Your major",
  "no-experience-error-message":"Enter a summary of your experience",
  "no-cv-error-message" : "Your CV",
  "no-practice-card-error-message" : "Your practice card",
  "no-id-error-message" : "Your ID",
  "cancel":"Cancel",
  "date": "Date",
  "medical-record": "Medical Record",
  "medical-description-error" : "ُEnter medical history",
  "page-title":"Hospitalia | Integrated Home Medical Services",
  "page-description":"HOSPITALIA is e-health services marketplace that connects patients with multiple in-home healthcare including access to top tier health care providers and organizations either through home visits or virtual consultations.",
  "payment-method":"Payment method",
  "add-promocode":"add promocode",
  "promocode-pop-title":"promocode",
  "agree":"ok",
  "promocode-input-placeholder":"enter promocode",
  "promo-success-message":"Promo Code has been applied",
  "promocode-applied":"promocode has been applied",
  "service-cost": "Service fees",
  "egyptian-pound": "EGP",
  "choose-appropriate-time-online":"Select appropriate call time",
  "not-available-message":"Not available now: working hours are from 10 am to 11 pm",
  "customer-service" : "Customer service"
};
  
export default en;
  