import React, { Component } from "react";
import * as classes from "./HealthyAttachmentForm.module.scss";
import cx from "classnames";
import imagesPhoto from "../../../../../assets/img/bookingService/images.png";
import Button from "../../../../shared/Button/Button";
import deleteIcon from "../../../../../assets/img/healthy/positioned-x.png";
import pdfImg from "../../../../../assets/img/healthy/pdf.png";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import * as actions from '../../../../../store/actions/index'
import Cookies from "js-cookie";
import moment from "moment";
import {
  Link, Redirect
} from "react-router-dom";
const mapStateToProps = (state) => {
  return {
    clientID: state.common.clientID,
    lang: state.common.currentLanguage,
    customerMedicalRecords: state.profile.customerMedicalRecords,
    customerMedicationsRecord : state.profile.customerMedicationsRecord,
    customerSymptomsRecords: state.profile.customerSymptomsRecords,
    redirectToHealthyHome : state.profile.redirectToHealthyHome,
    loading : state.profile.loading
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    getCustomerMedicalRecords: (id, lang) => dispatch(actions.getCustomerMedicalRecords(id, lang)),
    getcustomerMedications : (id, lang) => dispatch(actions.getcustomerMedications(id, lang)),
    getCustomerSymptoms : (id, lang) => dispatch(actions.getCustomerSymptoms(id, lang)),
    addSymptom : (customerID, description) => dispatch(actions.addSymptom(customerID, description)),
    addMedication : (customerID, description) => dispatch(actions.addMedication(customerID, description)),
    addMedicalHistory : (customerID, description)=>dispatch(actions.addMedicalHistory(customerID, description))

  }
}
export class HealthyAttachmentForm extends Component {
  state = {
    description: "",
    descriptionError:"",
    file1: "",
    file1Viewer: "",
    file2: "",
    file2Viewer: "",
    fileUploadError: "",
    dataTable: [],
    lng:Cookies.get('language')
  };

  // check if file extention meet requirments
  checkFile = (fileName, fileSize) => {
    let fileExtension = fileName.split(".").pop();
    if (
      (fileExtension === "jpg" ||
        fileExtension === "png" ||
        fileExtension === "pdf") &&
      fileSize / 1024 < 1500
    ) {
      return true;
    } else {
      return false;
    }
  };
  // set files attributes
  handelFileInput = (event, id) => {
    event.preventDefault();
    this.setState({
      ...this.state,
      fileUploadError: ""
    })
    if (id == 1) {
      let fileName = event.target.files[0].name;
      let fileSize = event.target.files[0].size;
      if (this.checkFile(fileName, fileSize)) {
        this.setState({
          ...this.state,
          file1: event.target.files[0]
        })
        this.setState({
          ...this.state,
          file1Viewer: URL.createObjectURL(event.target.files[0])
        })
      } else {
        this.setState({
          ...this.state,
          fileUploadError: this.props.t("file-error")
        })
      }
    } else {
      let fileName = event.target.files[0].name;
      let fileSize = event.target.files[0].size;
      if (this.checkFile(fileName, fileSize)) {
        this.setState({
          ...this.state,
          file2: event.target.files[0]
        })
        this.setState({
          ...this.state,
          file2Viewer: URL.createObjectURL(event.target.files[0])
        })
      } else {
        this.setState({
          ...this.state,
          fileUploadError: this.props.t("file-error")
        })
      }
    }
  };
  //remove file uploaded
  removeImg = (event, id) => {
    event.preventDefault();
    if (id == 1) {
      this.setState({
        ...this.state,
        file1: ""
      })
      this.setState({
        ...this.state,
        file1Viewer: ""
      })
    } else {
      this.setState({
        ...this.state,
        file2: ""
      })
      this.setState({
        ...this.state,
        file2Viewer: ""
      })
    }
  };
  submitForm = ()=>{
    if(this.props.familyDisease){
      this.props.addSymptom(this.props.clientID,this.state.description);

    }
    if(this.props.medications){
      this.props.addMedication(this.props.clientID,this.state.description)
    }
    if(this.props.medicationHistory){
      this.props.addMedicalHistory(this.props.clientID,this.state.description)
    }
  }
  tableContentFill = (prop,att1,att2)=>{
    let dataContainer = [...prop];
    return dataContainer.map(el => {
      return <tr key={el.ID}>
        <td >{moment(el[att1]).locale(this.state.lng).format('DD-MM-YYYY') }</td>
        <td >{el[att2]}</td>
      </tr>
    })
  }
  componentDidMount() {
    if (this.props.medicationHistory) {
      this.props.getCustomerMedicalRecords(this.props.clientID, this.props.lang);
      
    }
    if (this.props.medications) {
      this.props.getcustomerMedications(this.props.clientID, this.props.lang);
      
    }
    if (this.props.familyDisease) {
      this.props.getCustomerSymptoms(this.props.clientID, this.props.lang);
      
    }

  }
  render() {
    let tableContent = null;
    if(this.props.redirectToHealthyHome){
      return <Redirect to="/healthy" />
    }
    if (this.props.medicationHistory) {
      console.log(this.props.customerMedicalRecords)
      tableContent=this.tableContentFill(this.props.customerMedicalRecords,"Diagnosis","Comment")
    }
    if(this.props.medications){
      console.log(this.props.customerMedicationsRecord)
      tableContent=this.tableContentFill(this.props.customerMedicationsRecord,'Dosage','MedicationName')
    }
    if(this.props.familyDisease){
      //console.log(this.props.getCustomerSymptoms)
      tableContent=this.tableContentFill(this.props.customerSymptomsRecords,'LastRequestUpdate','SymptomDescription')}
    return (
      <div
        className={cx(
          "row",
          classes.HealthyAttachmentForm,
          this.props.classCustomName
        )}
      >
        <div className={cx("col-12 d-flex", classes.HeadSection)}>
          <img className={classes.SectionImg} src={this.props.img} />
          <p className={classes.Head}>{this.props.head}</p>
        </div>
        <div className="col-12 px-3 px-md-5">
          <hr />
        </div>
        <div className="col-12">
          <div className={cx("row", classes.FormSection)}>
            <p className="col-12 mb-3">{this.props.formLabel}</p>
            <textarea
              className={cx(
                "form-control col-12 col-md-6 mb-4",
                classes.Textarea
              )}
              onChange={(event) => {
                this.setState({
                  ...this.state,
                  description: event.target.value
                })
              }}
            ></textarea>
          </div>
          {this.props.medicationHistory ? (
            <div className={cx("row", classes.FormSection)}>
              <p className="col-12 mb-3">{this.props.t("attach-reports")}</p>
              <div className="d-flex mt-1">
                <label
                  htmlFor="file-input1"
                  className=" d-flex justify-content-center align-items-center"
                >
                  {this.state.file1Viewer ? (
                    <img
                      className={classes.FaCustom}
                      src={deleteIcon}
                      onClick={(event) => this.removeImg(event, 1)}
                    />
                  ) : null}
                  {this.state.file1Viewer ? (
                    <img
                      className={cx("img-responsive", classes.Img)}
                      src={this.state.file1Viewer}
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = pdfImg;
                      }}
                    />
                  ) : (
                    <img src={imagesPhoto} />
                  )}
                </label>
                <input
                  id="file-input1"
                  type="file"
                  className="form-control"
                  onChange={(event) => {
                    this.handelFileInput(event, 1);
                  }}
                />
                <label
                  htmlFor="file-input2"
                  className=" ml-2 d-flex justify-content-center align-items-center"
                >
                  {this.state.file2Viewer ? (
                    <img
                      className={cx("img-responsive", classes.Img)}
                      src={this.state.file2Viewer}
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = pdfImg;
                      }}
                    />
                  ) : (
                    <img src={imagesPhoto} />
                  )}
                  {this.state.file2Viewer ? (
                    <img
                      className={classes.FaCustom}
                      src={deleteIcon}
                      onClick={(event) => this.removeImg(event, 2)}
                    />
                  ) : null}
                </label>
                <input
                  id="file-input2"
                  type="file"
                  className="form-control ml-2"
                  onChange={(event) => {
                    this.handelFileInput(event, 2);
                  }}
                />
              </div>
            </div>
          ) : null}
          {this.state.fileUploadError ? (
            <p
              className={classes.FormSection}
              style={{ color: "red", fontWeight: "normal" }}
            >
              {this.state.fileUploadError}
            </p>
          ) : null}
          <div className={cx("col-12  col-md-5 col-lg-5 col-xl-4 d-flex justify-content-between", classes.ButtonContainer)}>
            <div className={classes.SubmitButton}>
              <Button loading={this.props.loading} text={this.props.t("save")} clicked={()=>{this.submitForm()}} disabled={!this.state.description} />
            </div>
            <div>
              <Link className={cx(classes.BackButton,"btn btn-default")} to="/healthy"><span>{this.props.t("back")}</span></Link>
            </div>
          </div>
          <div className={cx("col-md-8 col-12",classes.TableContainer)}>
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">{this.props.t("date")}</th>
                  <th scope="col">{this.props.t("medical-record")}</th>
                </tr>
              </thead>
              <tbody>

                {tableContent}

              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}
const Extended = withTranslation()(HealthyAttachmentForm);
Extended.static = HealthyAttachmentForm.static;
export default connect(mapStateToProps, mapDispatchToProps)(Extended);