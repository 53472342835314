import React from "react";
import { Link } from "react-router-dom";
import * as classes from "./Footer.module.scss";
import cx from "classnames";
import footerLogo from "../../../assets/img/auth/hospitalia-logo-2.png";
import FooterInput from "./FooterInput/FooterInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import {
  faPhoneAlt,
  faEnvelope,
  faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebookSquare,
  faInstagram,
  faTwitter,
  faLinkedinIn,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import googlePlayLogo from "../../../assets/img/Footer/White - Googleplay.png";
import appleStoreLogo from "../../../assets/img/Footer/White - Appstore.png";

const Footer = (props) => {
  const { t, i18n } = useTranslation();
  return (
    <div className={cx("navbar-fixed-bottom ", classes.Footer)}>
      <div className={cx("Container-fluid", classes.Container)}>
        <div className={cx("row", classes.Row)}>
          <div className="col-md-6 col-lg-4 col-xl-4">
            <div className="text-center text-md-left">
              <img src={footerLogo} className={cx(classes.FooterLogo)} />
            </div>

            <p className={cx("mb-2", classes.ParaSubHeader)}>
              {t("download-app")}
            </p>
            <div className="mb-2">
              <a
                target="_blank"
                href="https://play.google.com/store/apps/details?id=com.hospitalia&hl=en"
              >
                <img src={googlePlayLogo} className={classes.googlePlay} />
              </a>
            </div>
            <div className={cx("")}>
              <a
                target="_blank"
                href="https://apps.apple.com/sa/app/hospitalia/id1524945078"
              >
                <img className={classes.appleLogo} src={appleStoreLogo} />
              </a>
            </div>
          </div>
          <div className="col-md-6 col-lg-3 col-xl-2 ">
            <p className={cx(classes.ParaHeader)}>{t("quick-links")}</p>
            <p className="mb-3">
              <Link to="/">{t("services")}</Link>
            </p>
            {/*<p className="mb-3">
              <Link className={classes.Unused} to="/" onClick={(event)=>{event.preventDefault()}}>{t("services-providers")}</Link>
  </p>*/}
            <p className="mb-3">
              <Link
                className={classes.Unused}
                to="/"
                onClick={(event) => {
                  event.preventDefault();
                }}
              >
                {t("join-us")}
              </Link>
            </p>
            <p className="mb-3">
              <a href="https://blog.hospitalia.net/" target="_blank">
                {" "}
                {t("medical-articles")}
              </a>
            </p>
            {/* <p className="">
              <Link
                to="/"
                className={classes.Unused}
                onClick={(event) => {
                  event.preventDefault();
                }}
              >
                {t("contact-us")}
              </Link>
            </p> */}
          </div>
          <div className="col-md-6 col-lg-4 col-xl-3 ">
            <p className={cx(classes.ParaHeader)}> {t("quick-info")}</p>
            <p className={cx(classes.ContactUs, "mb-3")}>
              <FontAwesomeIcon
                className={cx(classes.fontAwesome, "mr-2")}
                icon={faPhoneAlt}
              />{" "}
              01100080900
            </p>
            <p
              className={cx(
                classes.ContactUs,
                "mt-1 mb-3",
                classes.FooterEnglish
              )}
            >
              <FontAwesomeIcon
                className={cx(classes.fontAwesome, "mr-2")}
                icon={faEnvelope}
              />
              info@hospitalia.net
            </p>
            <p className={cx(classes.ContactUs, "")} style={{ width: "200px" }}>
              <FontAwesomeIcon
                className={cx(classes.fontAwesome, "mr-2")}
                icon={faMapMarkerAlt}
              />
              {t("address")}
            </p>
          </div>
          <div className="col-md-6 col-xl-3 col-lg-12 d-flex align-items-xl-end align-items-start pb-5">
            <div
              style={{ width: "100%" }}
              className={cx(
                "d-flex justify-content-center justify-content-md-start justify-content-lg-center justify-content-xl-end ",
                classes.SocialContainer
              )}
            >
              <a
                href="https://www.youtube.com/channel/UCDh1ZhoE1Prcn_DbqkgKoWg"
                target="_blank"
              >
                <FontAwesomeIcon
                  className={classes.fontAwesomeSocial}
                  icon={faYoutube}
                />
              </a>
              <a
                href="https://www.linkedin.com/company/hospitalia247/"
                target="_blank"
              >
                <FontAwesomeIcon
                  className={classes.fontAwesomeSocial}
                  icon={faLinkedinIn}
                />
              </a>
              <a href="https://twitter.com/hospitalia247" target="_blank">
                <FontAwesomeIcon
                  className={classes.fontAwesomeSocial}
                  icon={faTwitter}
                />
              </a>
              <a
                href="https://www.instagram.com/hospitalia247/"
                target="_blank"
              >
                <FontAwesomeIcon
                  className={classes.fontAwesomeSocial}
                  icon={faInstagram}
                />
              </a>
              <a href="https://www.facebook.com/hospitalia247" target="_blank">
                <FontAwesomeIcon
                  className={classes.fontAwesomeSocial}
                  icon={faFacebookSquare}
                />
              </a>
            </div>
          </div>
        </div>
        <hr />
        <div className={cx("row", classes.CopyRights, classes.Row)}>
          <div className="col-md-6">
            <p className="d-flex justify-content-center justify-content-md-start mb-3 mb-md-0">
            <Link
                to="/terms-conditions"
              >
                {t("terms")}
              </Link>
            </p>
          </div>
          <div className="d-flex col-md-6 d-flex justify-content-center justify-content-md-end">
            <p className={classes.FooterEnglish} dir="ltr">
              © 2021 Hospitalia. All Rights Reserved.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Footer;
