import React from "react";
import * as classes from "./NewBookingServiceCard.module.scss";
import cx from "classnames";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import {isMobile , isTablet} from 'react-device-detect';
import infoIcon from './../../../../assets/img/bookingService/info-icon.svg';
export default function BookingServiceCard(props) {
  return (
    <div
      className={cx(
        classes.BookingServiceCard,
        "mb-3 px-3 px-md-2"
      )}
      data-tip={props.name}
    >
      <Link onClick={props.clicked} to="/">
        <div className="d-flex align-items-center">
            <img className="mt-3 mb-2" src={props.img} />
            <p className={classes.CardText}>
            {props?.name?.length > 24
                ? props?.name?.substring(0, 23) + "..."
                : props?.name}
            </p>
        </div>
        <div className={classes.DescriptionWrapper}>
            <img src={infoIcon} className={cx(classes.InfoIcon)}/>
            <p className={classes.DescriptionText}>
                {props.description}
            </p>
        </div>
      </Link>
      <ReactTooltip place="bottom" type="dark" effect="float" disable={isMobile || isTablet}/>
    </div>
  );
}
