import React ,{useState,useEffect} from "react";
import Calendar from "react-calendar";
import "./Calendar.css";
import moment from 'moment'
import {
  faChevronLeft,
  faChevronRight
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
export default function BookingServiceCalender(props) {
const [value, setValue] = useState(new Date());
const setBookingDate = (val,event)=>{
  let momentDate= moment(val);
  setValue(val)
  props.submitDate(momentDate);

}

const minDate = ()=>{
  if(props.service.location_type==1){
    return new Date();
  }else{
    if(moment().isBefore(moment(new Date()).set({hour:19,minute:45}))){
      return new Date();
    }else {
      return new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
    }
  }
}
  return (
    <div>
      <Calendar
        locale={props.lang === "ar" ? "ar-AR" : "en-EN"}
        calendarType="Arabic"
        next2Label={null}
        prev2Label={null}
        minDate={minDate()}
        onChange={(val,event)=>{setBookingDate(val,event)}}
        value={value}
        prevLabel={<FontAwesomeIcon color="#585858" icon={props.lang === "ar"?faChevronRight:faChevronLeft}  />}
        nextLabel={<FontAwesomeIcon color="#585858" icon={props.lang === "ar"?faChevronLeft:faChevronRight}  />}
      />
    </div>
  );
}
