import React from "react";
import Select from "react-select";
const customStyle = {
  control: (base) => ({
    ...base,
    height: 45,
    background: "#fff 0% 0% no-repeat padding-box",
    border: "1px solid #e7ddeb",

  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: "#B1B1B1",
  }),
};
export default function JoinUsSelect(props) {
  return (
    <Select
      ref={props.register}
      name={props.name}
      options={props.optionsElements}
      onChange={props.changed}
      className="mt-3"
      placeholder={props.placeholder}
      styles={customStyle}
      defaultValue={props.defaultValue}
      components={{
        IndicatorSeparator: () => null,
      }}
      theme={theme => ({
        ...theme,
        borderRadius: 4,
        colors: {
          ...theme.colors,
          primary: '#8802B4',
        },
      })}
    ></Select>
  );
}
