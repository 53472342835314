import React, { Component } from "react";
import { connect } from "react-redux";
import * as classes from "./Notifications.module.scss";
import cx from "classnames";
import * as actions from "../../../../store/actions/index";
import NotificationsHeader from "./NotificationsHeader/NotificationsHeader";
import { withTranslation } from "react-i18next";
import OrderStatusCard from "./OrderStatusCard/OrderStatusCard";
import RateModal from "./RateModal/RateModal";
import NotificationCard from "./NotificationCard/NotificationCard";
import LoadingPage from "../../../shared/LoadingPage/LoadingPage";
const mapStateToProps = (state) => {
  return {
    lang: state.common.currentLanguage,
    userID: state.common.clientID,
    notifications: state.profile.notifications,
    ordinaryNotifications: state.profile.ordinaryNotifications,
    orderNotifications: state.profile.orderNotifications,
    loading: state.profile.loading,
    rateMessage: state.profile.rateMessage,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getNotificationListItems: (userID, lang) => {
      dispatch(actions.getNotificationList(userID, lang));
    },
    rateService: (id, rate, comment) => {
      dispatch(actions.rateService(id, rate, comment));
    },
    removeMessage: () => {
      dispatch(actions.removeMessage());
    },
  };
};
export class Notifications extends Component {
  state = {
    notifiType: 0,
    showModal: false,
    targetRatedService: null,
    notificationType: {
      1: {
        color: "#9FE5FF",
      },
      2: {
        color: "#F9EE8C",
      },
      3: {
        color: "#CCFFAF",
      },
      4: {
        color: "#CCFFAF",
      },
      5: {
        color: "#FFB4AF",
      },
      6: {
        color: "#9FE5FF",
      },
      7: {
        color: "#CCFFAF",
      },
      8: {
        color: "#00F50A",
      },
    },
  };
  changeNotification = (buttonID) => {
    if (buttonID == 0) {
      this.setState({
        ...this.state,
        notifiType: 0,
      });
    } else {
      this.setState({
        ...this.state,
        notifiType: 1,
      });
    }
  };
  onCloseModal = () => {
    this.setState({
      ...this.state,
      showModal: false,
      targetRatedService: null,
    });
    this.props.removeMessage();
  };
  submitRating = (rating, comment) => {
    this.props.rateService(this.state.targetRatedService, rating, comment);
  };
  componentDidMount() {
    this.props.getNotificationListItems(this.props.userID, this.props.lang);
  }
  render() {
    if (this.props.notifications) {
      let content = null;
      if (this.state.notifiType === 0) {
        const orderNotifications = [...this.props.orderNotifications];
        if(orderNotifications.length>0){
        content = orderNotifications.map((el) => {
          return (
            <div className={cx("col-12 col-lg-6 ")} key={el.Id}>
              <OrderStatusCard
                img={el.Image}
                id={el.Id}
                speciality={el.Speciality}
                title={el.notificationTitle}
                timestamp={el.TimeStamp}
                lang={this.props.lang}
                badge={el.notificationBadge}
                color={this.state.notificationType[el.NotificationType].color}
                notificationType={el.NotificationType}
                clicked={() => {
                  this.setState({
                    ...this.state,
                    showModal: true,
                    targetRatedService: el.RequestId,
                  });
                }}
              />
            </div>
          );
        });}
        else{
          content=<div className="col-12 col-md-8 offset-md-1"><h1 className={classes.NoNotification}>{this.props.t("no-notification")}</h1></div>
        }
      } else {
        const ordinaryNotifications = [...this.props.ordinaryNotifications];
        if(ordinaryNotifications.length>0){
        
        content = ordinaryNotifications.map((el) => {
          return (
            <div className="col-12 col-md-6" key={el.Id}>
              <NotificationCard
                lang={this.props.lang}
                badge={el.notificationBadge}
                title={el.Title}
                message={el.Message}
              />
            </div>
          );
        });
      }
      else{
        content=<div className="col-12 col-md-6 offset-md-1"><h1 className={classes.NoNotification}>{this.props.t("no-notification")}                </h1></div>
      }
      }
      return (
        <div className={classes.Notifications}>
          <div className="container-fluid">
            <RateModal
              loading={this.props.loading}
              isOpen={this.state.showModal}
              clicked={() => {
                this.onCloseModal();
              }}
              submitted={(rating, comment) => {
                this.submitRating(rating, comment);
                this.onCloseModal();
              }}
              message={this.props.rateMessage}
            />
            <NotificationsHeader count={this.props.notifications.length} />
            <div className={cx("row", classes.TogglerContainer,(this.state.showModal?'hidden':''))}>
              <div
                className={cx(
                  " col-12  d-flex justify-content-center pl-4"
                )}
              >
                <div className={cx(classes.Toggler, "nav nav-pills")}>
                  
                    <button
                      className={cx(
                        "nav-link",
                        this.state.notifiType == 0 ? classes.Active : null
                      )}                      
                      onClick={() => {
                        this.changeNotification(0);
                      }}
                    >
                      {this.props.t("order-status")}
                    </button>
                  
                  
                    <button
                      className={cx(
                        "nav-link",
                        this.state.notifiType == 1 ? classes.Active : null
                      )}
                      
                      onClick={() => {
                        this.changeNotification(1);
                      }}
                    >
                      {this.props.t("notifications")}
                    </button>
                  
                </div>
              </div>
            </div>
            <div className={cx("row", classes.Content,this.state.notifiType==0?classes.OrderNotificationContainer:null)}>
              {
                /*<div className="col-12 col-md-8 offset-md-1">
                <OrderStatusCard
                  img={this.props.notifications[0].Image}
                  id={this.props.notifications[0].Id}
                  speciality={this.props.notifications[0].Speciality}
                  title={this.props.notifications[0].notificationTitle}
                  timestamp={this.props.notifications[0].TimeStamp}
                  lang={this.props.lang}
                  badge={this.props.notifications[0].notificationBadge}
                  color={this.state.notificationType[this.props.notifications[0].NotificationType].color}
                />
                    </div>*/
                content
              }
            </div>
          </div>
        </div>
      );
    } else {
      return <LoadingPage />;
    }
  }
}
const Extended = withTranslation()(Notifications);
Extended.static = Notifications.static;
export default connect(mapStateToProps, mapDispatchToProps)(Extended);
