import React, { useState } from "react";
import * as classes from "./Input.module.scss";
import uploadImg from "../../../assets/img/contactUs/Solid.png";
import deleteFileImg from "../../../assets/img/contactUs/close.png";
import { faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import { toast } from 'react-toastify';
import pdfImg from '../../../assets/img/contactUs/pdf.png'
import jpgImg from '../../../assets/img/contactUs/jpg.png'

const PasswordInput = (props) => {
  const [show, setShow] = useState(false);

  return (
    <div
      className={cx("input-group", props.classCustomName, classes.AuthInput)}
    >
      <input
        className="form-control"
        type={show ? "text" : "password"}
        placeholder={props.placeholder}
        onChange={props.changed}
        name={props.name}
        value={props.value}
        ref={props.register}
        style={({ borderRadius: "4px" }, props.customStyle)}
      />
      <div
        className={cx("input-group-addon addon-custom", classes.addonCustom)}
      >
        <FontAwesomeIcon icon={faEyeSlash} onClick={() => setShow(!show)} />
      </div>
    </div>
  );
};
const DateInput = (props) => {
  const [inputType, setInputType] = useState("text");
  const onFocusInput = () => {
    setInputType("date");
  };
  const onBlurInput = () => {
    setInputType("text");
  };
  return (
    <div className={classes.AuthInput}>
      <input
        className={"form-control ".concat(props.classCustomName)}
        style={props.customStyle}
        type={inputType}
        placeholder={props.placeholder}
        onChange={props.changed}
        name={props.name}
        value={props.value}
        onFocus={onFocusInput}
        ref={props.register}
      />
    </div>
  );
};
const AuthInput = (props) => {
  const { t, i18n } = useTranslation();
  const [file, setFile] = useState('');
  const [extenstion, setExtenstion] = useState('');
  const [fileInput, setFileInput] = useState('');
  const fileValidator = (fileName,fileSize) => {
    let fileExtension = fileName.split('.').pop();
    if ((fileExtension === 'jpg' || fileExtension === 'png' || fileExtension === 'pdf')&&fileSize/1024<1500) {
        setExtenstion(fileExtension)
        return true
    }
    else {
        return false
    }
}
  const handelFileInput = (event) => {
    if(event.target.files[0]){
    const fileName = event.target.files[0].name;
    const fileSize = event.target.files[0].size;
    if(fileValidator(fileName,fileSize)){
      setFileInput(event.target.files[0].name);
      props.changed(event.target.files[0])
    }
    else{
      toast.error(t("file-error"),{autoClose:5000})
    }

  }
  };
  const deleteFile = (event)=>{
    event.preventDefault();
    setFile('');
    setFileInput('');
  }
  if (props.type === "password") {
    return <PasswordInput className={classes.AuthInput} {...props} />;
  } else if (props.type === "date") {
    return <DateInput className={classes.AuthInput} {...props} />;
  } else if (props.type === "file") {
    return (
      <div className={classes.AuthInput}>
        <label className={cx(classes.CustomFileInput)}>
          <input
            ref={props.register}
            type={props.type}
            name={props.name}
            onChange={(event) => {
              handelFileInput(event);
            }}
          />
          {fileInput ? (
            <div
              className="d-flex justify-content-between"
              style={{ width: "100%" }}
            >
              <div className="d-flex align-items-center">
                <div className="mr-2">
                <img src={extenstion == 'pdf' ? pdfImg:jpgImg }/>
                </div>
              <p style={{color:'#000000',fontWeight:'normal'}}>{fileInput}</p>
              </div>
              <div>
                <img src={deleteFileImg} className="mt-2" onClick={(event)=>{deleteFile(event)}}/>
              </div>
            </div>
          ) : (
            <div
              className="d-flex justify-content-between"
              style={{ width: "100%" }}
            >
              <p className={classes.Placeholder}>{props.placeholder}</p>
              <div>
                <img src={uploadImg} className="mt-2" />
              </div>
            </div>
          )}
        </label>
      </div>
    );
  }
  return (
    <div className={classes.AuthInput}>
      <input
        className={"form-control ".concat(props.classCustomName)}
        type={props.type}
        placeholder={props.placeholder}
        onChange={props.changed}
        name={props.name}
        value={props.value}
        ref={props.register}
        style={props.customStyle}
      />
    </div>
  );
};
export default AuthInput;
