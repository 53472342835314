import React , {useEffect,useState}from 'react'
import * as classes from './AccountAddress.module.scss'
import cx from 'classnames'
import { useTranslation } from "react-i18next";
import AccountAddressMap from './AccountAddressMap/AccountAddressMap';
export default function AccountAddress(props) {
    const { t, i18n } = useTranslation();
    const [address,setAddress]=useState("");
    const [lat,setLat]=useState("")
    const [lng,setLng]=useState("")
    useEffect(() => {
        let addressProp=props.address.split('$')
        setAddress(addressProp[0])
        let location= addressProp[1].split(',');
        setLat(location[0]);
        setLng(location[1]);
      });
    return (
        <div className={classes.AccountAddress}>
            <p className={classes.HeadPara}>{t("detailed-address")}</p>
            <p className="mt-3 mb-4">{address} </p>
            <p className={cx(classes.HeadPara,"mt-3")} >{t("map-location")}</p>
            <div className={classes.Map}>
                {lat&&lng?<AccountAddressMap  lat={lat} lng={lng}/>:null}
            </div>
        </div>
    )
}
