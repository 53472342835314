import React from "react";
import * as classes from "./HealthyHeader.module.scss";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import heartBeat from "../../../../../assets/img/healthy/heartbeat.png";
export default function HealthyHeader() {
  const { t, i18n } = useTranslation();
  return (
    <div className={cx(classes.HealthyHeader, "row")}>
      <div className="col-12 col-md-3 col-lg-2">
        <div className="d-flex justify-content-center justify-content-md-end">
          <img src={heartBeat} />
        </div>
      </div>
      <div className="col-12 col-md-9 col-lg-10  text-center text-md-left">
        <h3>{t("my-healthy")}</h3>
        <p>{t("medical-file")}</p>
      </div>
    </div>
  );
}
