import axios from "../../axios";
import * as actiontypes from "./actionTypes";


export const userCallRequestStart = ()=>{
    return {
        type : actiontypes.USER_CALL_REQUEST_START
    }
}
export const userCallRequest = (phoneNumber) => {
    const reqData = {
        PhoneNumber: phoneNumber
    }
    return dispatch => {
        dispatch(userCallRequestStart())
        axios.post('/ServiceRequest/usercallrequest', reqData).then(res => {
            if (res.data.statuscode == 200) {
                dispatch(callRequestSuccess())
            }
        })
    }
}
export const sendGuestOTPStart = ()=>{
    return{
        type : actiontypes.QUEST_SEND_OTP_START
    }
}
export const sendGuestOTP = (phoneNumber) => {
    const reqData = {
        PhoneNumber: phoneNumber
    }
    return dispatch => {
        dispatch(sendGuestOTPStart())
        axios.post('/mng/resendotp', reqData).then(res => {
            
            if (res.data.statuscode == 200) {
                dispatch(sendGuestOTPSuccess())
            }
        })
    }
}
export const guestCallRequest = (name,phone,code)=>{
    const reqData= {
        ClientName : name,
        PhoneNumber : phone,
        OTP : code
    } 
    return dispatch =>{
        dispatch(sendGuestOTPStart())
        axios.post('/ServiceRequest/callrequest',reqData).then(res=>{
            if(res.data.statuscode == 200){
                dispatch(callRequestSuccess())
            }
        })
    }
}
export const sendGuestOTPSuccess = () => {
    return {
        type: actiontypes.QUEST_SEND_OTP_SUCCESS
    }
}
export const callRequestSuccess = () => {
    return {
        type: actiontypes.CALL_REQUEST_SUCCESS
    }
}
export const callRequestReset = ()=>{
    return{
        type : actiontypes.CALL_REQUEST_RESET
    }
}