import React from 'react'
import * as classes from './Modal.module.scss'
import hosLogo from '../../../../assets/img/logos/hoslogo.png'
import cx from 'classnames';
import { useTranslation } from "react-i18next";
import Button from '../../../shared/Button/Button'
import { Link } from "react-router-dom";
import Modal from 'react-modal'
import {
    faTimes
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function CustomModal(props) {
    const { t, i18n } = useTranslation();
    const onNavigateToLogin = ()=>{
        props.history.push('/login')
    }
    return (
        <div>
            <Modal className={cx(classes.Modal)} overlayClassName={classes.Overlay} isOpen={props.isOpen} >
                <div onClick={props.clicked} className={cx(classes.FaCustom,"d-flex justify-content-center align-items-center")}>
                    <FontAwesomeIcon icon={faTimes} color="#707070" />
                </div>
                <div className="text-center">
                    <img src={hosLogo} />
                    <h3>{t("please-sign-in")}</h3>
                    <p>{t("please-sign-in-para")}</p>
                </div>
                <Button text={t("loginTitle")} clicked={()=>{onNavigateToLogin()}} />
                <Link className={cx("btn btn-default py-auto", classes.register)} to="/register" >
                    <p>{t('loginRegister')}</p>
                </Link>

            </Modal>
        </div>
    )
}
