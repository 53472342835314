import React from "react";
import * as classes from "../Register.module.scss";
import cx from "classnames"
import regSuccess from "../../../../../assets/img/auth/register-success.png";
import { faChevronCircleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from 'react-i18next';

export default function RegisterSuccess() {
  const { t, i18n } = useTranslation();
  return (
    <div className={classes.Register}>
        <div className={cx("text-center", classes.MainContent)}>
          <img src={regSuccess} className="mb-3" />
          <h3 className="mx-auto mt-1">{t("otp-success")}</h3>
          <div className={cx(classes.ServicesPath, " mx-auto py-3 d-flex justify-content-center")}>
            <FontAwesomeIcon className="mt-1 mr-3" icon={faChevronCircleRight} />
            <p>{t("otp-success-para")}</p>
          </div>
        </div>
    </div>
  );
}
