import React, { Component } from "react";
import { Link } from "react-router-dom";
import * as classes from "../../pages/Register/Register.module.scss";
import * as otpClasses from './VerificationForm.module.scss';
import cx from "classnames";
import { faChevronRight,faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import smartPhoneImg from "../../../../assets/img/auth/047-smartphone-3.png";
import AuthButton from "../../../shared/Button/Button";
import { withTranslation } from 'react-i18next';
import OtpInput from 'react-otp-input';
const intialState = {
  verificationCode:'',
  valiErrorMessage: ''
};
const OtpInputFocusStyle = {
  boxShadow:'none',
  border:'2px solid #8802b4',
  backgroundColor:'#f4f4fc',
  outline:0,
  color:'#495057'
}
const OtpInputStyle = {
  width:58,
  height:58,
  fontSize:35,
  fontWeight:700,
  color:'#000',
  textAlign:'center',
  borderRadius:12,
  border:'1px solid #ced4da',
  transition:'border-color .15s ease-in-out,box-shadow .15s ease-in-out',
  padding:'.375rem .75rem',
  backgroundColor:'#eee'
}
class VerificationForm extends Component {

  state = intialState;
  handelInputChange = (otp) => {
    this.setState({
      ...this.state,
      verificationCode: otp,
    });
  };
  handelForm = (event) => {
    event.preventDefault();
    this.setState({
      ...this.state,
      valiErrorMessage: ''
    })
    if (this.state.verificationCode.length !== 5) {
      this.setState({
        ...this.state,
        valiErrorMessage: this.props.t("send-code-validation")

      })
    }
    else {
      this.props.submitted(this.state.verificationCode);
    }
  };
  render() {
    return (
      <div className={cx(classes.Register)}>
        <div className="">

          <div className={cx("d-flex justify-content-between",classes.VerifyHeader)}>
            <div className={cx(classes.Back, "d-flex justify-content-center pt-1")}>
              <Link
                to={this.props.backLink}>
                <p>{ this.props.dir === 'ar'
                  ?<FontAwesomeIcon className="pt-1"
                    icon={faChevronRight}
                  />:
                  <FontAwesomeIcon className="pt-1"
                    icon={faChevronLeft}
                  />}
                  <span className="ml-2">{this.props.t('back')}</span>
                </p>
              </Link>
            </div>
            <img src={smartPhoneImg} />
          </div>
        </div>
        <div className="">
          <h3 className="mb-3">{this.props.t("confirm-code")}</h3>
          <p className="mb-3"> {this.props.t("enter-confirm-code")}</p>
          {
            this.props.callReqError ? <p className={cx(classes.errorMessage ,"")}>{this.props.t("otp-error")}</p> :null
          }
          <form
            onSubmit={(event) => {
              this.handelForm(event);
            }}
          >
            <div
              className="d-flex flex-row  justify-content-around"
              dir="ltr"
            >
              <OtpInput
                className={otpClasses.OTP}
                value={this.state.verificationCode}
                onChange={this.handelInputChange}
                numInputs={5}
                containerStyle={{
                  marginBottom:20
                }}
                focusStyle={OtpInputFocusStyle}
                inputStyle={OtpInputStyle}
                separator={<span>&nbsp;&nbsp;&nbsp;</span>}
                isInputNum={true}
                shouldAutoFocus={true}
              />
            </div>
            {

            }{this.state.valiErrorMessage ?
              <p className={cx(classes.errorMessage)}>{this.state.valiErrorMessage}</p> : null
            }
            <AuthButton text={this.props.t("verify")} classCustomName={classes.VerifyButton}/>
          </form>
          <Link onClick={()=>{this.props.sendAgain()}} className={cx("d-flex justify-content-center ", classes.LinkSpace)}>
            {this.props.t("send-code-again")}
          </Link>
        </div>
      </div>
    );
  }
}
const Extended = withTranslation()(VerificationForm);
Extended.static = VerificationForm.static;
export default Extended;
