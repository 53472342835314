import React from 'react'
import * as classes from './HealthyInfo.module.scss'
import cx from 'classnames'
import { format } from 'date-fns'
import weightImg from '../../../../../assets/img/account/weight-scale.png';
import heightImg from '../../../../../assets/img/account/height (1).png';
import editImg from '../../../../../assets/img/account/edit.png'
import { useTranslation } from "react-i18next";
import BloodType from '../../../shared/BloodType/BloodType';
export default function HealthyInfo(props) {
    const { t, i18n } = useTranslation();
    return (
        <div className={cx(classes.HealthyInfo, "row")}>
            <div className={cx("col-6 col-md-3", classes.Heads)}>
                <h3 className="mb-4">{t("birthday")}</h3>
                <h3 className="mb-4">{t("gender")}</h3>
                <h3 className="mb-4">{t("smoker")}</h3>
                <h3>{t("alcoholic")}</h3>
            </div>
            <div className={cx("col-6 col-md-3", classes.Info)}>
                <p className="mb-4">{format(new Date(props.birthdate),'yyyy/MM/dd')}</p>
                <p className="mb-4">{props.gender?t("signup-gender-male"):t("signup-gender-female")}</p>
                <p className="mb-4">{props.smoker?t("yes"):t("no")}</p>
                <p>{props.alcoholic ? t("yes"):t("no")}</p>
            </div>
            <div className={cx("col-12 col-md-5 offset-md-1 d-flex order-first order-md-last",classes.NumericInfo)}>
                <div className={cx("d-flex justify-content-between",classes.Images)} >
                    <div className="text-center">
                        <img src={weightImg} className={classes.img} />
                        <p className={props.weight ? classes.Data : classes.NoData}>
                            {props.weight ? props.weight : t("unknown")}
                        </p>
                    </div>
                    <div className="text-center">
                        <img src={heightImg} className={classes.img} />
                        <p className={props.height ? classes.Data : classes.NoData}>
                            {props.height ? props.height : t("unknown")}
                        </p>
                    </div>
                    <div className="text-center">
                        <div className={classes.bloodImg}>
                            <BloodType bloodType={props.bloodId} />
                        </div>
                        <p className={props.blood ? classes.Data : classes.NoData} dir={props.blood ? 'ltr':null} style={{fontFamily:'Euclid'}}>
                            {props.blood ? props.blood : t("unknown")}
                        </p>
                    </div>
                </div>
                <div>
                    <img src={editImg} className="mt-2" style={{cursor:'pointer'}} onClick={props.clicked}/>
                </div>

            </div>
        </div>
    )
}
