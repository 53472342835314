const ar = {
  "loginTitle" : 'تسجيل الدخول',
  "loginParagraph" : 'ادخل بيانات حسابك',
  "phoneNumber" : 'رقم المحمول',
  "password" : 'الرقم السري',
  "loginRegister" : 'إنشاء حساب جديد',
  "forgetPassword":"نسيت كلمة السر؟",
  "no-phone-error-message":"برجاء إدخال رقم المحمول",
  "phone-not-valid-error-message":"برجاء إدخال رقم الهاتف 11 رقم",
  "no-password-error-message":"برجاء إدخال كلمة المرور",
  "password-contains-error-message":"يجب أن تحتوي كلمة المرور على أحرف وأرقام",
  "email-not-valid-error-message":"البريد الإلكتروني غير صالح",
  "create-new-account":"إنشاء حساب جديد",
  "create-new-account-para" : "ادخل بياناتك لإنشاء حساب",
  "signup-birthdate":"تاريخ الميلاد (اختياري)",
  "have-account":"لديك حساب؟",
  "signup-phone":"رقم المحمول",
  "signup-email":"البريد الإلكتروني (اختياري)",
  "signup-name":"الإسم الثنائي",
  "signup-password":"كلمة المرور",
  "signup-gender" : "النوع (اختياري)",
  "signup-gender-male" : "ذكر",
  "signup-gender-female" : "أنثى",

  "signup-birthdate":"تاريخ الميلاد (اختياري)",
  "signup-password-example":"يجب أن تحتوي كلمة المرور على 8 أحرف على الأقل ويوجد بها رقم واحد على الأقل , وكمثال توضيحي لذلك password1",
  "no-name-error-message":"برجاء إدخال الاسم",
  "name-not-valid-message":"يجب أن يحتوي الاسم على حروف فقط",
  "have-account":"لديك حساب؟",
  "back":"الرجوع",
  "confirm-code":"رمز التحقق",
  "enter-confirm-code":"ادخل رمز التحقق (OTP) المستلم من خلال البريد الإلكتروني",
  "verify" : "تحقق",
  "send-code-again":"إِرسال الرمز مرة أخرى",
  "send-code-validation" : "ادخل رقم التحقق صحيح",
  "otp-success":"تم تفعيل الحساب بنجاح",
  "otp-success-para" :"ابدأ البحث عن خدمات",
  "forget-password":"نسيت كلمة السر ؟",
  "forget-password-paragraph" : "يرجي تأكيد رقم الهاتف وسنرسل لك كود لإِعادة تعيين كلمة السر",
  "send-code" : "أرسل الكود",
  "reset-password" : "تعيين كلمة السر من جديد",
  "new-password" : "كلمة السر الجديدة",
  "new-password-confirm" : "تأكيد كلمة السر الجديدة",
  "change-password" : "تغيير كلمة السر",
  "password-no-match-error-message":"كلمة المرور وتأكيد كلمة المرور غير متطابقتان",
  "password-changed-successfully":"تم تغيير كلمة السر بنجاح",
  "my-account" : "حسابي",
  "my-healthy" : "صحتي",
  "notifications" : " الإشعارات",
  "settings" : "الإعدادات",
  "sign-out" : "تسجيل الخروج",
  "sign-in" : "تسجيل الدخول",
  "services" : "خدماتنا",
  "services-providers" : "مقدمي الخدمات",
  "call-request" : "تواصل مع خدمة العملاء",
  "download-app" : "حمل التطبيق الآن",
  "quick-links" : "روابط سريعة",
  "join-us" : "انضم إلينا",
  "medical-articles" : "مقالات طبية",
  "contact-us" : " تواصل معنا ",
  "quick-info" : "معلومات الأتصال",
  "subscribe" : "اشترك ف النشرة الاخبارية",
  "email" : "البريد الاليكتروني",
  "subscribe-button" : "تسجيل",
  "address" : "4 ج  شارع أحمد کامل ، متفرع من اللاسلکي ، المعادى الجدیدة ، .القاهرة ، مصر",
  "terms" : "الشروط والأحكام",

  "call-order-first-section-paragraph" : " ابدأ بوصف شعورك الآن لحجز خدمتك",
  "free-service" : "خدمة مجانية",
  "order-call" : "تواصل مع خدمة العملاء",
  "customer-service" : "خدمة العملاء",
  "order-call-para" : "يمكنك أيضا اختيار احد الاعراض التي تشعر بها لحجز خدمتك فوراً وسنقوم بالاتصال بك في الحال",
  "other-symptoms" : "هل لديك أعراض أخرى؟",
  "describe-symptoms" : "أوصف الأعراض",
  "increased-perspiration":"زيادة تعرق",
  "preasure" : "ارتفاع/انخفاض الضغط",
  "cough" : "سعال",
  "dizziness" : "دوخة",
  "scaly-skin" : "بشرة متقشرة",
  "breathing-difficulties" : "صعوبة في التنفس",
  "call-request-contact-us" : "تواصل مع أحد الأطباء المسجلين بهوسبيتاليا",
  "confirm-call-request" : "تأكيد طلب الأتصال",
  "enter-name-phone" : "ادخل اسمك ورقم تليفونك",
  "accept-conditions" : "أوافق على الشروط والأحكام",
  "request-confirmed":"تم تأكيد طلبك",
  "request-confirmed-para" : "سنقوم بالاتصال بك في أقرب وقت ممكن",
  "request-confirmed-second-para" : "يمكنك متابعة التنبيهات و حالة طلباتك من خلال صفحة الإشعارات",
  "ok":"حسنا",
  "otp-error" : "رقم التحقق غير صحيح",


  "home-hospital" : "المستشفي في بيتك",
  "home-hospitalia" : "خدمات طبية في المنزل في القاهرة و الأسكندرية",
  "home-doctor":"دكتور منزلي",
  "home-nursing":"تمريض منزلي",
  "physical-therapy":"علاج طبيعي",
  "rays":"أشغة",
  "tests" : "تحاليل",
  "book-now" : "أحجز الان",
  "call-req-land" : "يمكنك أيضا اختيار احد الاعراض التي تشعر بها لحجز خدمتك فوراً وسنقوم بالاتصال بك في الحال",
  "hospitalia-services":"خدمات هوسبيتاليا",

  "available-specialities":"التخصصات الطبية المتاحة للزيارات المنزلية",
  "available-nursing" : "تخصصات التمريض المتاحة للزيارات المنزلية",
  "available-pys" : "التخصصات المتاحة في العلاج الطبيعي",
  "hospitalia-registered-doctors":"الأطباء المسجلين بهوسبيتاليا",
  "choose-service-house":"ابدأ باختيار الخدمة التي تريدها في منزلك",
  "doctor-nurses-registerd" : "الأطباء والممرضين المسجلين بهوسبيتاليا",
  "labs-registerd" : " المعامل و مراكز الاشعة المعتمدة بهوسبيتاليا",
  "available-tests":"مجموعة واسعة من الاختبارات المعملية",
  "available-xrays": "خدمات الأشعة المنزلية المتاحة",
  "home-medicine" : "المزيد عن الطب المنزلي",
  "describe-feeling" : "ابدأ بوصف ما هو شعورك",
  "describe-status" : "أوصف الحالة المرضية",
  "upload-tests" : "يرجي إِرفاق أي صور/تقارير طبية, أشعة او تحاليل",
  "enter-address" :"يرجي إِدخال العنوان بالتفصيل",
  "select-location" : "حدد الموقع",
  "service-fee" : "تكلفة الخدمة تبدأ من",
  "collect-fee" : "نقوم بتحصيلها بعد تلقي الخدمة",
  "address-detailed": "ادخل العنوان بالتفصيل",
  "pick-date-time" : "أختر التاريخ والتوقيت",
  "search-box-placeholder":"ادخل العنوان",
  "please-sign-in": "يرجي تسجيل الدخول",
  "please-sign-in-para" : "للتمكن من حجز هذه الخدمة",
  "booking-success" : "تأكيد الحجز",
  "booking-button" : "تأكيد الحجز",
  "am" : "صباحاً",
  "pm" :"مساءً",

  "yes": "نعم",
  "no" : "لا",
  "smoker" : "هل أنت مدخن؟",
  "gender" : "النوع",
  "birthday":"تاريخ الميلاد",
  "alcoholic" : "هل تتناول المشروبات الكحولية؟",
  "unknown" : "غير معروف",
  "medical-file" : "ملفك الطبي في هوسبيتاليا",
  "medical-history":"إضافة تاريخ مرضي",
  "describe-medical-history":"يرجي وصف تاريخك المرضي:",
  "write-current-medicines":"يرجي كتابة الأدوية الحالية:",
  "write-family-deseases":"يرجي كتابة الامراض العائلية:",
  "current-medications" : "إضافة أَدوية حالية",
  "family-diseases" : "إضافة أمراض عائلية",
  "account-full-name" : "الاسم الكامل :",
  "account-phone" : "رقم المحمول :",
  "account-email" : "البريد الإلكتروني :",
  "add-address" : "إضافة عنوان المنزل",
  "enter-address": "يرجي إدخال العنوان بالتفصيل",
  "enter-address-place" : "ادخل العنوان بالتفصيل",
  "save" : "حفظ",
  "notifications":"الإشعارات",
  "request-status-message":"متابعة مواعيدك, التنبيهات و حالة طلباتك",
  "order-status" : "حالة الطلب",
  "number":"رقم",
  "service-evaluation":"تقييم الخدمة",
  "service-evaluation-para":"مدي رضاك عن الخدمة",
  "comment" : "كتابة تعليق",
  "rate-hosbitalia-modal":"أوصف رأيك او أي تعليقات عن الخدمة",
  "finish":"الانتهاء",
  "rate-tip":"اضغط هنا لتستطيع تقييم الخدمة",
  "detailed-address":"العنوان بالتفصيل:",
  "map-location" : "الموقع",
  "edit-profile" : "تعديل بيانات الحساب",
  "no-notification" : "لا يوجد اشعارات جديدة",
  "choose-blood-type":"أختر فصيلة الدم",
  "weight":"الوزن",
  "height" : "الطول",
  "kg" : "كج",
  "cm" : "سم",
  "enter-weight" : "أدخل الوزن",
  "enter-height" : "أدخل الطول",
  "file-error": "يجب ان تكون صيغة الملف .jpg او .png او .pdf والا يتعدي حجم الملف 1.5 ميجا بايت ",
  "describe-medical-history":"يرجي وصف تاريخك المرضي:",
  "attach-reports":"يرجي ارفاق أي صور/تقارير طبية, اشعة او تحاليل",
  "join-us-services" : "ابدأ باختيار الخدمة التي تريد الانضمام بها",
  "join-us-fullname" : "الاسم الكامل",
  "join-us-email" : "البريد الإلكتروني",
  "join-us-region" : "أختر المنطقة",
  "join-us-major" : "أختر التخصص",
  "join-us-experience" : "الخبرات",
  "join-us-tell-experience" : "أخبرنا عن خبراتك المهنية",
  "join-us-info" : "ادخل البيانات التالية",
  "cv" : "السيرة الذاتية",
  "join-us-upload-cv":"حمل صورة من السيرة الذاتية",
  "join-us-practice" : "كارنيه مزاولة المهنة",
  "join-us-upload-practice":"حمل صورة من كارنيه مزاولة المهنة",
  "join-us-id" : "صورة من الرقم القومى",
  "join-us-upload-id" : "حمل صورة من الرقم القومى",
  "join-us-button" : "تأكيد طلب الانضمام",
  "join-us-header" : "لتقديم أعلى مستوى للرعاية الصحية المنزلية المتكاملة",
  "join-us-city1" : "القاهرة",
  "join-us-city2" : "الأسكندرية",
  "join-us-xray" : "مركز أشعة",
  "join-us-tests": "معامل تحاليل",
  "join-us-phys" : "علاج طبيعى",
  "join-us-nur" : "تمريض",
  "join-us-docs" : "أطباء",
  "no-region-error-message":"برجاء تحديد المنطقة",
  "no-major-error-message":"برجاء تحديد التخصص",
  "no-experience-error-message":"برجاء إدخال نبذة عن خبرتك",
  "no-cv-error-message" : "برجاء تحميل السيرة الذاتية",
  "no-practice-card-error-message" : "برجاء تحميل كارنيه مزاولة المهنة",
  "no-id-error-message" : "برجاء تحميل صورة من الرقم القومي",
  "cancel":"إلغاء",
  "date": "التاريخ",
  "medical-record": "السجل المرضي",
  "medical-description-error" : "برجاء ادخال التاريخ المرضي",
  "page-title":"كشف منزلي - علاج طبيعي - تمريض - أشعة | خدمات طبية منزلية | هوسبيتاليا",
  "page-description":"خدمات طبية منزلية متكاملة : دكتور كشف منزلي في جميع التخصصات أخصائي علاج طبيعي بالمنزل ، تمريض منزلي أشعة بالمنزل في القاهرة و الاسكندرية",
  "payment-method":"طريقة الدفع",
  "add-promocode":"ادخل كوبون خصم",
  "promocode-pop-title":"كوبون الخصم",
  "agree":"موافق",
  "promocode-input-placeholder":"إدخل كود الكوبون",
  "promo-success-message":"تم تطبيق كوبون الخصم",
  "promocode-applied":"تمت إضافة كوبون",
  "service-cost":"تكلفة الخدمة",
  "egyptian-pound":"جم",
  "choose-appropriate-time-online":"اختر الوقت المناسب للمكالمة",
  "not-available-message":"غير متاح الآن : مواعيد العمل من ١٠ صباحاً إلى ١١ مساءاً"
};
  
export default ar;
  