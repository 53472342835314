import React, { Component } from "react";
import { Link } from "react-router-dom";
import * as classes from "../Register.module.scss";
import cx from "classnames";
import { faChevronRight,faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import smartPhoneImg from "../../../../../assets/img/auth/047-smartphone-3.png";
import AuthButton from "../../../../shared/Button/Button";
import VerficationInput from "../../../shared/VerificationInput/VerficationInput";
import { withTranslation } from 'react-i18next';
const intialState = {
  verInputs: {
    one: "",
    two: "",
    three: "",
    four: "",
    five: "",
  },
  valiErrorMessage: ''
};
class RegisterVerificationForm extends Component {

  state = intialState;
  handelInputChange = (event, key) => {
    event.preventDefault();
    const updatedState = { ...this.state.verInputs };
    updatedState[key] = event.target.value;
    this.setState({
      ...this.state,
      verInputs: updatedState,
    });
  };
  handelForm = (event) => {
    event.preventDefault();
    this.setState({
      ...this.state,
      valiErrorMessage: ''
    })
    const verfiCode = { ...this.state.verInputs }
    let code = "";
    for (let element of Object.keys(verfiCode)) {
      code += this.state.verInputs[element]
    }
    if (code.length !== 5) {
      this.setState({
        ...this.state,
        valiErrorMessage: this.props.t("send-code-validation")

      })
    }
    else {
      this.props.submitted(code)
    }
  };
  render() {
    const verInputsView = { ...this.state.verInputs };
    const verInputsViewKeys = Object.keys(verInputsView).map((element) => {
      return (
        <VerficationInput
          changed={(event) => {
            this.handelInputChange(event, element);
          }}
          value={this.state.verInputs[element]}
          key={element}
        />
      );
    });
    return (
      <div className={cx(classes.Register)}>
        <div className="">

          <div className={cx("d-flex justify-content-between",classes.VerifyHeader)}>
            <div className={cx(classes.Back, "d-flex justify-content-center pt-1")}>
              <Link
                to={this.props.backLink}>
                <p>{ this.props.dir === 'ar'
                  ?<FontAwesomeIcon className="pt-1"
                    icon={faChevronRight}
                  />:
                  <FontAwesomeIcon className="pt-1"
                    icon={faChevronLeft}
                  />}
                  <span className="ml-2">{this.props.t('back')}</span>
                </p>
              </Link>
            </div>
            <img src={smartPhoneImg} />
          </div>
        </div>
        <div className="">
          <h3 className="mb-3">{this.props.t("confirm-code")}</h3>
          <p className="mb-3"> {this.props.t("enter-confirm-code")}</p>
          {
            this.props.callReqError ? <p className={cx(classes.errorMessage ,"")}>{this.props.t("otp-error")}</p> :null
          }
          <form
            onSubmit={(event) => {
              this.handelForm(event);
            }}
          >
            <div
              className="d-flex flex-row justify-content-around"
              dir="ltr"
            >
              {verInputsViewKeys}
            </div>
            {

            }{this.state.valiErrorMessage ?
              <p className={cx(classes.errorMessage)}>{this.state.valiErrorMessage}</p> : null
            }
            <AuthButton text={this.props.t("verify")} classCustomName={classes.VerifyButton} loading={this.props.loading}/>
          </form>
          <Link className={cx("d-flex justify-content-center ", classes.LinkSpace)}>
            {this.props.t("send-code-again")}
          </Link>
        </div>
      </div>
    );
  }
}
const Extended = withTranslation()(RegisterVerificationForm);
Extended.static = RegisterVerificationForm.static;
export default Extended;
