import React , {useState} from 'react'
import * as classes from './PromoCodeModal.module.scss'
import cx from 'classnames';
import { useTranslation } from "react-i18next";
import Button from '../../../shared/Button/Button'
import Input from '../../../shared/Input/Input'
import { Link } from "react-router-dom";
import Modal from 'react-modal'
import {
    faTimes
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function CustomModal(props) {
    const { t, i18n } = useTranslation();
    const [promoCode,setPromoCode] = useState('');
    return (
        <div>
            <Modal ariaHideApp={false} className={cx(classes.Modal)} overlayClassName={classes.Overlay} isOpen={props.isOpen} >
                <div onClick={props.clicked} className={cx(classes.FaCustom,"d-flex justify-content-center align-items-center")}>
                    <FontAwesomeIcon icon={faTimes} color="#707070" />
                </div>
                <div className="text-center">
                    <h3>{t("promocode-pop-title")}</h3>
                    <Input classCustomName="mt-2 mb-4"
                        type="text"
                        name="promoCode"
                        placeholder={t('promocode-input-placeholder')}
                        changed={(event) => {
                            setPromoCode(event.target.value)
                        }}
                        value={promoCode}
                    />
                </div>
                <Button text={t("agree")} loading={props.loading} clicked={()=>{props.submit(promoCode);}} />
            </Modal>
        </div>
    )
}
