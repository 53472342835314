
import React from 'react'
import * as classes from './NotificationsHeader.module.scss'
import cx from 'classnames'
import bellImg from '../../../../../assets/img/Notifications/bell.png'
import { useTranslation } from "react-i18next";
export default function NotificationsHeader(props) {
    const { t, i18n } = useTranslation();
    return (
        <div className={cx(classes.NotificationsHeader,"row")}>
            <div className="col-12 col-md-3 col-lg-2 d-flex justify-content-center justify-content-md-end">
                <figure>
                    <img src={bellImg} />
                    <div className={cx(classes.NotifNum,"text-center")}>
                        <span>{props.count}</span>
                    </div>
                </figure>
            </div>
            <div className="col-12 col-md-9 col-lg-10 text-center text-md-left">
                <h3>{t("notifications")}</h3>
                <p>{t("request-status-message")}</p>
            </div>
        </div>
    )
}
