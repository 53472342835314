import React from 'react'
import aPlus from '../../../../assets/img/bloodTypes/A+.png'
import aMinus from '../../../../assets/img/bloodTypes/A-.png'
import bPlus from '../../../../assets/img/bloodTypes/B+.png'
import bMinus from '../../../../assets/img/bloodTypes/B-.png'
import oPlus from '../../../../assets/img/bloodTypes/O+.png'
import oMinus from '../../../../assets/img/bloodTypes/O-.png'
import abPlus from '../../../../assets/img/bloodTypes/AB+.png'
import abMinus from '../../../../assets/img/bloodTypes/AB-.png'
import transparent from '../../../../assets/img/bloodTypes/transparent.png'
import * as classes from './BloodType.module.scss'

export default function BloodType(props) {
    let bloodType = ''
    if(props.bloodType == 1){
        bloodType = aPlus
    }
    else if(props.bloodType == 2){
        bloodType = aMinus; 
    }
    else if(props.bloodType == 3){
        bloodType = bPlus; 
    }
    else if(props.bloodType == 4){
        bloodType = bMinus; 
    }
    else if(props.bloodType == 5){
        bloodType = oPlus; 
    }
    else if(props.bloodType == 6){
        bloodType = oMinus; 
    }
    else if(props.bloodType == 7){
        bloodType = abPlus; 
    }
    else if(props.bloodType == 8){
        bloodType = abMinus;
    }
    else{
        bloodType=transparent
    }
    return (
        <img src={bloodType} className={classes.BloodType} />
    )
}
