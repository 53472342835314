import React, { Component} from "react";
import * as classes from "./TermsPage.module.scss";
import cx from "classnames";
import { withTranslation } from "react-i18next";
import axios from './../../../axios';
import parse from 'html-react-parser';
import LoadingPage from "../../shared/LoadingPage/LoadingPage";
import { connect } from "react-redux";

const mapStateToProps = (state) => {
    return {
      lang: state.common.currentLanguage,
    };
  };
export class TermsPage extends Component {
  state = {
      termsData:'',
      isLoading: false
  }
  constructor() {
    super();
  }
  componentDidMount() {
    this.getTerms();
  }
  getTerms(){
    this.setState({
        ...this.state,
        isLoading:true
    });
    axios.get('/mng/gettermsandconditions').then((res)=>{
        this.setState({
            ...this.state,
            termsData:res.data.responseobject,
            isLoading:false
        });
    },(error)=>{
        this.setState({
            ...this.state,
            isLoading:false
        });
        console.log(error);
    });
  }
  componentWillUnmount() {

  }
  componentDidUpdate(prevProps) {

  }


  render() {
      if(this.state.isLoading){
          return <LoadingPage />
      }else{
        return <div className={cx(classes.TermWrapper)}>{parse(this.state.termsData)}</div>;
      }
  }
}
const Extended = withTranslation()(TermsPage);
Extended.static = TermsPage.static;
export default connect(mapStateToProps)(Extended);