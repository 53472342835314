import moment from 'moment';
import Cookies from "js-cookie";

moment.locale(Cookies.get('language') || document.documentElement.lang);
export const timeSlots = (date) => {
    let start;
    if(!date) return [];
    if(moment().isBefore(date,'day')){
        start = moment(new Date()).set({hour:10,minute:0});
    } else {
        if(moment().isAfter(moment(new Date()).set({hour:19,minute:45}))){
            return [];
        }
        if(moment().add(2,'hours').isBefore(moment(new Date()).set({hour:10,minute:0}),'hour')){
            start = moment(new Date()).set({hour:10,minute:0});
        }else {
            start = moment().add(120,'minutes');
            const minutes = start.minutes();
            if(minutes > 0 && minutes < 15){
                start.add(15-minutes,'minutes');
            }else if(minutes > 15 && minutes < 30){
                start.add(30-minutes,'minutes');
            }else if(minutes > 30 && minutes < 45){
                start.add(45-minutes,'minutes');
            }else if(minutes > 45 && minutes <= 59){
                start.add(60-minutes,'minutes');
            }
        }
    }
    
    const end = moment(new Date()).set({hour:22,minute:0});
    const slots = [];
    const timeSlots = [];
    while (start <= end ){
        let entry = {
            label:new moment(start).format('hh:mm a'),
            value: new moment(start).locale('en').format('HH:mm')
        };
        slots.push(entry);
        start.add(15,'minutes');
    }
    for(let i =0; i<slots.length-1;i++){
        timeSlots.push({label:`${slots[i].label} - ${slots[i+1].label}`,value:i,value:slots[i].value});
    }
    return timeSlots;
}

export const checkServiceAvailability = () => {
    const isAfter10 =  moment().isSameOrAfter(moment(new Date()).set({hour:10,minute:0}));
    const isBefore11 = moment().isSameOrBefore(moment(new Date()).set({hour:23,minute:0}));
    return isAfter10 && isBefore11;
}
