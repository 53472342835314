import React, { useState } from "react";
import AuthButton from "../../../../shared/Button/Button";
import AuthInput from "../../../../shared/Input/Input";
import * as classes from "./PhoneForm.module.scss";
import { useTranslation } from "react-i18next";
import cx from "classnames";
export default function PhoneForm(props) {
  const { t, i18n } = useTranslation();
  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const validate = () => {
    let phoneErrorVal = "";
    const num = /^[0-9\b]+$/;
    if (phone === "" || !phone.match(num) || phone.length !== 11) {
      phoneErrorVal = t("phone-not-valid-error-message")
    }
    if (phoneErrorVal) {
      setPhoneError(phoneErrorVal);
      return false;
    }
    return true;
  };
  const submitPhone = (event) => {
    event.preventDefault();
    const isValid = validate();
    if (isValid) {
      setPhoneError("");
      props.submitted(phone);
    }
  };

  return (
    <div className={classes.PhoneForm}>
      <h3 className="text-md-left text-center ">{t("forget-password")}</h3>
      <p className=" mt-2 mt-md-3 mb-3 text-md-left text-center ">
        {t("forget-password-paragraph")}
      </p>
      <form onSubmit={(event) => submitPhone(event)}>
        <AuthInput
          type="phone"
          name="phone"
          placeholder={t("phoneNumber")}
          changed={(event) => setPhone(event.target.value)}
          value={phone}
        />
        {phoneError ? (
          <p className={classes.ErrorMessage}>{phoneError}</p>
        ) : null}
        {props.error ? (
          <p className={cx(classes.ServerMessage)}>
            {props.error}
          </p>
        ) : null}
        <div className={cx(classes.AuthButton)}>
          <AuthButton text={t("send-code")} type="submit"></AuthButton>
        </div>
      </form>
    </div>
  );
}
