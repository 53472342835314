import React from "react";
import * as classes from "./ProfileInput.module.scss";
import cx from 'classnames'
export default function ProfileInput(props) {
  return (
    <div className={classes.ProfileInput}>
      <input
      className={"form-control ".concat(props.classCustomName)}
        type={props.type}
        name={props.name}
        placeholder={props.placeholder}
        onChange={props.changed}
        value={props.value}
      />
    </div>
  );
}
