import React, { Component } from "react";
import * as classes from "./BookingServiceSuccess.module.scss";
import { withTranslation } from "react-i18next";
import cx from "classnames";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import BookingServiceHeader from "../../shared/BookingServiceHeader/BookingServiceHeader";
import * as actions from '../../../../store/actions/index'
import CallRequestSuccess from "../../../shared/RequestSuccess/RequestSuccess";
import LoadingPage from "../../../shared/LoadingPage/LoadingPage";
import {checkServiceAvailability} from './../../../../utils/utils';
import WorkingHoursWidget from "../../shared/WorkingHoursWidget/WorkingHoursWidget";
const mapStateToProps = (state) => {
    return {
        spec: state.bookingService.targetSpecsItem,
        lang: state.common.currentLanguage,
        targetService: state.bookingService.targetService,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getServiceSpecializationsItem: (serviceID, specID, lang) => {
            dispatch(actions.getServiceSpecsItem(serviceID, specID, lang));
        },
        getServices: (id) => {
            dispatch(actions.getService(id));
        },
        resetRequestCycle : () => {dispatch(actions.resetRequestCycle())}
    };
};
export class BookingServiceSuccess extends Component {
    componentDidMount() {
        const serviceID = this.props.match.params.id;
        const sepcId = this.props.match.params.specID;
        console.log(serviceID)
        this.props.getServices(serviceID);
        this.props.getServiceSpecializationsItem(
            serviceID,
            sepcId,
            this.props.lang
        );
        this.props.resetRequestCycle();
    }
    render() {
        if (this.props.spec && this.props.targetService) {
            return (

                <div className={classes.BookingServicesSuccess}>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-7">
                                <nav aria-label="breadcrumb">
                                    <ol className={cx("breadcrumb", classes.BreadCrumbCustom)}>
                                        <li className="breadcrumb-item">
                                            <Link to="/">{this.props.t("hospitalia-services")}</Link>
                                        </li>
                                        <li className="breadcrumb-item" aria-current="page">
                                            <Link
                                                to={
                                                    "/services/" +
                                                    this.props.match.params.id +
                                                    "/" +
                                                    this.props.match.params.serviceName
                                                }
                                            >
                                                {this.props.match.params.serviceName.split("-").join(" ")}
                                            </Link>
                                        </li>
                                        <li className="breadcrumb-item" aria-current="page">
                                            <Link
                                                to={
                                                    "/services/" +
                                                    this.props.match.params.id +
                                                    "/" +
                                                    this.props.match.params.serviceName +
                                                    "/" +
                                                    this.props.match.params.specID +
                                                    "/" +
                                                    this.props.match.params.specName
                                                }
                                            >
                                                {this.props.match.params.specName.split("-").join(" ")}
                                            </Link>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page">
                                            <span>{this.props.t("booking-success")}</span>
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                            <div className={cx("col-md-5")}>
                                {!checkServiceAvailability() && <WorkingHoursWidget />}
                            </div>
                            <div className="col-md-12 mb-3 px-0">
                                <BookingServiceHeader img={this.props.spec.img}
                                    fee={this.props.spec.Fee}
                                    currency={this.props.spec.Currency}
                                    speciality={this.props.spec.Speciality}
                                    description={this.props.targetService.Description} />
                            </div>
                            <div className="col-md-12">
                                <CallRequestSuccess superClicked={() => { this.props.history.push('/notifications') }} />
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        else {
            return <LoadingPage />;
          }

    }
}

const Extended = withTranslation()(BookingServiceSuccess);
Extended.static = BookingServiceSuccess.static;
export default connect(mapStateToProps, mapDispatchToProps)(Extended);