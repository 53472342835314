import React, { Component } from "react";
import { connect } from "react-redux";
import * as classes from "./Healthy.module.scss";
import HealthyHeader from "./HealthyHeader/HealthyHeader";
import HealthyInfo from "./HealthyInfo/HealthyInfo";
import * as actions from "../../../../store/actions/index";
import HealthyAttachment from "./HealthyAttachment/HealthyAttachment";
import medicalHistoryImg from "../../../../assets/img/healthy/018-health report1.png";
import currentMedicinesImg from "../../../../assets/img/healthy/018-health report.png";
import familyDiseasesImg from "../../../../assets/img/healthy/018-health-medicine.png";
import blueCircleImg from "../../../../assets/img/healthy/plus-blue.png";
import yellowCircleImg from "../../../../assets/img/healthy/plus-yellow.png";
import greenCircleImg from "../../../../assets/img/healthy/plus-green.png";
import { withTranslation } from "react-i18next";
import BloodType from "../../shared/BloodType/BloodType";
import HealthyForm from "./HealthyForm/HealthyForm";
import HealthyAttachmentForm from "./HealthyAttachmentForm/HealthyAttachmentForm";
import { Route, Switch } from "react-router-dom";
import HealthyHome from "./HealthyHome/HealthyHome";
import LoadingPage from '../../../shared/LoadingPage/LoadingPage'
const mapStateToProps = (state) => {
  return {
    clientID: state.common.clientID,
    lang: state.common.currentLanguage,
    userProfileData: state.profile.userProfileData,
    loading: state.profile.loading,
    updateProfileMessage: state.profile.updateProfileMessage
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getProfile: (clientID) => {
      dispatch(actions.getProfile(clientID));
    },
    updateHealthyInfo: (info) => {
      dispatch(actions.updateProfile(info));
    }
  };
};
export class Healthy extends Component {
  state = {
    switchToEditHealthyInfo: false,
  };
  componentDidMount() {
    this.props.getProfile(this.props.clientID);

  }
  editHealthyInfo = (editData) => {
    console.log(editData);
    this.props.updateHealthyInfo(editData);
  };
  render() {
    if (this.props.userProfileData) {
      let content = (
        <HealthyInfo
          gender={this.props.userProfileData.isMale}
          smoker={this.props.userProfileData.isSmoker}
          alcoholic={this.props.userProfileData.isAlcoholic}
          birthdate={this.props.userProfileData.Birthdate}
          weight={this.props.userProfileData.Weight}
          height={this.props.userProfileData.Height}
          blood={this.props.userProfileData.BloodType}
          bloodId={this.props.userProfileData.BloodTypeId}
          clicked={() => {
            this.setState({
              ...this.state,
              switchToEditHealthyInfo: true
            })
          }}
        />
      );
      if (this.state.switchToEditHealthyInfo) {
        content = (
          <HealthyForm
            loading={this.props.loading}
            userProfileData={this.props.userProfileData}
            message={this.props.updateProfileMessage}
            submitted={(editData) => {
              this.editHealthyInfo(editData);
            }}
          />
        );
      }
      let routes = (
        <Switch>
          <Route path={this.props.match.url} exact>
            <HealthyHome />
          </Route>
          <Route path={this.props.match.url + "/medical-history"} >
            <HealthyAttachmentForm medicationHistory img={currentMedicinesImg} head={this.props.t("medical-history")} formLabel={this.props.t("describe-medical-history")} />
          </Route>
          <Route path={this.props.match.url + "/current-medicines"} >
            <HealthyAttachmentForm medications img={medicalHistoryImg} head={this.props.t("current-medications")} formLabel={this.props.t("write-current-medicines")} />
          </Route>
          <Route path={this.props.match.url + "/family-diseases"} >
            <HealthyAttachmentForm familyDisease img={familyDiseasesImg} head={this.props.t("family-diseases")} formLabel={this.props.t("write-family-deseases")} />
          </Route>
        </Switch>
      )
      return (

        <div className={classes.Healthy}>
          <div className="container-fluid">
            <HealthyHeader />
            {content}
            {routes}
          </div>
        </div>
      );
    } else {
      return <LoadingPage/>;
    }
  }
}
const Extended = withTranslation()(Healthy);
Extended.static = Healthy.static;
export default connect(mapStateToProps, mapDispatchToProps)(Extended);
