import React from "react";
import * as classes from "./RequestSuccess.module.scss";
import successImg from "../../../assets/img/order-call/001-phone.png";
import cx from "classnames";
import bellImg from '../../../assets/img/order-call/Group 388.png'
import OrderCallButton from "../../callRequest/shared/OrderCallButton/OrderCallButton";
import { useTranslation } from 'react-i18next';
export default function CallRequestSuccess(props) {
  const { t, i18n } = useTranslation();
  return (
    <div className={cx(classes.CallRequestSuccess, "row")}>
      <div className="text-center col-md-12">
        <img className={classes.successIm} src={successImg} />
        <h3 className="mb-4">{t("request-confirmed")}</h3>
      </div>
      <div className="col-md-12 d-flex justify-content-center">
        <p className={classes.Main}>{t("request-confirmed-para")}</p>
      </div>
      <div className="col-md-12">
        <hr />
      </div>

      <div className={cx(classes.bellIm,"col-md-12 text-center")}>
        <div className= {cx("row justify-content-center",classes.ParaBell)}>
        <p className="order-last order-md-first mt-4">{t("request-confirmed-second-para")}</p>
        <img src={bellImg} className="ml-0 ml-md-4 order-first order-md-last"/>
        </div>
      </div>
      <div className={cx("col-md-12 text-center",classes.Button)}>
        <OrderCallButton text={t("ok")} clicked={props.superClicked}/>
      </div>

    </div>
  );
}
