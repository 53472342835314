import * as actionTypes from "./actions/actionTypes";
import Cookies from "js-cookie";

export const localCookieStorage = (store) => (next) => (action) => {
  if (
    action.type === actionTypes.REGISTER ||
    action.type === actionTypes.LOGIN_SUCCESS
  ) {
    if (action.token) {
      Cookies.set("token", action.token);
      Cookies.set("currentUser", action.currentUser);
      Cookies.set("profileImg", action.profileImg);
      Cookies.set("username", action.username);
      Cookies.set("clientID",action.clientID)
    }
  } else if (action.type === actionTypes.LOGOUT) {
    Cookies.remove("token");
    Cookies.remove("currentUser");
    Cookies.remove("profileImg");
    Cookies.remove("username");
    Cookies.remove("clientID");
  }
  else if(action.type===actionTypes.GET_PROFILE_SUCCESS){
    console.log(action.userProfileData.ProfilePic)
    Cookies.set("profileImg", action.userProfileData.ProfilePic);
  }
  next(action);
};
