import React from 'react'
import HealthyAttachment from '../HealthyAttachment/HealthyAttachment'
import medicalHistoryImg from "../../../../../assets/img/healthy/018-health report1.png";
import currentMedicinesImg from "../../../../../assets/img/healthy/018-health report.png";
import familyDiseasesImg from "../../../../../assets/img/healthy/018-health-medicine.png";
import blueCircleImg from "../../../../../assets/img/healthy/plus-blue.png";
import yellowCircleImg from "../../../../../assets/img/healthy/plus-yellow.png";
import greenCircleImg from "../../../../../assets/img/healthy/plus-green.png";
import * as classes from './HealthyHome.module.scss'
import { useTranslation } from "react-i18next";
export default function HealthyHome() {
    const { t, i18n } = useTranslation();
    return (
        <div className={classes.HealthyHome}>
            <HealthyAttachment
              classCustomName="mb-3"
              mainImg={currentMedicinesImg}
              plusImg={blueCircleImg}
              para={t("medical-history")}
              link="/healthy/medical-history"
            />
            <HealthyAttachment
              classCustomName="mb-3"
              mainImg={medicalHistoryImg}
              plusImg={yellowCircleImg}
              para={t("current-medications")}
              link="/healthy/current-medicines"
            />
            <HealthyAttachment
              mainImg={familyDiseasesImg}
              classCustomName={classes.SubSection}
              plusImg={greenCircleImg}
              para={t("family-diseases")}
              link="/healthy/family-diseases"
            />
        </div>
    )
}
