import * as actionTypes from '../actions/actionTypes'
const defaultState = {
    error: null,
    switchValidation: false,
    switchPassValidation: false,
    activateAccountSuccess: false,
    passwordChanged: false,
    loading: false,
}

export default (state = defaultState, action) => {
    switch (action.type) {
        case actionTypes.LOGIN_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error
            }
        case actionTypes.REGISTER_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error
            }
        case actionTypes.LOGIN_START:
            return {
                ...state,
                loading: true,
                error: null
            }
        case actionTypes.LOGIN_SUCCESS:
            return{
                ...state,
                loading:false
            }
        case actionTypes.ERROR:
            return {
                ...state,
                error: action.error,
                loading:false
            }
        case actionTypes.REGISTER_START:
            return {
                ...state,
                loading: true,
                error: null
            }
        case actionTypes.REGISTER_SUCCESS:
            return {
                ...state,
                message: action.message,
                loading: false,
                switchValidation: true
            }
        case actionTypes.ACTIVATE_ACCOUNT_SUCCESS:
            return {
                ...state,
                activateAccountSuccess: true,
                loading: false
            }
        case actionTypes.ACTIVATE_ACCOUNT_FAIL:
            return {
                ...state,
                loading: false
            };
        case actionTypes.FORGET_PASS_SEND_PHONE_SUCCESS:
            return {
                ...state,
                switchPassValidation: true,
                loading: false
            }
        case actionTypes.CHANGE_PASSWORD_SUCCESS:
            return {
                ...state,
                loading: false,
                passwordChanged: true,
            }
        case actionTypes.CHANGE_PASSWORD_FAIL:
            return {
                ...state,
                loading: false,
                switchPassValidation: false,

            }
        case actionTypes.CHANGE_PASSWORD_SEND_CODE_AGAIN:
            return {
                ...state,
                loading: false,
                switchPassValidation: false,
                
            }
        default:
            return state;
    }
}