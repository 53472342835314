import * as classes from "./App.module.scss";
import "react-toastify/dist/ReactToastify.min.css";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import Header from "./modules/shared/Header/Header";
import Footer from "./modules/shared/Footer/Footer";
import Cookies from "js-cookie";
import * as actions from "./store/actions/index";
import Login from "./modules/auth/pages/Login/Login";
import Register from "./modules/auth/pages/Register/Register";
import AppLayout from "./modules/shared/AppLayout/AppLayout";
import ForgetPassword from "./modules/auth/pages/ForgetPassword/ForgetPassword";
import { languages } from "./constants/index";
import OrderCall from "./modules/callRequest/pages/CallRequest/CallRequest";
import LandingPage from "./modules/landingPage/LandingPage";
import { ToastContainer } from "react-toastify";
import Account from "./modules/profile/pages/Account/Account";
import BookingService from "./modules/bookingService/pages/BookingServices/BookingService";
import BookingServiceForm from "./modules/bookingService/pages/BookingServiceForm/BookingServiceForm";
import BookingServiceSuccess from "./modules/bookingService/pages/BookingServiceSuccess/BookingServiceSuccess";
import MessengerCustomerChat from "react-messenger-customer-chat";
import Healthy from "./modules/profile/pages/Healthy/Healthy";
import Notifications from "./modules/profile/pages/Notifications/Notifications";
import JoinUs from "./modules/joinUs/pages/JoinUs/JoinUs";
import TermsPage from "./modules/landingPage/TermsPage/TermsPage";
import Meta from './Meta';
// Access store required states
const mapStateToProps = (state) => {
  return {
    appName: state.common.appName,
    currentUser: state.common.currentUser,
    redirectTo: state.common.redirectTo,
    otherLanguage: state.common.otherLanguage,
    profileImg: state.common.profileImg,
    lang: state.common.currentLanguage,
    isLandingPage: state.common.isLandingPage,
  };
};
// Access store required actions
const mapDispatchToProps = (dispatch) => {
  return {
    onLoad: (
      token,
      currentUser,
      currentLanguage,
      otherLanguage,
      profileImg,
      username,
      clientID
    ) =>
      dispatch(
        actions.appLoad(
          token,
          currentUser,
          currentLanguage,
          otherLanguage,
          profileImg,
          username,
          clientID
        )
      ),
    logout: () => {
      dispatch(actions.logout());
    },
  };
};
class App extends Component {
  componentWillMount() {
    this.initApp();
  }
  initApp = () => {
     // set token from cookies
     const token = Cookies.get("token");
     const currentUser = Cookies.get("currentUser");
     const profileImg = Cookies.get("profileImg");
     const username = Cookies.get("username");
     const clientID = Cookies.get("clientID");
     // ssetting lang / dir  fallback
     const currentLanguage = Cookies.get("language") || "ar";
     if (!Cookies.get("language")) Cookies.set("language", currentLanguage);
     const otherLanguage = languages.filter(
       (lang) => lang !== currentLanguage
     )[0];
     document.documentElement.lang = currentLanguage;
     document.documentElement.dir = currentLanguage === "ar" ? "rtl" : "ltr";
     this.props.onLoad(
       token,
       currentUser,
       currentLanguage,
       otherLanguage,
       profileImg,
       username,
       clientID
     );
     document.title =
       currentLanguage === "en"
         ? "Hospitalia | Integrated Home Medical Services"
         : "كشف منزلي - علاج طبيعي - تمريض - أشعة | خدمات طبية منزلية | هوسبيتاليا";
  }
  changeLanguage = () => {
    Cookies.set("language", this.props.otherLanguage);
    window.location.reload();
  };
  logout = (event) => {
    event.preventDefault();
    this.props.logout();
  };
  render() {
    const token = Cookies.get("token");
    let routes;
    if (token) {
      // Guarded routes
      routes = (
        <Switch>
          <Route exact path="/" component={LandingPage} />
          <Route
            path="/services/:id/:serviceName"
            exact
            component={BookingService}
          />
          <Route
            path="/services/:id/:serviceName/:specID/:specName"
            exact
            component={BookingServiceForm}
          />
          <Route
            path="/services/:id/:serviceName/:specID/:specName/success"
            component={BookingServiceSuccess}
          />
          <Route path="/account" exact component={Account} />
          <Route path="/notifications" exact component={Notifications} />
          <Route path="/healthy" component={Healthy}  />
          <Route path="/order-call" component={OrderCall} />
          <Route path="/join-us" exact component={JoinUs} />
          <Route path="/terms-conditions" exact component={TermsPage} />
          <Redirect to="/" />
        </Switch>
      );
    } else {
      // Public router
      routes = (
        <Switch>
          <Route path="/register" component={Register} />
          <Route exact path="/" component={LandingPage} />
          <Route path="/login" component={Login} />
          <Route path="/forget-password" component={ForgetPassword} />
          <Route path="/order-call" component={OrderCall} />
          <Route
            path="/services/:id/:serviceName"
            exact
            component={BookingService}
          />
          <Route path="/join-us" exact component={JoinUs} />
          <Route path="/terms-conditions" exact component={TermsPage} />
          <Redirect to="/" />
        </Switch>
      );
    }
    return (
      <div
        className={classes.App}
        onClick={() => {
          this.setState({ ...this.state, clicked: true });
        }}
      >
        <Header
          appName={this.props.appName}
          currentUser={this.props.currentUser}
          lang={this.props.otherLanguage}
          changeLanguage={() => this.changeLanguage()}
          profileImg={this.props.profileImg}
          logout={(event) => {
            this.logout(event);
          }}
        />
        <AppLayout isLandingPage={this.props.isLandingPage}>{routes}</AppLayout>
        <ToastContainer />
        <MessengerCustomerChat
          pageId={process.env.REACT_APP_FACEBOOK_PAGE_ID}
          appId={process.env.REACT_APP_FACEBOOK_APP_ID}
          themeColor="#730198"
        />
        <Footer />
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(App);
