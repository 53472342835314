import commonReducer from "./common";
import authReducer from './auth'
import callRequestReducer from './callRequest'
import bookingServiceReducer from './bookingService'
import profileReducer from './profile'
import { combineReducers } from "redux";

const rootReducer = combineReducers({
    common : commonReducer,
    auth : authReducer,
    callRequest : callRequestReducer,
    bookingService : bookingServiceReducer,
    profile : profileReducer
});

export default rootReducer;