import React, { Component } from "react";
import * as classes from "./BookingServiceForm.module.scss";
import BookingServiceHeader from "../../shared/BookingServiceHeader/BookingServiceHeader";
import cx from "classnames";
import axios from './../../../../axios';
import { withTranslation } from "react-i18next";
import { Link, Redirect } from "react-router-dom";
import imagesPhoto from "../../../../assets/img/bookingService/images.png";
import { faTimesCircle ,faChevronLeft , faChevronRight , faTimes} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as actions from "../../../../store/actions/index";
import { connect } from "react-redux";
import { toast } from 'react-toastify';
import BookingServiceCalender from "../../shared/BookingServiceCalender/BookingServiceCalender";
import BookingServiceMap from "../../../shared/Map/Map";
import TimeCard from "../../shared/TimeCard/TimeCard";
import Button from '../../../shared/Button/Button'
import moonImg from "../../../../assets/img/bookingService/014-cloudy.png";
import sunImg from "../../../../assets/img/bookingService/014-sun.png";
import LoadingPage from "../../../shared/LoadingPage/LoadingPage";
import CashImg from '../../../../assets/img/bookingService/cash.svg';
import MasterCardImg from '../../../../assets/img/bookingService/master-card.svg';
import VisaImg from '../../../../assets/img/bookingService/visa.svg';
import couponImg from '../../../../assets/img/bookingService/coupon.svg';
import AddPromoCodeModal from './../../shared/PromoCodeModal/PromoCodeModal';
import AuthSelect from './../../../auth/shared/AuthSelect/AuthSelect';
import {timeSlots,checkServiceAvailability} from './../../../../utils/utils';
import WorkingHoursWidget from "../../shared/WorkingHoursWidget/WorkingHoursWidget";
const mapStateToProps = (state) => {
  return {
    spec: state.bookingService.targetSpecsItem,
    lang: state.common.currentLanguage,
    targetService: state.bookingService.targetService,
    clientID : state.common.clientID,
    loading : state.bookingService.loading,
    promoLoading: state.bookingService.promoLoading,
    requestSuccess:state.bookingService.requestSuccess
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getServiceSpecializationsItem: (serviceID, specID, lang) => {
      dispatch(actions.getServiceSpecsItem(serviceID, specID, lang));
    },
    getServices: (id) => {
      dispatch(actions.getService(id));
    },
    submitRequest : (reqData) =>{dispatch(actions.submitRequest(reqData))}
  };
};

const poitionOptions = {
  enableHighAccuracy: true,
  timeout: 5000,
  maximumAge: 0,
};
const paymentMethodsIds = {
  cash:1,
  online:2
};
export class BookingServiceForm extends Component {
  state = {
    form: {
      file1: null,
      file2: null,
      userCondition: null,
      userAddress: null,
      reservationDate: new Date(),
      lat: null,
      lng: null,
      reservationTime: null,
    },
    serviceName: null,
    sepcName: null,
    sepcId: null,
    serviceId: null,
    selectedPaymentMethod:null,
    promoModalOpened:false,
    positionLoaded: false,
    promoApplyLoading:false,
    promoCodeData:null,
    feeAfterDiscount:null
  };
  componentDidUpdate(prevProps, prevState){
    if(prevProps.targetService !== this.props.targetService){
      if(this.props.targetService.location_type==1)this.getUserLocation();
      else {
        this.setState({
          ...this.state,
          positionLoaded:false,
          selectedPaymentMethod:'online'
        });
      }
    }
  }
  getUserLocation(){
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(this.getPositionSuccess , this.getPositionErrors, poitionOptions);
    } else {
      this.setState({
        ...this.state,
        positionLoaded:true
      });
    }
  }
  componentDidMount() {
    const serviceName = this.props.match.params.serviceName;
    const serviceID = this.props.match.params.id;
    const specName = this.props.match.params.specName;
    const sepcId = this.props.match.params.specID;
    this.setState({
      ...this.state,
      serviceName: serviceName,
      sepcName: specName,
      sepcId: sepcId,
      serviceId: serviceID,
    });
    this.props.getServices(serviceID);
    this.props.getServiceSpecializationsItem(
      serviceID,
      sepcId,
      this.props.lang
    );
  }

  getPositionSuccess = (pos) => {
    const crd = pos.coords;
    this.setState({
      ...this.state,
      positionLoaded:true,
      form: {
        ...this.state.form,
        lat: crd.latitude,
        lng: crd.longitude,
      },
    });
  }
  
  getPositionErrors = (err) => {
    this.setState({
      ...this.state,
      positionLoaded:true
    });
  }
  handelFileInput = (event, id) => {
    event.preventDefault();
    if (id == 1) {
      this.setState(
        {
          ...this.state,
          form: {
            ...this.state.form,
            file1: URL.createObjectURL(event.target.files[0]),
          },
        },
      );
    } else {
      this.setState({
        ...this.state,
        form: {
          ...this.state.form,
          file2: URL.createObjectURL(event.target.files[0]),
        },
      });
    }
  };
  removeImg = (event, id) => {
    event.preventDefault();
    if (id == 1) {
      this.setState({
        ...this.state,
        form: {
          ...this.state.form,
          file1: null,
        },
      });
    } else {
      this.setState({
        ...this.state,
        form: {
          ...this.state.form,
          file2: null,
        },
      });
    }
  };
  submitForm = (event) => {
    event.preventDefault();
    const requestData = {
      AttachedImage:[],
      ClientID: this.props.clientID,
      ConditionDescription : this.state.form.userCondition,
      Fee : this.props.spec.Fee,
      Loc_Lat : this.state.form.lat?this.state.form.lat: 0,
      Loc_Long : this.state.form.lng?this.state.form.lng:0,
      RequestAddress : this.state.form.userAddress,
      RequestDate : this.state.form.reservationDate,
      RequestTime : this.state.form.reservationTime,
      SpecialityID : this.state.sepcId,
      PayementMethod: paymentMethodsIds[this.state.selectedPaymentMethod]
    }
    if(this.state.promoCodeData){
      requestData['PromoCodeID'] = this.state.promoCodeData.ID;
      requestData['PromoCodeTitle'] = this.state.promoCodeData.Title;
    }
    this.props.submitRequest(requestData);
    
  };
  submitPromoCode(code){
    this.setState({
      ...this.state,
      promoApplyLoading:true
    });
    axios.post(`/promocode/apply?promoCode=${code}`).then(res=>{
      const data = res.data;
      this.setState({
        ...this.state,
        promoApplyLoading:false,
        promoModalOpened:false,
        promoCodeData:data.responseobject
      });
      if(data.statuscode==200){
        toast.success(this.props.t('promo-success-message'),{autoClose:5000});
        let fee;
        if(data.responseobject.Type==1){
          fee = this.props.spec.Fee - data.responseobject.Value;
        }else{
          fee = (((100-data.responseobject.Value)/100)*this.props.spec.Fee);
        }
        this.setState({
          ...this.state,
          feeAfterDiscount:fee
        });
      }     
    },(error)=>{
      toast.error(error.statusmessage,{autoClose:5000});
      this.setState({
        ...this.state,
        promoApplyLoading:false
      });
    })
  }
  render() {
    if (this.props.spec && this.props.targetService) {
      if(this.props.requestSuccess){
        return(
          <Redirect to={this.props.match.url+"/success"}/>
        )
      }
      return (
        <div className={classes.BookingServiceForm}>
          {this.state.promoModalOpened ? (
            <AddPromoCodeModal
              {...this.props}
              clicked={() => {
                this.setState({ ...this.state, promoModalOpened: false });
              }}
              submit={this.submitPromoCode.bind(this)}
              loading={this.state.promoApplyLoading}
              isOpen={this.state.promoModalOpened}
            />
          ) : null}
          <form
            onSubmit={(event) => {
              this.submitForm(event);
            }}
          >
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-7">
                  <nav aria-label="breadcrumb">
                    <ol className={cx("breadcrumb", classes.BreadCrumbCustom)}>
                      <li className="breadcrumb-item">
                        <Link to="/">
                          {this.props.t("hospitalia-services")}
                        </Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        <Link to={"/services/"+this.state.serviceId+"/"+this.state.serviceName}>
                          {this.props.targetService.ServiceType}
                        </Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        <span>{this.props.spec.Speciality}</span>
                      </li>
                    </ol>
                  </nav>
                </div>
                <div className={cx("col-md-5")}>
                  {!checkServiceAvailability() && <WorkingHoursWidget />}
                </div>
                <div className="col-md-12 mb-3 px-0">
                  <BookingServiceHeader
                    img={this.props.spec.img}
                    fee={this.props.spec.Fee}
                    currency={this.props.spec.Currency}
                    speciality={this.props.spec.Speciality}
                    serviceID={this.state.serviceId}
                    description={this.props.targetService.Description}
                  />
                </div>
                <div
                  className={cx(
                    "col-12 col-md-6 col-lg-6 col-xl-7 mt-1 mt-md-0",
                    classes.FirstSection,
                    `${this.props.targetService.location_type==1?"":"align-self-start"}`
                  )}
                >
                  <h3 className="mb-2">{this.props.t("describe-feeling")}</h3>
                  <textarea
                    className={cx(classes.TextArea, "form-control")}
                    placeholder={this.props.t("describe-status")}
                    name="userCondition"
                    onChange={(event) => {
                      this.setState({
                        ...this.state,
                        form: {
                          ...this.state.form,
                          userCondition: event.target.value,
                        },
                      });
                    }}
                  ></textarea>
                  <h3 className="mb-3">{this.props.t("upload-tests")}</h3>
                  <div className="d-flex mt-0 mt-md-1 mb-md-4 mb-3">
                    <label
                      htmlFor="file-input1"
                      className="rounded d-flex justify-content-center align-items-center"
                    >
                      {this.state.form.file1 ? (
                        <FontAwesomeIcon
                          className={classes.FaCustom}
                          size="2x"
                          icon={faTimesCircle}
                          onClick={(event) => this.removeImg(event, 1)}
                        />
                      ) : null}
                      {this.state.form.file1 ? (
                        <img
                          className="img-responsive"
                          src={this.state.form.file1}
                        />
                      ) : (
                        <img src={imagesPhoto} />
                      )}
                    </label>
                    <input
                      id="file-input1"
                      type="file"
                      className="form-control"
                      onChange={(event) => {
                        this.handelFileInput(event, 1);
                      }}
                    />
                    <label
                      htmlFor="file-input2"
                      className="rounded ml-2 d-flex justify-content-center align-items-center"
                    >
                      {this.state.form.file2 ? (
                        <img
                          className="img-responsive"
                          src={this.state.form.file2}
                        />
                      ) : (
                        <img src={imagesPhoto} />
                      )}
                      {this.state.form.file2 ? (
                        <FontAwesomeIcon
                          className={classes.FaCustom}
                          size="2x"
                          icon={faTimesCircle}
                          onClick={(event) => this.removeImg(event, 2)}
                        />
                      ) : null}
                    </label>
                    <input
                      id="file-input2"
                      type="file"
                      className="form-control ml-2"
                      onChange={(event) => {
                        this.handelFileInput(event, 2);
                      }}
                    />
                  </div>
                  {this.state.positionLoaded&&<h3 className="mt-1 mb-2">{this.props.t("enter-address")}</h3>}                  {this.state.positionLoaded&&<input
                    type="text"
                    className="form-control mt-1"
                    placeholder={this.props.t("address-detailed")}
                    onChange={(event) => {
                      this.setState({
                        ...this.state,
                        form: {
                          ...this.state.form,
                          userAddress: event.target.value,
                        },
                      });
                    }}
                  />}
                  {this.state.positionLoaded&&<h3 className="mb-2">{this.props.t("select-location")}</h3>}
                  <div>
                    {this.state.positionLoaded && <BookingServiceMap
                      lat = {this.state.form.lat}
                      lng = {this.state.form.lng}
                      updateLocation={(lat,lng)=>{
                        this.setState({
                          ...this.state,
                          form: {
                            ...this.state.form,
                            lat,
                            lng,
                          },
                        });
                      }}
                    />}
                  </div>
                </div>
                <div
                  className={cx(
                    "col-12 col-md-6 col-lg-6 col-xl-5",
                    classes.SecondSection
                  )}
                >
                  <div className={cx(classes.PaymentSection)}>
                    <div className={cx(classes.PaymentMethodSection, "text-center")}>
                      <h3 className={cx(classes.Label)}>{this.props.t("payment-method")}</h3>
                      <div className={cx(classes.MethodsWrapper)}>
                          <div 
                          className={cx(classes.MethodBox,
                            (this.state.selectedPaymentMethod=='cash'?classes.SelectedBox:''),
                            (this.props.targetService.location_type==2?classes.Disabled:''))}
                          onClick={()=>{
                            this.setState({
                              ...this.state,
                              selectedPaymentMethod:'cash'
                            });
                          }}
                          >
                            <img src={CashImg}/>
                          </div>
                          <div 
                          className={cx(classes.MethodBox,(this.state.selectedPaymentMethod=='online'?classes.SelectedBox:''))}
                          onClick={()=>{
                            this.setState({
                              ...this.state,
                              selectedPaymentMethod:'online'
                            });
                          }}
                          >
                          <img src={MasterCardImg}/>
                          <img src={VisaImg} className={cx(classes.visaImg)}/>
                          </div>
                      </div>
                      <div className={cx(classes.PromoCode)}>
                          <img src={couponImg} className={cx(classes.PromoCodeIcon)}/>                            
                          {this.state.promoCodeData?
                            (
                              <div className={cx(classes.PromoText)}>
                                <h3 className={cx(classes.PromoLabel)}>
                                  {this.props.lang=='ar'?(`${this.props.t("promocode-applied")} "${this.state.promoCodeData.Title}"`)
                                  :(`"${this.state.promoCodeData.Title}" ${this.props.t("promocode-applied")}`)}
                                </h3>
                                <FontAwesomeIcon  
                                  size="1x"
                                  className={cx(classes.PromoIcon)}
                                  icon={faTimes} 
                                  onClick={()=>{
                                    this.setState({ ...this.state, promoCodeData: null , feeAfterDiscount:null});
                                  }}
                                />
                              </div>
                              )
                            :(
                              <div 
                                onClick={()=>{
                                  this.setState({ ...this.state, promoModalOpened: true });
                                }} 
                                className={cx(classes.PromoText,classes.Pointer)}
                              >
                                <h3 className={cx(classes.PromoLabel)}>{this.props.t("add-promocode")}</h3>
                                <FontAwesomeIcon  
                                  size="1x"
                                  className={cx(classes.PromoIcon)}
                                  icon={this.props.lang=='ar'?faChevronLeft:faChevronRight} 
                                />
                              </div>
                            )}
                      </div>
                      {this.state.promoCodeData&&<div className={cx(classes.DiscountMessage)}>
                          <h3>
                          <span>{this.props.t('service-cost')}</span>:
                          <span className={cx(classes.Original)}>{`${this.props.spec.Fee} ${this.props.t('egyptian-pound')}`}</span>
                          <span className={cx(classes.AfterDiscount)}>{`${this.state.feeAfterDiscount} ${this.props.t('egyptian-pound')}`}</span>
                          </h3>
                      </div>}
                    </div>
                  </div>
                  <div className={cx(classes.SecondSection)}>
                    <div className={cx(classes.CalenderSection, "text-center")}>
                      <h3>{this.props.t("pick-date-time")}</h3>
                      <div className={classes.Calender}>
                        <BookingServiceCalender
                          lang={this.props.lang}
                          service={this.props.targetService}
                          submitDate={(date) => {
                            this.setState({
                              ...this.state,
                              form: {
                                ...this.state.form,
                                reservationDate: date,
                              }
                            });
                          }}
                        />
                      </div>
                      {this.props.targetService.location_type==1 ? (<div
                        className={cx(
                          "row d-flex justify-content-center",
                          classes.CalenderTime
                        )}
                      >
                        <div className="pr-2">
                          <TimeCard
                            lang={this.props.lang}
                            name="time"
                            img={sunImg}
                            text={this.props.t("am")}
                            checked={this.state.form.reservationTime==="12:00:00"?true:false}
                            changed={(value) => {
                              this.setState({
                                ...this.state,
                                form: {
                                  ...this.state.form,
                                  reservationTime: value? "12:00:00":null,
                                },
                              });
                            }}
                          />
                        </div>
                        <div className="pl-2">
                          <TimeCard
                            name="time"
                            checked={this.state.form.reservationTime==="00:00:00"?true:false}
                            value="00:00:00"
                            text={this.props.t("pm")}
                            img={moonImg}
                            changed={(value) => {
                              this.setState({
                                ...this.state,
                                form: {
                                  ...this.state.form,
                                  reservationTime: value?"00:00:00":null,
                                },
                              });
                            }}
                          />
                        </div>
                      </div>):(
                        <div className={cx("mt-3",classes.TimeSlotWrapper)}>
                          <AuthSelect
                            optionsElements={timeSlots(this.state.form.reservationDate)}
                            placeholder={this.props.t("choose-appropriate-time-online")}
                            value={this.state.form.reservationTime}
                            name="slot"
                            changed={(event) => {
                              this.setState({
                              ...this.state,
                              form:{
                                ...this.state.form,
                                reservationTime:event.value
                              }
                            })}}
                          />  
                        </div>
                      )}
                    </div>
                    <div className={cx(classes.Button,"mb-3")}>
                      <Button
                        text={this.props.t("booking-button")}
                        loading={this.props.loading}
                        disabled={
                          !this.state.form.userCondition ||
                          (!this.state.form.userAddress && this.props.targetService.location_type!=2) ||
                          !this.state.form.reservationDate ||
                          !this.state.form.reservationTime ||
                          !this.state.selectedPaymentMethod  
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      );
    } else {
      return <LoadingPage/>;
    }
  }
}
const Extended = withTranslation()(BookingServiceForm);
Extended.static = BookingServiceForm.static;

export default connect(mapStateToProps, mapDispatchToProps)(Extended);
