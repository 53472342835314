import React, { useState ,useRef } from "react";
import * as classes from './LoginForm.module.scss'
import AuthInput from "../../../../shared/Input/Input";
import AuthButton from "../../../../shared/Button/Button";
import mainLogo from "../../../../../assets/img/logos/hoslogo.png";
import { Link } from "react-router-dom";
import cx from 'classnames'
import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";


export default function LoginForm(props) {
  const { t, i18n } = useTranslation();
  const schema = yup.object().shape({
    phone: yup.string().required(t("no-phone-error-message")).matches(/^\d{11}$/,t("phone-not-valid-error-message")),
    password: yup.string().required(t("no-password-error-message"))
  });
  // intial state
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");  
  const { register, handleSubmit, errors ,formState:{isDirty,isValid}} = useForm({
    mode:'all',
    defaultValues:{
      phone:phone,
      password:password
    },
    resolver: yupResolver(schema)
  });

  const onSubmit = (data) => {
    props.submitted(phone, password);
  }
  return (
    <div className={cx(classes.LoginForm)}>
      <div className="text-center text-md-left">
        <img src={mainLogo} className={classes.LogoImg} />
        <h3 className="">{t('loginTitle')}</h3>
      </div>
      <p className="mb-3">{t('loginParagraph')}</p>
      <form onSubmit={handleSubmit(onSubmit)}>
        <AuthInput classCustomName="mt-1 mb-2"
          type="phone"
          name="phone"
          placeholder={t('phoneNumber')}
          changed={(event) => setPhone(event.target.value)}
          // value={phone}
          register={register}
        />
        <p className={classes.errorMessage}>{errors.phone?.message}</p>
        <AuthInput
          classCustomName="mt-1 mb-2"
          type="password"
          name="password"
          placeholder={t('password')}
          changed={(event) => setPassword(event.target.value)}
          // value={password}
          register={register}
        />
        <p className={classes.errorMessage}>{errors.password?.message}</p>
        <div className="d-flex justify-content-end ">
          <Link to="/forget-password">{t('forgetPassword')}</Link>
        </div>
        <div className={cx("", classes.login)}>
          <AuthButton loading={props.loading} disabled={!isValid} text={t('loginTitle')} type="submit" />
        </div>
        <Link className={cx("btn btn-default", classes.register)} to="/register" >
          <p >{t('loginRegister')}</p>
        </Link>

      </form>

    </div>
  );
}
