import React, { Component } from "react";
import AuthLayout from "../../shared/AuthLayout/AuthLayout";
import loginImg from "../../../../assets/img/auth/login.png";
import {
  faChevronRight,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cx from "classnames";
import * as classes from "./ForgetPassword.module.scss";
import forgetPassImg from "../../../../assets/img/auth/017-password-2.png";
import PhoneForm from "./PhoneForm/PhoneForm";
import * as actions from "../../../../store/actions/index";
import { connect } from "react-redux";
import RegisterVerificationForm from "../Register/RegisterVerificationForm/RegisterVerificationForm";
import PasswordForm from "./PasswordForm/PasswordForm";
import ForgetPasswordSuccess from "./ForgetPasswordSuccess/ForgetPasswordSuccess";
import { withTranslation } from "react-i18next";
import VerificationForm from "../../shared/VerificationForm/VerificationForm";

// Access required state from store
const mapStateToProps = (state) => {
  return {
    switchPassValidation: state.auth.switchPassValidation,
    error: state.common.error,
    passwordChanged: state.auth.passwordChanged,
    dir: state.common.currentLanguage,
  };
};
//Access required actions from store
const mapDispatchToProps = (dispatch) => {
  return {
    sendCodeAgain: () => dispatch(actions.sendForgetPasswordCodeAgain()),
    phoneSentCode: (phone) => dispatch(actions.forgetPassSendPhone(phone)),
    passwordReset: (otp, password, confirmPassword) =>
      dispatch(actions.changePasswordStart(otp, password, confirmPassword)),
  };
};
export class ForgetPassword extends Component {
  state = {
    phone: "",
    code: "",
    passwordForm: false,
  };
  // Get phonenumber to send OTP to user
  onSubmitPhone = (phone) => {
    this.props.phoneSentCode(phone);
    this.setState({
      ...this.state,
      phone: phone,
      passwordForm:false
    });
  };
  // Get Otp from child component RegisterVerification code and connect to store to verify the OTP
  onSubmitCode(code) {
    this.setState({
      ...this.state,
      passwordForm: true,
      code: code,
    });
  }
  // Get User new password and the OTP and connect to store which send data to API to reset password
  onSubmitPassword(password, confirmPassword) {
    this.props.passwordReset(this.state.code, password, confirmPassword);
  }

  render() {
    // Load PhoneForm first to user
    let content = (
      <PhoneForm
        error={this.props.error}
        submitted={(phone) => {
          this.onSubmitPhone(phone);
        }}
      />
    );
    // then confirm code then Password form is loaded to make user enter new password
    if (
      this.state.passwordForm &&
      this.props.switchPassValidation &&
      !this.props.passwordChanged
    ) {
      content = (
        <PasswordForm
          submitted={(password, confirmPassword) => {
            this.onSubmitPassword(password, confirmPassword);
          }}
        />
      );
    }
    //if the whole process is successed the success page is loaded
    if (
      this.state.passwordForm &&
      this.props.switchPassValidation &&
      this.props.passwordChanged
    ) {
      return (
        <AuthLayout mainImg={loginImg}>
          <ForgetPasswordSuccess dir={this.props.dir} />
        </AuthLayout>
      );
    }
    // When user enter phone then confirm sended OTP
    if (this.props.switchPassValidation && !this.state.passwordForm) {
      return (
        <div className={cx(classes.ForgetPassword)}>
          <AuthLayout mainImg={loginImg}>
            {/* <RegisterVerificationForm
              submitted={(code) => {
                this.onSubmitCode(code);
              }}
            /> */}
            <VerificationForm
              backLink="/login"
              dir={this.props.dir}
              submitted={(code) => {
                this.onSubmitCode(code);
              }}
              sendAgain={()=>{
                this.setState({
                  ...this.state,
                  passwordForm:false
                });
                this.props.sendCodeAgain();
              }}
            />
          </AuthLayout>
        </div>
      );
    }
    return (
      <div className={cx(classes.ForgetPassword)}>
        <AuthLayout mainImg={loginImg}>
          <div className="d-flex justify-content-between">
            <div className={cx(classes.Back, "d-flex justify-content-center")}>
              <Link to="/login">
                <p className="pt-1">
                  {this.props.dir === "ar" ? (
                    <FontAwesomeIcon className="pt-1" icon={faChevronRight} />
                  ) : (
                    <FontAwesomeIcon className="pt-1" icon={faChevronLeft} />
                  )}
                  <span className="ml-3">{this.props.t("back")}</span>
                </p>
              </Link>
            </div>
            <img src={forgetPassImg} />
          </div>
          <div className={classes.content}>{content}</div>
        </AuthLayout>
      </div>
    );
  }
}
const Extended = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ForgetPassword)
);
Extended.static = connect(
  mapStateToProps,
  mapDispatchToProps
)(ForgetPassword).static;
export default Extended;
