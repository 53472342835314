import React from 'react'
import * as classes from './CheckBox.module.scss';
export default function CheckBox(props) {
    return (
        <div className={classes.mCheckbox} style={props.containerStyle}>
          <input
            style={props.inputStyle}
            type="checkbox"
            checked={props.checked}
            onChange={props.changed}
            className={classes.mCheckboxInput}
          />
        </div>
      );
}
