import * as actionTypes from "../actions/actionTypes";

const defaultState = {
    userProfileData:null,
    notifications :null,
    orderNotifications:null,
    ordinaryNotifications:null,
    loading : false,
    rateMessage :null,
    bloodTypes : null,
    updateProfileMessage:null,
    customerMedicalRecords : [],
    customerMedicationsRecord :[],
    customerSymptomsRecords : [],
    redirectToHealthyHome:false
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case actionTypes.GET_PROFILE_SUCCESS:
            return{
                ...state,
                userProfileData : action.userProfileData
            }
        case actionTypes.GET_NOTIFICATION_LIST_SUCCESS:
            //console.log(action.notifications)
            return{
                ...state,
                notifications:action.notifications,
                orderNotifications: action.orderNotifications,
                ordinaryNotifications:action.ordinaryNotifications

            }
        case actionTypes.RATE_SERVICE_START :
            return{
                ...state,
                loading:true
            }
        case actionTypes.FETCH_HEALTHY_DATA_SUCCESS:
            return{
                ...state,
                loading:false,
                redirectToHealthyHome:true
            }
        case actionTypes.FETCH_HEALTHY_DATA_START:
            return{
                ...state,
                loading:true
            }
        case actionTypes.CLEAR_MEMORY:
            return{
                ...state,
                redirectToHealthyHome:false
            }
        case actionTypes.RATE_SERVICE_SUCCESS:
            return{
                ...state,
                loading:false,
                rateMessage : action.message
            }
        case actionTypes.REMOVE_MESSAGE:
            return{
                ...state,
                rateMessage:null
                
            }
        case actionTypes.UPDATE_PROFILE_START:
            return{
                ...state,
                loading:true
            }
        case actionTypes.UPDATE_PROFILE_SUCCESS:
            return{
                ...state,
                loading:false,
                updateProfileMessage:action.updateProfileMessage
            }
        case actionTypes.REMOVE_UPDATE_PROFILE_MESSAGE:
            return{
                ...state,
                updateProfileMessage: null
            }
        case actionTypes.GET_BLOOD_TYPES_SUCCESS:
            return {
                ...state,
                bloodTypes : action.bloodTypes
            }
        case actionTypes.GET_CUSTOMER_MEDICAL_RECORDS_SUCCESS:
            return{
                ...state,
                customerMedicalRecords:action.records

            }
        case actionTypes.GET_CUSTOMER_MIDICATIONS_SUCCESS:
            return{
                ...state,
                customerMedicationsRecord:action.records

            }
        case actionTypes.GET_CUSTOMER_SYMPTOMS_SUCCESS:
            return{
                ...state,
                customerSymptomsRecords : action.records
            }
        default:
            return state;
    }
}