import React, { useState } from "react";
import * as classes from "./RateModal.module.scss";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import Modal from "react-modal";
import StarRatings from "react-star-ratings";
import Button from '../../../../shared/Button/Button'
import {
    faTimes
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
export default function RateModal(props) {
    const { t, i18n } = useTranslation();
    const [rating, setRating] = useState(0);
    const [comment, setComment] = useState("");
    const changeRating = (newRating, name) => {
        setRating(newRating);
    };
    const submitRate = (event) => {
        event.preventDefault();
        props.submitted(rating, comment);
        setRating(0);
        setComment("")

    }
    return (
        <div>
            <Modal
                className={cx(classes.RateModal)}
                overlayClassName={classes.Overlay}
                isOpen={props.isOpen}
                ariaHideApp={false}
            >
                <div
                    onClick={props.clicked}
                    className={cx(
                        classes.FaCustom,
                        "d-flex justify-content-center align-items-center"
                    )}
                >
                    <FontAwesomeIcon icon={faTimes} color="#707070" />
                </div>
                <div className="text-center">
                    <h3>{t("service-evaluation")}</h3>
                </div>
                {/* {props.message?<div className="alert alert-success" role="alert">{props.message}</div>:null} */}
                <div>
                    <form onSubmit={(event) => { submitRate(event) }}>
                        <p>{t("service-evaluation-para")}</p>
                        <div className={classes.Rating}>
                            <StarRatings
                                rating={rating}
                                starRatedColor="#FFB300"
                                changeRating={changeRating}
                                starDimension="40px"
                                starSpacing="5px"
                                starHoverColor="#FFB300"
                                name="rating"
                            />
                        </div>
                        <p>{t("comment")}</p>
                        <textarea
                            name="text"
                            value={comment}
                            onChange={(event) => {
                                setComment(event.target.value);
                            }}
                            placeholder={t("rate-hosbitalia-modal")}
                            className="form-control"
                            rows="3"
                        ></textarea>
                        <Button disabled={!rating || !comment} text={t("finish")} loading={props.loading} />
                    </form>
                </div>
            </Modal>
        </div>
    );
}
