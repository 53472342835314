import React,{useEffect, useState} from "react";
import * as classes from "./EditAccount.module.scss";
import ProfileInput from "../../../shared/ProfileInput/ProfileInput";
import AccountAddressForm from "../AccountInfo/AccountAddressForm/AccountAddressForm";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import {toast } from 'react-toastify';
export default function EditAccount(props) {
  const { t, i18n } = useTranslation();
  const [address,setAddress] = useState("");
  const [email,setEmail] =useState(props.accountData.Email?props.accountData.Email:'')
  const [name,setName] =useState(props.accountData.FirstName?props.accountData.FirstName:'');
  useEffect(() => {
    if(props.accountData.Address){
    let addressProp=props.accountData.Address.split('$')
    //console.log(addressProp[0])
    setAddress(addressProp[0])
    }
    if(props.message){
      toast(props.message)
    }
    
  });
  const submitForm=(address,lat,lng)=>{
    const newAddress=address + '$'+lat + ','+lng
    props.submitted(newAddress,name)

  }
  
  return (
    <div className={cx(classes.EditAccount, "row")}>
      <div className="col-12">
        <h3> {t("edit-profile")}</h3>
        <p>{t("account-full-name")}</p>
        <ProfileInput type="text" name="name" changed={(event)=>{setName(event.target.value)}}  value={name} />
        <p className="mt-2">{t("account-email")}</p>
        <ProfileInput type="text" name="email" changed={(event)=>{setEmail(event.target.value)}}  value={email} />
        <div className="mt-2 mt-md-3">
          {<AccountAddressForm switchBack={()=>{props.switchBack()}} loading={props.loading} address={address} submitted={(address,lat,lng)=>{submitForm(address,lat,lng)}}/>}
        </div>
      </div>
    </div>
  );
}
