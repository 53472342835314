import React from "react";
import * as classes from './OrderCallButton.module.scss'
import cx from "classnames"
const OrderCallButton = (props) => {
    return (
        <div className={classes.OrderCallButton}>
            <button 
            onClick = {props.clicked}
            type={props.type}
                className={cx("btn btn-default", props.classCustomName)}
            >{props.text}</button>
        </div>
    );
};
export default OrderCallButton;