import React, { Component } from "react";
import loginImg from "../../../../assets/img/auth/login.png"; 
import { connect } from "react-redux";
import * as actions from "../../../../store/actions/index";
import AuthLayout from "../../shared/AuthLayout/AuthLayout";
import LoginForm from "./LoginForm/LoginForm";
// Load required state from store
const mapStateToProps = (state) => {
  return {
    error: state.common.error,
    token: state.common.token,
    loading: state.auth.loading
  };
};
// Load Required action from store
const mapDispatchToProps = (dispatch) => {
  return {
    onLogin: (phone, password) => dispatch(actions.loginStart(phone, password)),
  };
};
class Login extends Component {
  // Getting phone and password user entered then connect to store which cobbect to API to verify user
  handelSubmit = (phone, password) => {
    this.props.onLogin(phone, password);
    if (this.props.token) {
      this.props.history.goBack();
    }
  };
  render() {
    return (
      <div>
        <AuthLayout mainImg={loginImg}>
          <LoginForm
            error={this.props.error}
            loading={this.props.loading}
            submitted={(phone, password) => this.handelSubmit(phone, password)}
          />
        </AuthLayout>
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Login);
