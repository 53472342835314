import moment from "moment";
import axios from "../../axios";
import { toast } from 'react-toastify';
import * as actiontypes from "./actionTypes";
// get user profile data
export const getProfile = (clientID) => {
  return (dispatch) => {
    axios.get("/users/getprofile?UserID=" + clientID).then((res) => {
      if (res.data.statuscode == 200) {
        dispatch(getProfileSuccess(res.data.responseobject[0]));
      }
    });
  };
};
export const getProfileSuccess = (userProfile) => {
  return {
    type: actiontypes.GET_PROFILE_SUCCESS,
    userProfileData: userProfile,
  };
};
export const updateProfile = (profileData) => {
  const formData = new FormData();
  formData.append('ProfilePic', profileData.ProfilePic);

  return (dispatch) => {
    dispatch(updateProfileStart());
    axios
      .post("/users/updateprofile", {
        UserID: profileData.Id,
        FirstName: profileData.FirstName,
        MiddleName: profileData.MiddleName === 'null' ? null : profileData.MiddleName,
        LastName: profileData.LastName,
        BirthDate: profileData.Birthdate,
        isMale: profileData.isMale,
        BloodTypeID: profileData.BloodTypeId,
        Height: profileData.Height,
        Weight: profileData.Weight,
        isSmoker: profileData.isSmoker,
        isAlcoholic: profileData.isAlcoholic,
        Address: profileData.Address,
      })
      .then((res) => {
        dispatch(updateProfileSuccess(res.data.statusmessage))
      });
  };
};
export const updateProfileStart = () => {
  return {
    type: actiontypes.UPDATE_PROFILE_START
  }
}
export const updateProfileSuccess = (message) => {
  return {
    type: actiontypes.UPDATE_PROFILE_SUCCESS,
    updateProfileMessage: message
  }
}
export const removeUpdateProfileMessage = () => {
  return {
    type: actiontypes.REMOVE_UPDATE_PROFILE_MESSAGE
  }
}

export const saveProfilePic = (pic) => {
  console.log(pic)
  let formData = new FormData();
  formData.append('File', pic);
  return dispatch => {
    axios.post('/users/updateprofilepic', formData).then(res => {
      console.log(res)
    })
  }
}
// get user medications data
export const getCustomerMedicalRecords = (clientID, lang) => {
  const reqData = {
    clientID: clientID,
    langID: lang == "ar" ? "2" : "1",
  };
  return (dispatch) => {
    axios.post("/customers/getcustomermedicalrecords", reqData).then((res) => {
      dispatch(getCustomerMedicalRecordsSuccess(res.data.responseobject));
    });
  };
};
//add user medications success
export const getCustomerMedicalRecordsSuccess = (records) => {
  return {
    type: actiontypes.GET_CUSTOMER_MEDICAL_RECORDS_SUCCESS,
    records: records
  }
}
export const getcustomerMedications = (clientID, lang) => {
  const reqData = {
    clientID: clientID,
    langID: lang == "ar" ? "2" : "1",
  };
  return (dispatch) => {
    axios.post("/customers/getcustomermedications", reqData).then((res) => {
      dispatch(getcustomermedicationsSuccess(res.data.responseobject));
    });
  };
};
export const getcustomermedicationsSuccess = (records) => {
  return {
    type: actiontypes.GET_CUSTOMER_MIDICATIONS_SUCCESS,
    records: records
  }
}

export const getCustomerSymptoms = (clientID, lang) => {
  const reqData = {
    clientID: clientID,
    langID: lang == "ar" ? "2" : "1",
  };
  return (dispatch) => {

    axios.post("/customers/getcustomersymptoms", reqData).then((res) => {
      dispatch(getCustomerSymptomsSuccess(res.data.responseobject));
    });
  };
};
export const getCustomerSymptomsSuccess = (records) => {
  return {
    type: actiontypes.GET_CUSTOMER_SYMPTOMS_SUCCESS,
    records: records
  }
}
export const fetchHealthyDataStart= ()=>{
  return {
    type : actiontypes.FETCH_HEALTHY_DATA_START
  }
}
export const fetchHealthyDataSuccess = ()=>{
  return {
    type : actiontypes.FETCH_HEALTHY_DATA_SUCCESS
  }
}
export const addSymptom = (customerID, symptomDescription) => {
  const requestData = {
    customerID: customerID,
    symptomDescription: symptomDescription,
    requestID: "3fa85f64-5717-4562-b3fc-2c963f66afa6"
  }
  return dispatch=>{
    dispatch(fetchHealthyDataStart());
    axios.post('/customers/addsymptom',requestData).then(res=>{
      toast(res.data.statusmessage)
      dispatch(fetchHealthyDataSuccess())
      dispatch(clearMemory())
    })
  }
}


export const addMedication = (clientID, medicationName) => {
  const requestData = {
    clientID: clientID,
    medicationName: medicationName,
    requestID: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    dosageNote : moment().locale('en'),
    isCurrent: true

  }
  console.log(requestData.dosageNote)
  return dispatch=>{
    dispatch(fetchHealthyDataStart());
    axios.post('/customers/addmedicationtoclient',requestData).then(res=>{
      toast(res.data.statusmessage);
      dispatch(fetchHealthyDataSuccess())
      dispatch(clearMemory())
    })
  }
}

export const addMedicalHistory = (customerID, medicalNote) => {
  const requestData = {
    customerID: customerID,
    medicalNote: medicalNote,
    requestID: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    diagnosis:  moment().locale('en'),
    attachments : []

  }
  return dispatch=>{
    dispatch(fetchHealthyDataStart());
    axios.post('/customers/savemedicalrecord',requestData).then(res=>{
      toast(res.data.statusmessage);
      dispatch(fetchHealthyDataSuccess());
      dispatch(clearMemory())
    })
  }
}
export const clearMemory=()=>{
  return{
    type : actiontypes.CLEAR_MEMORY
  }
}
/* start notifications */


// get bloods types
export const getBloodTypes = () => {
  return dispatch => {
    axios.get('/lookups/BpTs').then(res => {
      dispatch(getBloodTypesSuccess(res.data.responseobject))
    })
  }
}
export const getBloodTypesSuccess = (bloodTypes) => {
  return {
    type: actiontypes.GET_BLOOD_TYPES_SUCCESS,
    bloodTypes: bloodTypes
  }
}




// end of get bloods types 
export const getNotificationList = (userID, lang) => {
  const requestData = {
    userID: userID,
    langID: lang == "ar" ? 2 : 1,
    all: 1,
  };
  return (dispatch) => {
    axios.post("/mng/usernotificationslist", requestData).then((res) => {
      if (res.data.statuscode == 200) {
        const orderNotifications = res.data.responseobject.filter((el) => {
          return el.NotificationType != 8;
        });
        const ordinaryNotifications = res.data.responseobject.filter((el) => {
          return el.NotificationType == 8;
        });
        return dispatch(
          getNotificationListSuccess(
            res.data.responseobject,
            orderNotifications,
            ordinaryNotifications
          )
        );
      }
    });
  };
};

export const getNotificationListSuccess = (
  notifications,
  orderNotifications,
  ordinaryNotifications
) => {
  return {
    type: actiontypes.GET_NOTIFICATION_LIST_SUCCESS,
    notifications: notifications,
    orderNotifications: orderNotifications,
    ordinaryNotifications: ordinaryNotifications,
  };
};
export const rateService = (id, rate, comment) => {
  const reqData = {
    requestID: id,
    rate: rate,
    comment: comment,
  };
  return (dispatch) => {
    dispatch(rateServiceStart());
    axios.post("/ServiceRequest/raterequest", reqData).then((res) => {
      dispatch(rateServiceSuccess(res.data.statusmessage));
    });
  };
};
export const rateServiceStart = () => {
  return {
    type: actiontypes.RATE_SERVICE_START,
  };
};
export const rateServiceSuccess = (message) => {
  toast.success(message,{autoClose:5000})
  return {
    type: actiontypes.RATE_SERVICE_SUCCESS,
    message: message,
  };
};
export const removeMessage = () => {
  return {
    type: actiontypes.REMOVE_MESSAGE,
  };
};

/* end notifications */
