import React, { Component } from "react";
import AuthLayout from "../../shared/AuthLayout/AuthLayout";
import registerImg from "../../../../assets/img/auth/doc search.png";
import RegisterForm from "./RegisterForm/RegisterForm";
import RegisterVerificationForm from "./RegisterVerificationForm/RegisterVerificationForm";
import * as actions from '../../../../store/actions/index'
import { connect } from "react-redux";
import RegisterSuccess from "./RegisterSuccess/RegisterSuccess";
// Load required state from store
const mapStateToProps = (state) => {
  return {
    switchValidation: state.auth.switchValidation,
    error: state.common.error,
    activateAccountSuccess : state.auth.activateAccountSuccess,
    dir : state.common.currentLanguage,
    loading:state.auth.loading
  };
};
// Load required actions from store
const mapDispatchToProps = (dispatch) => {
  return {
    onRegister: (regData) => dispatch(actions.registerStart(regData)),
    onActiveAccount: (activateData) => dispatch(actions.activateAccountStart(activateData))
  };
};

export class Register extends Component {
  state = {
    userData: null,
  };
  // Taking user's registeration data and sending it to backend then sending OTP
  setRegisterData = (regData) => {
    if (regData.Email === '') {
      regData.Email = "something@something.com"
    }
    this.setState({
      ...this.state,
      userData: regData,
    });
    this.props.onRegister(regData);
  };
  //taking confirmation of OTP code then complete registration process
  onSubmitCode = (code) => {
    const activateDate = {
      PhoneNumber: this.state.userData.PhoneNumber,
      ConfirmationCode: code
    }
    this.props.onActiveAccount(activateDate);

  }
  render() {
    // if Code sended then user shows RegisterVerificationForm to submit sended code
    if (this.props.switchValidation&&!this.props.activateAccountSuccess) {
      return (
        <div>
          <AuthLayout mainImg={registerImg} registerImg={true}>
            <RegisterVerificationForm backLink="/login" dir={this.props.dir} submitted={(code) => { this.onSubmitCode(code) }} error={this.props.error} />
          </AuthLayout>
        </div>
      );
    }
    // if the whole process success RegisterSuccess page is loaded
    if(this.props.switchValidation&&this.props.activateAccountSuccess){
      return (
        <div>
          <AuthLayout mainImg={registerImg} registerImg={true}>
            <RegisterSuccess />
          </AuthLayout>
        </div>
      );
    }
    return (
      // first load RegisterForm to take user's registeration data
      <div>
        <AuthLayout mainImg={registerImg} registerImg={true} >
          <RegisterForm
            submitted={(regData) => {
              this.setRegisterData(regData);
            }}
            loading={this.props.loading}
            error={this.props.error}
          />
        </AuthLayout>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Register);
