import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import * as classes from "./Header.module.scss";
import cx from "classnames";
import headerLogo from "../../../assets/img/auth/hospitalia-logo-2.png";
import userNullImg from "../../../assets/img/Header/Group 62721.png";
import { useTranslation } from "react-i18next";
import {
  faChevronDown,
  faChevronUp,
  faCog,
  faGlobeAmericas,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faHeart, faBell } from "@fortawesome/free-regular-svg-icons";
import userLogo from "../../../assets/img/Header/user (5).png";
import healthyLogo from "../../../assets/img/Header/cardiology.png";
import bellLogo from "../../../assets/img/Header/bell.png";
import settingLogo from "../../../assets/img/Header/settings.png";
import logoutLogo from "../../../assets/img/Header/logout (1).png";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";

const Header = (props) => {
  const { t, i18n } = useTranslation();
  const [showSmMenu, setshowSmMenu] = useState(false);
  const [showSmSubMenu, setshowSmSubMenu] = useState(false);
  const [showMdMenu, setshowMdMenu] = useState(false);

  useEffect(() => {
    // Update the document title using the browser API
    window.onclick = () => {
      if (showMdMenu) {
        setshowMdMenu(false);
      }
      /*if(showSmSubMenu){
        setshowSmSubMenu(false)
      }*/
    };
  });
  return (
    <div>
      <nav
        className={cx(
          "navbar navbar-expand-lg navbar-transparent bg-transparent fixed-top",
          classes.Header
        )}
      >
        <Link className="navbar-brand" to="/">
          <img src={headerLogo} className={classes.HeaderLogoBrand} />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={() => {
            setshowSmMenu(!showSmMenu);
          }}
        >
          <span
            className={classes.collapseNav,cx(
              "navbar-toggler-icon",
              showSmMenu
                ? classes.navbarTogglerIconClose
                : classes.navbarTogglerIcon
            )}
          ></span>
        </button>

        <div
          className={"collapse navbar-collapse ".concat(
            showSmMenu ? "show" : null
          )}
          id="navbarSupportedContent"
        >
          <ul className="navbar-nav order-first order-md-last">
            <div
              className={cx(
                "d-flex justify-content-between justify-content-lg-start nav-sm-header",
                classes.navSmHeader
              )}
            >
              <li
                className={cx(
                  "nav-item  d-flex align-items-center order-last order-lg-first",
                  classes.navItem
                )}
                onClick={props.changeLanguage}
              >
                <button className={cx(classes.LangButton, "btn btn-default")}>
                  <span className="mr-3">{props.lang}</span>
                  <FontAwesomeIcon
                    className={classes.LangFont}
                    icon={faGlobeAmericas}
                    color="#7C126B"
                  />
                </button>
              </li>
              {props.currentUser ? (
                <li
                  className={cx(
                    "dropdown d-flex justify-content-between align-items-center ml-0 ml-md-3 mt-3 mt-lg-0",
                    classes.PrimeButton
                  )}
                  onMouseEnter={(event) => {
                    event.preventDefault();
                    setshowMdMenu(true);
                  }}
                  onMouseLeave={(event) => {
                    event.preventDefault();
                    setshowMdMenu(false);
                  }}
                >
                  <NavLink
                    className={cx(
                      "nav-link dropdown-toggle p-0 mt-1 ",
                      classes.dropDownNav,
                      classes.dropdownToggle
                    )}
                    id="navbarDropdown1"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    to="/account"
                  >
                    {props.profileImg ? (
                      <img
                        className={cx(classes.userNullImg, "mr-2")}
                        src={props.profileImg}
                      />
                    ) : (
                      <img
                        src={userNullImg}
                        style={{
                          borderRadius: "50%",
                          width: "40px",
                          height: "40px",
                        }}
                      />
                    )}
                    <span>{props.currentUser}</span>
                  </NavLink>
                  {showMdMenu ? (
                    <FontAwesomeIcon
                      className="d-none d-lg-block ml-2 mt-1"
                      icon={faChevronUp}
                      color="#fff"
                    />
                  ) : (
                    <FontAwesomeIcon
                      className="d-none d-lg-block ml-2 mt-1"
                      icon={faChevronDown}
                      color="#fff"
                      onClick={(event) => {
                        event.preventDefault();
                        setshowMdMenu(true);
                      }}
                    />
                  )}

                  <div
                    className={cx(
                      "dropdown-menu ".concat(showMdMenu ? "show" : null),
                      showMdMenu ? classes.fade : classes.fadeReverse,
                      classes.dropdownMenu,
                      classes.DropDownMd
                    )}
                    aria-labelledby="navbarDropdown1"
                  >
                    <Link
                      className={cx(
                        "dropdown-item d-none d-lg-block px-2 ",
                        classes.dropDownP,
                        classes.dropdownItem
                      )}
                      to="/account"
                    >
                      <img src={userLogo} className="mr-2" />
                      {t("my-account")}
                    </Link>
                    <div
                      className={cx(
                        "dropdown-divider d-none d-lg-block ",
                        classes.DividerSm
                      )}
                    ></div>
                    <Link
                      className={cx(
                        "dropdown-item px-2",
                        classes.dropDownP,
                        classes.dropdownItem
                      )}
                      to="/healthy"
                    >
                      <img src={healthyLogo} className="mr-2" />
                      {t("my-healthy")}
                    </Link>
                    <div
                      className={cx("dropdown-divider ", classes.DividerSm)}
                    ></div>
                    <Link
                      className={cx(
                        "dropdown-item px-2 ",
                        classes.dropDownP,
                        classes.dropdownItem
                      )}
                      to="/notifications"
                    >
                      <img src={bellLogo} className="mr-2" />
                      {t("notifications")}
                    </Link>
                    <div
                      className={cx("dropdown-divider ", classes.DividerSm)}
                    ></div>
                    {/* <Link
                      className={cx(
                        "dropdown-item px-2",
                        classes.dropDownP,
                        classes.dropdownItem,
                        classes.Unused
                      )}
                      to="/"
                      onClick={(event) => {
                        event.preventDefault();
                      }}
                    >
                      {" "}
                      <img src={settingLogo} className="mr-2" />
                      {t("settings")}
                    </Link> */}
                    {/* <div
                      className={cx("dropdown-divider", classes.DividerSm)}
                    ></div> */}
                    <Link
                      className={cx(
                        "dropdown-item px-2",
                        classes.dropDownP,
                        classes.dropdownItem
                      )}
                      to="/"
                      onClick={props.logout}
                    >
                      <img src={logoutLogo} className="mr-2" />
                      {t("sign-out")}
                    </Link>
                  </div>
                </li>
              ) : (
                /*
                <li className={cx("nav-item mt-2 d-block d-lg-none", classes.navItem)}>
                  <NavLink className="nav-link" exact to="/login" activeClassName={classes.activeLink}>
                    {t("sign-in")}
                  </NavLink>
                </li>*/
                <Link
                  to="/login"
                  className={cx(
                    classes.PrimeButton,
                    "btn btn-success d-flex justify-content-around align-items-center ml-0 ml-md-3 mt-3 mt-lg-0 "
                  )}
                >
                  <span>{t("sign-in")}</span>
                  <FontAwesomeIcon
                    color="#fff"
                    className="ml-3"
                    icon={faUser}
                    style={{ width: "19px", height: "19px" }}
                  />
                </Link>
              )}
            </div>
          </ul>
          <ul
            className={cx(
              "navbar-nav mr-md-auto order-last order-md-first ",
              classes.mainNav
            )}
          >
            <li className={cx("nav-item ml-0", classes.navItem)}>
              <NavLink
                className="nav-link"
                exact
                to="/"
                activeClassName={classes.activeLink}
              >
                <p className={classes.NavLinkCustom}>{t("services")}</p>
              </NavLink>
            </li>
            {/*
            <div className={cx("dropdown-divider d-block d-lg-none  ", classes.DividerSm)}></div>
            <li className={cx("nav-item ml-0", classes.navItem)}>
              <Link className={cx("nav-link", classes.Unused)} to="/" onClick={(event) => event.preventDefault()}>
                <p className={classes.NavLinkCustom}>{t("services-providers")}</p>
              </Link>
            </li>*/}
            <div
              className={cx(
                "dropdown-divider d-block d-lg-none ",
                classes.DividerSm
              )}
            ></div>
            <li className={cx("nav-item ml-0", classes.navItem)}>
              <NavLink
                className="nav-link"
                exact
                to="/order-call"
                activeClassName={classes.activeLink}
              >
                <p className={classes.NavLinkCustom}>{t("customer-service")}</p>
              </NavLink>
            </li>

            {props.currentUser ? (
              <div>
                <div
                  className={cx(
                    "dropdown-divider d-block d-lg-none ",
                    classes.DividerSm
                  )}
                ></div>
                <li className={"dropdown d-block d-lg-none px-3 px-lg-0"}>
                  <Link
                    className={cx(
                      "nav-link dropdown-toggle d-flex justify-content-between",
                      classes.dropdownToggle
                    )}
                    to="/account"
                    id="navbarDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <p>{t("my-account")}</p>
                    <FontAwesomeIcon
                      className=""
                      icon={faChevronDown}
                      onClick={(event) => {
                        event.preventDefault();
                        setshowSmSubMenu(!showSmSubMenu);
                      }}
                    />
                  </Link>

                  <div
                    className={cx(
                      "dropdown-menu ".concat(showSmSubMenu ? "show" : null),
                      classes.dropdownMenu
                    )}
                    aria-labelledby="navbarDropdown"
                  >
                    <div className="dropdown-divider d-none d-lg-block"></div>
                    <Link
                      className={cx(
                        "dropdown-item px-3",
                        classes.dropDownP,
                        classes.dropdownItem
                      )}
                      to="/healthy"
                    >
                      <img src={healthyLogo} className="mr-2" />
                      {t("my-healthy")}
                    </Link>
                    <div
                      className={cx("dropdown-divider", classes.DividerSm)}
                    ></div>
                    <Link
                      className={cx(
                        "dropdown-item px-3 ",
                        classes.dropDownP,
                        classes.dropdownItem
                      )}
                      to="/notifications"
                    >
                      <img src={bellLogo} className="mr-2" />
                      {t("notifications")}
                    </Link>
                    <div
                      className={cx("dropdown-divider", classes.DividerSm)}
                    ></div>
                    {/* <Link
                      className={cx(
                        "dropdown-item px-3",
                        classes.dropDownP,
                        classes.dropdownItem,
                        classes.Unused
                      )}
                      to="/"
                      onClick={(event) => {
                        event.preventDefault();
                      }}
                    >
                      {" "}
                      <img src={settingLogo} className="mr-2" />
                      {t("settings")}
                    </Link> */}
                    {/* <div
                      className={cx("dropdown-divider", classes.DividerSm)}
                    ></div> */}
                    <Link
                      className={cx(
                        "dropdown-item px-3 ",
                        classes.dropDownP,
                        classes.dropdownItem
                      )}
                      to="/"
                      onClick={props.logout}
                    >
                      <img src={logoutLogo} className="mr-2" />
                      {t("sign-out")}
                    </Link>
                  </div>
                </li>
              </div>
            ) : null}
            <div
              className={cx(
                "dropdown-divider d-block d-lg-none ",
                classes.DividerSm
              )}
            ></div>
          </ul>
        </div>
      </nav>
    </div>
  );
};
export default Header;
