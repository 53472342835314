import React, { Component } from "react";
import * as classes from "./BookingService.module.scss";
import cx from "classnames";
import { Link } from "react-router-dom";
import BookingServiceCard from "../../shared/NewBookingServiceCard/NewBookingServiceCard";
import phoneImg from "../../../../assets/img/bookingService/006-support.png";
import doctorsPhoto from "../../../../assets/img/bookingService/docs.png";
import labPhoto from "../../../../assets/img/bookingService/labs .png";
import BookingServiceButton from "../../shared/BookingServiceButton/BookingServiceButton";
import customerService from "../../../../assets/img/landingPage/customer-service.svg";
import * as actions from "../../../../store/actions/index";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import CustomModal from "../../shared/Modal/Modal";
import LoadingPage from "../../../shared/LoadingPage/LoadingPage";
import arrowImg from "../../../../assets/img/bookingService/external-link-square-alt.png";
import {checkServiceAvailability} from './../../../../utils/utils';
import WorkingHoursWidget from "../../shared/WorkingHoursWidget/WorkingHoursWidget";
const mapStateToProps = (state) => {
  return {
    targetService: state.bookingService.targetService,
    serviceSpecs: state.bookingService.serviceSpecs,
    lang: state.common.currentLanguage,
    token: state.common.token,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getServices: (id) => {
      dispatch(actions.getService(id));
    },
    getServiceSpecs: (id, lang) => {
      dispatch(actions.getServiceSpecs(id, lang));
    },
  };
};

export class BookingService extends Component {
  state = {
    doc: {
      header: this.props.t("available-specialities"),
      img: doctorsPhoto,
      imgText: this.props.t("doctor-nurses-registerd"),
    },
    nur: {
      header: this.props.t("available-nursing"),
    },
    pys: {
      header: this.props.t("available-pys"),
    },
    labs: {
      header: this.props.t("available-tests"),
      img: labPhoto,
      imgText: this.props.t("labs-registerd"),
    },
    rays: {
      header: this.props.t("available-xrays"),
    },
    servicesId: null,
    isAuthenticated: false,
  };
  componentDidMount() {
    const serviceID = this.props.match.params.id;
    this.props.getServices(serviceID);
    this.setState({
      ...this.state,
      servicesId: serviceID,
    });
    this.props.getServiceSpecs(serviceID, this.props.lang);
  }
  onMoveToSpecialization = (event, id, name) => {
    event.preventDefault();
    if (!this.props.token) {
      this.setState({
        ...this.state,
        isAuthenticated: true,
      });
    } else {
      this.props.history.push(
        this.props.match.url + "/" + id + "/" + name.split(" ").join("-")
      );
    }
  };
  render() {
    let items = null;
    if (this.props.targetService) {
      if (this.props.serviceSpecs) {
        let itemsCopy = [...this.props.serviceSpecs];
        items = itemsCopy.map((item) => {
          return this.state.servicesId == 5 ? (
            <div
              key={item.ID}
              className={cx("col-12 col-md-6", classes.GridPadding)}
            >
              <BookingServiceCard
                classCustomName={item.ID == 3 ? "mb-3" : null}
                name={item.Speciality}
                description={item.Description}
                img={item.img}
                clicked={(event, id) => {
                  this.onMoveToSpecialization(event, item.ID, item.Speciality);
                }}
              />
            </div>
          ) : (
            <div
              key={item.ID}
              className={cx("col-12 col-md-6 ", classes.GridPadding)}
            >
              <BookingServiceCard
                classCustomName={item.ID == 4 ? "mb-3" : null}
                name={item.Speciality}
                img={item.img}
                description={item.Description}
                clicked={(event) => {
                  this.onMoveToSpecialization(event, item.ID, item.Speciality);
                }}
              />
            </div>
          );
        });
      }
      return (
        <div className={classes.BookingService}>
          {this.state.isAuthenticated ? (
            <CustomModal
              {...this.props}
              clicked={() => {
                this.setState({ ...this.state, isAuthenticated: false });
              }}
              isOpen={this.state.isAuthenticated}
            />
          ) : null}
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-7">
                <nav aria-label="breadcrumb">
                  <ol className={cx("breadcrumb", classes.BreadCrumbCustom)}>
                    <li className="breadcrumb-item">
                      <Link to="/">{this.props.t("hospitalia-services")}</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      <span>{this.props.targetService["ServiceType"]}</span>
                    </li>
                  </ol>
                </nav>
              </div>
              <div className={cx("col-md-5")}>
                {!checkServiceAvailability() && <WorkingHoursWidget />}
              </div>
              <div className={cx("col-md-8", classes.Specializations)}>
                <div className="row">
                  <div
                    className={cx(
                      "col-md-12 text-center text-md-left d-md-flex justify-content-center justify-content-md-start",
                      classes.FirstSection
                    )}
                  >
                    <div
                      className={cx(
                        classes.GrayCircle,
                        "d-flex justify-content-center"
                      )}
                    >
                      <img src={this.props.targetService["img"]} />
                    </div>
                    <div
                      className={cx(classes.FirstSectionChild, "ml-0 ml-md-4")}
                    >
                      <h3>{this.props.targetService["ServiceType"]}</h3>
                      <p>{this.props.targetService["Description"]}</p>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <hr />
                  </div>
                </div>
                <div className="col-md-12 mt-4">
                  <h3 className="mb-2 ">
                    {this.state.servicesId == 1
                      ? this.state.doc.header
                      : this.state.servicesId == 2
                      ? this.state.nur.header
                      : this.state.servicesId == 3
                      ? this.state.pys.header
                      : this.state.servicesId == 4
                      ? this.state.rays.header
                      : this.state.labs.header}
                  </h3>
                  <p>{this.props.t("choose-service-house")}</p>
                </div>
                <div className="col-md-12">
                  <div className="row">{items}</div>
                </div>
              </div>
              <div className="col-md-4 pl-0 pl-md-4">
                <div className={cx("text-center", classes.CallRequest)}>
                  <img src={customerService} />
                  <p>{this.props.t("order-call-para")}</p>
                  <Link to="/order-call" className={cx("btn btn-default")}>
                    <span>{this.props.t("order-call")}</span>
                  </Link>
                </div>
                <div className={cx("text-center", classes.DoctorsRegistered)}>
                  <img
                    src={arrowImg}
                    className={classes.DoctorsRegisteredAbs}
                  />
                  <img
                    src={
                      this.state.servicesId == 4 || this.state.servicesId == 5
                        ? labPhoto
                        : doctorsPhoto
                    }
                  />
                  <p>
                    {this.state.servicesId == 4 || this.state.servicesId == 5
                      ? this.props.t("labs-registerd")
                      : this.props.t("doctor-nurses-registerd")}
                  </p>
                </div>
                <div className={classes.Button}>
                  <BookingServiceButton text={this.props.t("home-medicine")} />
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return <LoadingPage />;
  }
}

const Extended = withTranslation()(BookingService);
Extended.static = BookingService.static;
export default connect(mapStateToProps, mapDispatchToProps)(Extended);
