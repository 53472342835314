import * as actionTypes from "../actions/actionTypes";

const defaultState = {
  appName: "Hospitalia",
  token: null,
  error: null,
  currentLanguage: null,
  otherLanguage: null,
  profileImg: null,
  username: null,
  services: null,
  clientID: null,
  isLandingPage: false
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case actionTypes.APP_LOAD:
      return {
        ...state,
        currentLanguage: action.currentLanguage,
        otherLanguage: action.otherLanguage,
        token: action.token || null,
        currentUser: action.currentUser || null,
        appLoaded: true,
        username: action.username,
        clientID: action.clientID,
        profileImg: action.profileImg,
        redirectTo: null,
      };
    case actionTypes.LOGIN_START:
      return {
        ...state,
        error: null
      }
    case actionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        token: action.token,
        currentUser: action.currentUser,
        error: action.token ? null : state.error,
        profileImg: action.profileImg,
        username: action.username,
        clientID: action.clientID
      };
    case actionTypes.LOGOUT:
      return {
        ...state,
        token: null,
        currentUser: null,
        clientID: null,
        username: null

      };
    /*case actionTypes.ERROR:
      return {
        ...state,
        error: action.error
      }*/
    case actionTypes.LOAD_SERIVCES_SUCCESS:
      return {
        ...state,
        services: action.services
      }
    case actionTypes.GET_PROFILE_SUCCESS:
      return {
        ...state,
        profileImg: action.userProfileData.ProfilePic

      }
    case actionTypes.SUBSCRIBE_LANDING_PAGE:
      return {
        ...state,
        isLandingPage: true
      }
    case actionTypes.UNSUBSCRIBE_LANDING_PAGE:
      return {
        ...state,
        isLandingPage: false
      }
    default:
      return state;
  }
};
