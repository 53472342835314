import React from "react";
import cx from 'classnames'
import CkeckBox from "./CheckBox/CkeckBox";
import * as classes from './CallRequestForm.module.scss'
import { useTranslation } from "react-i18next";
export default function CallRequestForm() {
    const { t, i18n } = useTranslation();
    return (
        <div className={classes.CallRequestForm}>
            <div className="row ">
            <h3 className="mb-2 col-12">{t("order-call")}</h3>
            <p className="mb-4 mb-md-3 col-md-8  text-left">
            {t("order-call-para")}
            </p>
            </div>
            <div className="row">
                <div className={cx("col-md-4 col-lg-4 col-xl-3 col-6 mb-2", classes.restBoxPadding)} >
                    <CkeckBox index="0" title={t("increased-perspiration")} />
                </div>
                <div className={cx("col-md-4 col-lg-4 col-xl-3  col-6", classes.restBoxPadding)} >
                    <CkeckBox index="1" title={t("preasure")}/>
                </div>
                <div className={cx("col-md-4 col-lg-4 col-xl-3  col-6", classes.restBoxPadding)} >
                    <CkeckBox index="2" title={t("cough")} />
                </div>
                <div className={cx("col-md-4 col-lg-4 col-xl-3  col-6 mb-2 mb-md-0", classes.restBoxPadding)} >
                    <CkeckBox index="3" title={t("dizziness")}/>
                </div>
                <div className={cx("col-md-4 col-lg-4 col-xl-3  col-6", classes.restBoxPadding)} >
                    <CkeckBox index="4" title={t("scaly-skin")}/>
                </div>
                <div className={cx("col-md-4 col-lg-4 col-xl-3  col-6", classes.restBoxPadding)} >
                    <CkeckBox index="5" title={t("breathing-difficulties")}/>
                </div>
            </div>
            <div className="row">
                <h3 className={classes.secondHeader}>{t("other-symptoms")}</h3>
                <textarea className={cx("form-control",classes.Textarea)} placeholder={t("describe-symptoms")}></textarea>
            </div>

        </div>
    );
}
