import React from 'react'
import cx from 'classnames'
import * as classes from './JoinUsHeader.module.scss'
import { useTranslation } from "react-i18next";
import docsImg from '../../../../../assets/img/contactUs/docs.png'
export default function ContactUsHeader() {
    const { t, i18n } = useTranslation();
    return (
        <div className={cx("row", classes.ContactUsHeader)}>
            <div className="text-center text-md-left">
                <h3>{t("join-us")}</h3>
                <p>{t("join-us-header")}</p>
            </div>
            <img className="mx-auto" src={docsImg}/>
        </div>
    )
}
