import React, { Component } from 'react'
import * as classes from './JoinUs.module.scss'
import cx from 'classnames'
import JoinUsHeader from './JoinUsHeader/JoinUsHeader'
import JoinUsForm from './JoinUsForm/JoinUsForm'
export class ContactUs extends Component {
    render() {
        return (
            <div className={cx(classes.JoinUS,"container-fluid")}>
                <JoinUsHeader />
                <JoinUsForm />
            </div>
        )
    }
}

export default ContactUs
