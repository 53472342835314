import React, { useState, useEffect } from "react";
import * as classes from "./AccountAddressForm.module.scss";
import ProfileInput from "../../../../shared/ProfileInput/ProfileInput";
import Button from "../../../../../shared/Button/Button";
import cx from "classnames";
import Map from "../../../../../shared/Map/Map";
import { useTranslation } from "react-i18next";
const poitionOptions = {
  enableHighAccuracy: true,
  timeout: 5000,
  maximumAge: 0,
};
export default function AccountAddressForm(props) {
  const { t, i18n } = useTranslation();
  const [address, setAddress] = useState(props.address?props.address:'');
  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);
  const [positionLoaded, setPositionLoaded] = useState(false);
  const submitAddress = (event) => {
    event.preventDefault();
    console.log(lat,lng)
    props.submitted(address, lat, lng);
  };
  const getPositionSuccess = (pos) => {
    const crd = pos.coords;

    setLat(crd.latitude);
    setLng(crd.longitude);
    setPositionLoaded(true);
    //console.log(pos);
  };
  const getPositionErrors = (err) => {
    //console.warn(`ERROR(${err.code}): ${err.message}`);
    setPositionLoaded(true);
  };
  
  useEffect(() => {
    //console.log(props.address)
    /*if (props.address) {
      setAddress(props.address);
    }*/
    if(!positionLoaded){
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        getPositionSuccess,
        getPositionErrors,
        poitionOptions
      );
      // navigator.permissions.query({ name: "geolocation" }).then((result) => {
      //   if (result.state === "granted") {
      //     //If granted then you can directly call your function here
      //     navigator.geolocation.getCurrentPosition(
      //       getPositionSuccess,
      //       getPositionErrors,
      //       poitionOptions
      //     );
      //   } else if (result.state === "prompt") {
      //     navigator.geolocation.getCurrentPosition(
      //       getPositionSuccess,
      //       getPositionErrors,
      //       poitionOptions
      //     );
      //   } else if (result.state === "denied") {
      //     //If denied then you have to show instructions to enable location
      //     setPositionLoaded(true);
      //   }
      //   result.onchange = function () {
      //     //console.log(result.state);
      //   };
      // });
    } else {
      setPositionLoaded(true);
    }
  }
  });

  return (
    <div className={classes.AccountAddressForm}>
      <form onSubmit={(event) => submitAddress(event, address)}>
        <p>{t("enter-address")}</p>
        <ProfileInput
          value={address}
          name= "address"
          type="text"
          changed={(event) => {
            setAddress(event.target.value);
          }}
          type="text"
          name="address"
          placeholder={t("enter-address-place")}
          classCustomName="mb-3"
        />
        <p>{t("select-location")}</p>
        <div className={classes.Map}>
          {positionLoaded && (
            <Map
              lat={lat}
              lng={lng}
              updateLocation={(latitude, longitute) => {
                //console.log(latitude,longitute);
                setLat(latitude);
                setLng(longitute);
              }}
            />
          )}
        </div>
        <div
          className={cx(
            "d-flex justify-content-center justify-content-md-end",
            classes.Button
          )}
        >
          <Button
            loading={props.loading}
            disabled={!address}
            classCustomName={classes.AuthButton}
            text={t("save")}
          />
          <Button
            clicked={()=>{props.switchBack()}}
            classCustomName={classes.AuthButton}
            text={t("cancel")}
          />
        </div>
      </form>
    </div>
  );
}
