import * as actionTypes from '../actions/actionTypes'
const defaultState = {
    callRequestSuccess : false,
    switchToVerifyOTP : false,
    loading : false
}
export default (state = defaultState, action) => {
    switch (action.type) {
        case actionTypes.CALL_REQUEST_SUCCESS:
            return {
                ...state,
                callRequestSuccess : true,
                loading : false
            }
        case actionTypes.QUEST_SEND_OTP_SUCCESS:
            return{
                ...state,
                switchToVerifyOTP :true,
                loading :false

            }
        case actionTypes.USER_CALL_REQUEST_START:
            return {
                ...state ,
                loading :true
            }
        case actionTypes.QUEST_SEND_OTP_START:
            return{
                ...state,
                loading :true
            }
            
        case actionTypes.CALL_REQUEST_RESET:
            return{
                ...state,
                switchToVerifyOTP:false,
                callRequestSuccess:false

            }
        default:
            return state
    }
}