import React, { Component } from "react";
import * as classes from "./Account.module.scss";
import AccountHeader from "./AccountHeader/AccountHeader";
import { connect } from "react-redux";
import AccountInfo from "./AccountInfo/AccountInfo";
import EditAccount from "./EditAccount/EditAccount";
import * as actions from "../../../../store/actions/index";
import LoadingPage from "../../../shared/LoadingPage/LoadingPage";

const mapStateToProps = (state) => {
  return {
    clientID: state.common.clientID,
    lang: state.common.currentLanguage,
    userProfileData: state.profile.userProfileData,
    loading: state.profile.loading,
    updateProfileMessage : state.profile.updateProfileMessage
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getProfile: (clientID) => {
      dispatch(actions.getProfile(clientID));
    },
    updateProfile : (userData)=>{
      dispatch(actions.updateProfile(userData))
    },
    removeUpdateProfileMessage : ()=>{
      dispatch(actions.removeUpdateProfileMessage())
    },
    saveProfilePic : (photo)=>{dispatch(actions.saveProfilePic(photo))}
  };
};

export class Account extends Component {
  state={
    switchToEditProfile : false,
    profilePhoto : null
  }
  componentDidMount() {
    
    this.props.getProfile(this.props.clientID);
  }
  submitAddressFrom=(address)=>{
    const userData = {...this.props.userProfileData}
    userData.Address=address
    this.props.updateProfile(userData);

    
  }
  submitEditForm=(address,name)=>{
    const userData = {...this.props.userProfileData}
    userData.Address=address
    userData.FirstName=name
    userData.ProfilePic=this.state.profilePhoto
    this.props.updateProfile(userData);
    
  }
  switchEdit=()=>{
    this.setState({
      ...this.state,
      switchToEditProfile:true
    })
  }
  switchBack = ()=>{
    this.setState({
      ...this.state,
      switchToEditProfile:false
    })
  }
  saveProfilePhoto=(photo)=>{
    this.props.saveProfilePic(photo)
  }
  render() {
    if (this.props.userProfileData) {
      
      
      let content =null
      if(!this.state.switchToEditProfile){
        content=<AccountInfo clicked={()=>{this.switchEdit()}}
        switchBack={()=>{this.switchBack()}}
        address={this.props.userProfileData.Address}
        name={this.props.userProfileData.FirstName}
        phonenumber={this.props.userProfileData.UserName}
        email={this.props.userProfileData.Email}
        submitted={(address)=>this.submitAddressFrom(address)}
        loading={this.props.loading}
        message={this.props.updateProfileMessage}
      />
      }
      else{
        content=<EditAccount switchBack={()=>{this.switchBack()}} message={this.props.updateProfileMessage} loading={this.props.loading} accountData={this.props.userProfileData} submitted={(address,name)=>{this.submitEditForm(address,name)}} />
      }
      return (
        <div className={classes.Account}>
          <div className="container-fluid">
            <AccountHeader
              lang={this.props.lang}
              weight={this.props.userProfileData.Weight}
              height={this.props.userProfileData.Height}
              blood={this.props.userProfileData.BloodType}
              name={this.props.userProfileData.FirstName}
              bloodType={this.props.userProfileData.BloodTypeId}
              changed={(photo)=>{this.saveProfilePhoto(photo)}}
              img={this.props.userProfileData.ProfilePic}
            />
            <div className={classes.SecondSection}>
              {content}
            </div>
          </div>
        </div>
      );
    } else {
      return <LoadingPage />;
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Account);
