import React, { useState, useEffect } from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
const containerStyle = {
  width: '100%',
  height: '381px',
  boxShadow: '0px 3px 6px #00000029',
  borderRadius: '8px'
};
export default function AccountAddressMap(props) {
  const [center, setCenter] = useState({ lat: null, lng: null });
  const [loadedLibraries] = useState(["places"]);
  const [markerPosition, setMarkerPosition] = useState({
    lat: null,
    lng: null,
  });
  useEffect(() => {
    setCenter({ lat: +props.lat, lng: +props.lng });
    setMarkerPosition({ lat: +props.lat, lng: +props.lng });
  }, []);
  return (
    <LoadScript
      libraries={loadedLibraries}
      googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_KEY}
    >
      <GoogleMap
        options={{
          disableDefaultUI: true,
          zoomControl: true,
        }}
        mapContainerStyle={containerStyle}
        center={center}
        zoom={12}
      >
        <Marker position={markerPosition} />
      </GoogleMap>
    </LoadScript>
  );
}
