import React , {useEffect, useState}from "react";
import searchIcon from '../../../assets/img/bookingService/search.svg';
import { GoogleMap, LoadScript ,Marker , StandaloneSearchBox} from '@react-google-maps/api';
import { useTranslation } from "react-i18next";

const containerStyle = {
    width: '100%',
    height: '381px',
    boxShadow: '0px 3px 6px #00000029',
    borderRadius: '8px'
};

const BookingServiceMap = (props) => {
    const { t, i18n } = useTranslation();
    const [searchBoxRef , setSearchBoxRef] = useState(null);
    const [loadedLibraries] = useState(['places']);
    const [center , setCenter] = useState({lat:null,lng:null});
    const [markerPosition , setMarkerPosition] = useState({lat:null,lng:null});
    const onLoad = marker => {
        //console.log('marker: ', marker)
    }
    const onDragEnd = marker => {
        //console.log('marker: ', marker);
        const lat = marker.latLng.lat();
        const lng = marker.latLng.lng();
        setMarkerPosition({lat,lng});
        setCenter({lat,lng});
        props.updateLocation(lat,lng);
    }
    const onSearchLoad = ref => setSearchBoxRef(ref);
    const onPlacesChanged = () => {
        const lat = searchBoxRef.getPlaces()[0].geometry.location.lat();
        const lng = searchBoxRef.getPlaces()[0].geometry.location.lng();
        setCenter({lat,lng});
        setMarkerPosition({lat,lng});
        props.updateLocation(lat,lng);
    }

    useEffect(() => {
        //console.log(props.lat,props.lng)
        
        setCenter({lat:props.lat,lng:props.lng});
        setMarkerPosition({lat:props.lat,lng:props.lng})
    },[]);
    return (
        <LoadScript
        libraries={loadedLibraries}
          googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_KEY}
        >
          <GoogleMap
            options={{
                disableDefaultUI : true,
                zoomControl:true
            }}
            mapContainerStyle={containerStyle}
            center={center}
            zoom={12}
          >
            <StandaloneSearchBox
                onLoad={onSearchLoad}
                onPlacesChanged={
                    onPlacesChanged
                }
            >
                <input
                    type="text"
                    placeholder={t('search-box-placeholder')}
                    className="map-search-box"
                    style={{
                        backgroundImage: `url(${searchIcon})`,
                        backgroundPositionX:document.documentElement.dir=='ltr'?'95%':'5%',
                        backgroundPositionY:'center',
                        backgroundRepeat:'no-repeat',
                        boxSizing: `border-box`,
                        border: `1px solid #E7DDEB`,
                        width: `80%`,
                        height: `45px`,
                        padding: `0 12px`,
                        borderRadius: `8px`,
                        boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                        fontSize: `16px`,
                        lineHeight:`30`,
                        outline: `none`,
                        textOverflow: `ellipses`,
                        position: "absolute",
                        left: "10%",
                        top: "20px"
                    }}
                />
            </StandaloneSearchBox>
            <Marker
                onLoad={onLoad}
                draggable={true}
                onDragEnd = {onDragEnd}
                position={markerPosition}
            />
          </GoogleMap>
        </LoadScript>
      );
}
export default BookingServiceMap;
