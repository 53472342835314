import React from "react";
import * as classes from "./VerificationInput.module.scss";
export default function VerficationInputs(props) {
  return (
    <div className={classes.VerficationInput}>
      <input
        size="1"
        type="text"
        maxLength="1"
        className="form-control mr-1 mr-lg-2"
        onChange={props.changed}
        value={props.value}
      />
    </div>
  );
}
