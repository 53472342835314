import React from "react";
import * as classes from "./TimeCard.module.scss";
import cx from "classnames";
import Checkbox from "react-custom-checkbox";
import * as Icon from "react-icons/fi";
import CheckBox from './../../../shared/CheckBox/CheckBox';

export default function TimeCard(props) {
    return (
        <div className={cx(classes.TimeCard, " d-flex justify-content-between")}>
            <div className="">
                <div className="ml-4">
                    {/* <Checkbox
                        checked={props.checked}
                        onChange={props.changed}
                        borderColor="#68046B"
                        size={24}
                        style={{ marginTop: "15px" }}
                        icon={
                            <div
                                style={{
                                    display: "flex",
                                    flex: 1,
                                    backgroundColor: "#FC0095",
                                    alignSelf: "stretch",
                                }}
                            >
                                <Icon.FiCheck color="white" size={20} />
                            </div>
                        }
                    /> */}
                    <CheckBox checked={props.checked} changed={props.changed} containerStyle={{ marginTop: "15px"}} inputStyle={{borderRadius:'5px'}}/>
                </div>
                <p className="ml-4 mt-2">{props.text}</p>
            </div>

            <img src={props.img} />
        </div>
    );
}
