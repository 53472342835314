import React from "react";
import * as classes from './NotificationCard.module.scss'
import cx from 'classnames'
import { useMediaQuery } from 'react-responsive'
export default function NotificationCard(props) {
    const style = {
        [props.lang =='ar' ? 'borderRight':'borderLeft']: '16px solid rgba(185, 217, 245,0.5)',
    }
    const styleSm={
      'borderTop' : " 16px solid rgba(185, 217, 245,0.5)"
    }
    const smallMobileScreen = useMediaQuery({
      query: '(max-device-width: 577px)'
    })
  return (
    <div className={cx(classes.NotificationCard,"d-flex justify-content-between")} style={smallMobileScreen?styleSm:style}>
      <div>
          <h3>
            {props.title}
          </h3>
          <p>
           {props.message} 
          </p>
      </div>
      <div>
        <span
          className="badge badge-pill badge-primary text-right"
          style={{ backgroundColor: '#B9D9F5',color: ' #1659A1' }}
        >
          {props.badge}
        </span>
      </div>
    </div>
  );
}
