import React from "react";
import * as classes from "./HealthyDateSelect.module.scss";
import cx from "classnames";
import Select from "react-select";
const customStyle = {
  control: (base) => ({
    ...base,
    width: 120,
    height: 45,
    border: "1px solid #e7ddeb",
    "@media (max-width: 577px)": {
      ...base,
      width: 85,
    },
    "@media (max-width: 320px)": {
      ...base,
      width: 68,
    },
  }),
  placeholder: (base) => ({
    ...base,
    color: "#1C1C1C", 
  }),
};
export default function HealthyDateSelect(props) {
  let selections = [];
  for (let i = props.range[0]; i <= props.range[1]; i++) {
    selections.push({ value: i, label: i });
  }
  return (
    <Select
      styles={customStyle}
      options={selections}
      onChange={props.changed}
      placeholder={props.placeholder}
      components={{
        IndicatorSeparator: () => null
      }}
      theme={theme => ({
        ...theme,
        borderRadius: 4,
        colors: {
          ...theme.colors,
          primary: '#8802B4',
        },
      })}
    ></Select>
  );
}
/*import React from 'react'
import * as classes from './HealthyDateSelect.module.scss'
import cx from 'classnames'
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
export default function HealthyDateSelect(props) {
    let selections = [];
    for (let i = props.range[0]; i <= props.range[1]; i++) {
        selections.push(i);
    }
    let selectionsOptions = selections.map(el => {
        return <option key={el} value={el}>{el}</option>
    })
    return (
        <div className={cx(classes.HealthyDateSelect)}>
            <select   className={cx("form-control")} value={props.value} onChange={props.changed} >
                {selectionsOptions}
            </select>
            <div className={cx(classes.addonCustom)}>
                <FontAwesomeIcon icon={faChevronDown} />
            </div>
        </div>
    )
}*/
