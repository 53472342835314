import React, { useState } from "react";
import * as classes from "./AccountInfo.module.scss";
import cx from "classnames";
import editImg from "../../../../../assets/img/account/edit.png";
import AccountNoAddress from "./AccountNoAddress/AccountNoAddress";
import AccountAddressForm from "./AccountAddressForm/AccountAddressForm";
import AccountAddress from "./AccountAddress/AccountAddress";
import { useTranslation } from "react-i18next";
export default function AccountInfo(props) {
  const { t, i18n } = useTranslation();
  const [switchToAddressForm, setSwitchToAddressForm] = useState(false)
  const submitForm=(address,lat,lng)=>{
    const newAddress=address + '$'+lat + ','+lng
    props.submitted(newAddress)

  }
  let content = null;
  if (!switchToAddressForm && !props.address) {
    content = <AccountNoAddress clicked={()=>{setSwitchToAddressForm(true)}}/>
  }
  if (switchToAddressForm && !props.address) {
    content = <div className={classes.AccountAddressForm}>
      <AccountAddressForm switchBack={()=>{props.switchBack()}} message={props.message} submitted={(address,lat,lng)=>{submitForm(address,lat,lng)}} loading={props.loading}/>
    </div>
  }
  if(props.address){
    content= <AccountAddress address={props.address} />
  }
  return (
    <div className={cx(classes.AccountInfo, "row")}>
      <div className="col-12 d-flex justify-content-between">
        <div className="row mt-3 mt-md-4">
          
            <h5 className="col-12 col-md-6">{t("account-full-name")}</h5>
            <p className="col-12 col-md-6 mt-2 mt-md-0">{props.name}</p>
         
          
            <h5 className="col-12 col-md-6 mt-3 mt-md-4">{t("account-phone")}</h5>
            <p className="col-12 col-md-6 mt-2 mt-md-4">{props.phonenumber}</p>

            <h5 className="col-12 col-md-6 mt-3 mt-md-4">{t("account-email")}</h5>
            <p className="col-12 col-md-6 mt-2 mt-md-4">{props.email}</p>
          
        </div>
        <div>
          <img src={editImg}  className={cx("mt-0 mt-md-2",classes.EditImg)} style={{cursor:'pointer'}} onClick={props.clicked} />
        </div>
      </div>
      <div className="col-12">
        <hr />
      </div>
      <div className="col-12">
        {
          content

        }
      </div>
    </div>
  );
}
