import React from "react";
import * as classes from "./Button.module.scss";
import cx from "classnames"
import {
  faSpinner
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const AuthButton = (props) => {
  return (
    <div className={classes.AuthButton}>
    <button type={props.type}
    disabled = {props.disabled || props.loading}
    onClick={props.clicked}
      className={cx("btn btn-default",props.classCustomName)}
    >{props.text} {props.loading?<FontAwesomeIcon className="ml-3" icon={faSpinner} spin />:null}</button>
    </div>
  );
};
export default AuthButton;