import React from 'react'
import * as classes from './AppLayout.module.scss'
import cx from 'classnames'
export default function AppLayout(props) {
    return (
        <div className={props.isLandingPage?classes.AppLayoutForLanding:classes.AppLayout}>
            <div className={cx("container-fluid",props.isLandingPage?"px-0":null)}>
                {props.children}
            </div>
        </div>

    )
}
