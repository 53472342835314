import React from "react";
import * as classes from "./MajorCard.module.scss";
import cx from "classnames";
export default function MajorCard(props) {
  return (
    <div
      className={cx(classes.MajorCard, props.classCustomName)}
      style={
        props.selected == props.id
          ? { backgroundColor: "#F4F4FC", boxShadow: "0px 2px 4px #00000029" }
          : null
      }
      onClick={props.clicked}
    >
      <div className={cx(classes.ImgContainer, "py-auto")}>
        <img src={props.img} />
      </div>
      <p>{props.major}</p>
    </div>
  );
}
