import React from "react";
import * as classes from "./OrderStatusCard.module.scss";
import cx from "classnames";
import moment from "moment";
import "moment/locale/ar";
import { useTranslation } from "react-i18next";
import phoneImg from "../../../../../assets/img/Notifications/001-phone.png";
import ReactTooltip from "react-tooltip";
import { useMediaQuery } from 'react-responsive'

const OrderStatusCard = (props) => {
  const { t, i18n } = useTranslation();
   const hexToRGB=(h)=> {
    let r = 0, g = 0, b = 0;
    if (h.length == 4) {
      r = "0x" + h[1] + h[1];
      g = "0x" + h[2] + h[2];
      b = "0x" + h[3] + h[3];
  
    // 6 digits
    } else if (h.length == 7) {
      r = "0x" + h[1] + h[2];
      g = "0x" + h[3] + h[4];
      b = "0x" + h[5] + h[6];
    }
    
    return "rgb("+ +r + "," + +g + "," + +b + ",0.5)";
  }
  const smallMobileScreen = useMediaQuery({
    query: '(max-device-width: 577px)'
  })
  const style = {
    [props.lang == "ar" ? "borderRight" : "borderLeft"]:
      "16px solid " + hexToRGB(props.color),
      
    
  };
  const styleSm={
    'borderTop' : "16px solid " + hexToRGB(props.color)
  }
  
  const rateService = (event) => {
    event.preventDefault();
    if (props.notificationType == 3) {
      props.clicked();
    }
  };
  return (
    <div
      data-tip={props.notificationType == 3?t("rate-tip"):null}
      className={cx(classes.OrderStatusCard, "row")}
      style={smallMobileScreen?styleSm:style}
      onClick={(event) => {
        rateService(event);
      }}
    >
      <div className="col-12 col-md-6 d-flex">
        {props.notificationType == 3 || props.notificationType == 4 ? (
          <ReactTooltip place="bottom" type="dark" effect="float" />
        ) : null}
        <div className="mt-4 mb-4" style={{width: '60px',height:'60px'}}>
        <img 
          src={props.speciality ? props.img : phoneImg}
          
          
        />
        </div>
        <div className="mt-4 ml-3 ml-md-3">
          <h3>{props.title}</h3>
          <p>{props.speciality}</p>
        </div>
      </div>
      <div
        className={cx(
          "col-12 col-md-6 d-md-flex justify-content-md-between",
          classes.SecondSection
        )}
      >
        <div className={classes.Date}>
          <p className="mb-2">
            {moment.utc(props.timestamp).local().locale(props.lang).format("DD MMMM YYYY")}
          </p>
          <p className="mt-1">
            {moment.utc(props.timestamp).local().locale(props.lang).format("hh:mm A")}
          </p>
        </div>
        <div className="d-flex flex-md-column flex-row justify-content-between justify-content-md-start">
          <div
            className={cx(
              "text-right order-last order-md-first",
              classes.Badge
            )}
          >
            <span
              className="badge badge-pill badge-primary text-right"
              style={{ backgroundColor: props.color }}
            >
              {props.badge}
            </span>
          </div>
          <p
            className={cx(
              classes.Number,
              "order-first order-md-last mt-2 mt-md-4"
            )}
          >
            {t("number")}: {props.id}
          </p>
        </div>
      </div>
    </div>
  );
};
export default OrderStatusCard;
