import React from 'react'
import cx from 'classnames'
import * as classes from './AccountNoAddress.module.scss'
import markerImg from '../../../../../../assets/img/account/011-your location.png'
import plusImg from '../../../../../../assets/img/account/plus-circle.png'
import { useTranslation } from "react-i18next";
export default function AccountNoAddress(props) {
    const { t, i18n } = useTranslation();
    return (
        <div className={cx(classes.AccountNoAddress,"text-center")}>
            <img className={classes.MarkerImg} src={markerImg} />
            <p>{t("add-address")}</p>
            <img className={classes.PlusImg} src={plusImg} onClick={props.clicked} />
            
        </div>
    )
}
