import React from "react";
import * as classes from "./LandingPageCard.module.scss";
import cx from "classnames";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
export default function LandingPageCard(props) {
  const { t, i18n } = useTranslation();
  return (
    <div
      className={cx(
        classes.LandingPageCard,
        "text-center",
        props.classCustomName
      )}
      onClick={props.clicked}
    >
      {/* <Link to={props.link}> */}
      <div className={cx(classes.GrayCircle, "mx-auto text-center")}>
        <img src={props.img} className="" />
      </div>

      <p className="mt-2 mb-1">{props.text}</p>
      <div className={cx(classes.book, " mx-auto mt-4 pt-1")}>
        <Link
          className={cx("btn btn-default", classes.CardButton)}
          to={props.link}
        >
          <span>{t("book-now")}</span>
        </Link>
      </div>
      {/* </Link> */}
    </div>
  );
}
