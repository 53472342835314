import React from 'react'
import * as classes from './CallRequestSubmit.module.scss';
import docWriteImg from '../../../../../assets/img/order-call/docwrite1.png'
import Button from '../../../../shared/Button/Button'
import { useTranslation } from "react-i18next";
export default function CallRequestSubmit(props) {
    const { t, i18n } = useTranslation();
    return (
        <div className={classes.CallRequestSubmit}>
            <div className="text-center">
                <img src={docWriteImg} />
                <p>{t("call-request-contact-us")}</p>
            </div>
            <div className={classes.Button}>
            <Button loading={props.loading} classCustomName={classes.submitButton} text={t("confirm-call-request")} clicked={props.superClicked}/>
            </div>
        </div>
    )
}
