import React from "react";
import Select from "react-select";
const customStyle = {
  control: (base) => ({
    ...base,
    height: 45,
    background: "#f4f4fc 0% 0% no-repeat padding-box",
    border: "1px solid #e7ddeb",
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: "#B1B1B1",
  }),
};

export default function AuthSelect(props) {
  return (
    <Select
      options={props.optionsElements}
      placeholder={props.placeholder}
      styles={customStyle}
      components={{
        IndicatorSeparator: () => null,
      }}
      onChange={props.changed}
      theme={theme => ({
        ...theme,
        borderRadius: 4,
        colors: {
          ...theme.colors,
          primary: '#8802B4',
        },
      })}
    ></Select>
  );
}
/*import React ,{useState} from "react";
import * as classes from './AuthSelect.module.scss'
import cx from 'classnames'
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
export default function AuthSelect(props) {

  return (
    <div className={cx(classes.AuthSelect,"input-group")}>
      <select  value={props.value} className={"form-control ".concat(props.classCustomName)} onChange={props.changed}>
        <option value="" disabled hidden>
          {props.placeholder}
        </option>
        {props.optionsElements.map((optionsElement) => {
          return (
            <option value={optionsElement} key={optionsElement}>
              {optionsElement}
            </option>
          );
        })}
      </select>
      <div className={cx("input-group-addon addon-custom",classes.addonCustom)}>
        <FontAwesomeIcon icon={faChevronDown}  />
      </div>
    </div>
  );
}
*/
