import React, { useState } from "react";
import * as classes from "./CallRequestPhoneForm.module.scss";
import cx from "classnames";
import mainLogo from "../.././../../../assets/img/logos/hoslogo.png";
import Input from "../../../../shared/Input/Input";
import Button from "../../../../shared/Button/Button";
import Checkbox from "react-custom-checkbox";
import * as Icon from "react-icons/fi";
import { useTranslation } from 'react-i18next';
export default function CallRequestPhoneForm(props) {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [checked, setChecked] = useState(false);
  const { t, i18n } = useTranslation();
  const submitForm = (event)=>{
    event.preventDefault();
    props.submitted(name,phone);
  }
  return (
    <div className={classes.CallRequestPhoneForm}>
      <div className="text-center text-md-left">
        <img src={mainLogo} className="mt-4 " />
        <h3>{t("call-request")}</h3>
        <p className="mb-3">{t("enter-name-phone" )}</p>
      </div>
      <form onSubmit={(event)=>{submitForm(event)}}>
        <Input
          type="name"
          name="name"
          placeholder={t("signup-name")}
          classCustomName="mt-1 mb-2"
          changed={(event) => {
            setName(event.target.value);
          }}
          value={name}
        />
        <Input
          type="phone"
          name="phone"
          placeholder={t("signup-phone")}
          classCustomName="mt-1 mb-2"
          changed={(event) => {
            setPhone(event.target.value);
          }}
          value={phone}
        />
        <div className={cx("d-flex justify-content-start ")}>
          {/*<input
            type="checkbox"
            className={cx("mr-3", classes.Checkbox)}
            onChange={()=>setChecked(!checked)}
            value={checked}
            
          />*/}
          <Checkbox
                borderColor="#68046B"
                size={24}
                onChange={
                  (value)=>{
                    value?setChecked(true):setChecked(false)

                  }
                }
                icon={
                    <div
                        style={{
                            display: "flex",
                            flex: 1,
                            backgroundColor: "#FC0095",
                            alignSelf: "stretch",
                        }}
                    >
                        <Icon.FiCheck color="white" size={20} />
                    </div>
                }
            />
          <p className="ml-2">{t("accept-conditions")}</p>
        </div>
        <div className={classes.Button}>
          <Button loading={props.loading} text={t("send-code")} disabled={!name||!phone||!checked}/>
        </div>
      </form>
    </div>
  );
}
